+function ($) {
    'use strict';
    var module = $("#moduleName").val();
    $(".orderPay-content .range-input").daterangepicker(
        {
            autoApply: true,
            autoUpdateInput: false,
            alwaysShowCalendars: true,
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '近7天': [moment().subtract(7, 'days'), moment()],
                '这个月': [moment().startOf('month'), moment().endOf('month')],
                '上个月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: "YYYY/MM/DD HH:MM:SS",
                separator: " 至 ",
                applyLabel: "确认",
                cancelLabel: "清空",
                fromLabel: "开始时间",
                toLabel: "结束时间",
                customRangeLabel: "自定义",
                daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
            }
        }
    ).on('apply.daterangepicker', function (ev, picker) {
        $("#startTime").val(picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
        $("#endTime").val(picker.endDate.format('YYYY-MM-DD HH:mm:ss'));
        search(1);
    });

    $(".orderPay-content .show-more-spec").on('mouseover', function (e) {
        var currentTop = $(this).position().top;
        var more = $(this).siblings(".more-spec");
        more.show();
        var fullHeight = $(".orderPay-content").height();
        var h = more.height();
        if (h + currentTop + 10 > fullHeight) {
           more.css({top: (currentTop - h - 20) + 'px'})
        } else {
            more.css({top: (currentTop + 10) + 'px'})
        }
    });
    $(".orderPay-content .show-more-spec").on('mouseout', function (e) {
        var more = $(this).siblings(".more-spec");
        more.hide();
    });


    $(".orderPay-content .timerange-group .input-icon").on('click', function (e) {
        $("#startTime").val("");
        $("#endTime").val("");
        search(1);
    });

    var checkList = [];

    $(".btn-item#checkAll").on('click', function (e) {
        $('.check-order').prop("checked", true);
        getCheckList()
    });
    $("#checkAll").on('change', function (e) {
        var isAll = $(this).prop("checked");
        if (isAll) {
            $('.check-order').prop("checked", true);
            getCheckList()
        } else {
            $('.check-order').each(function () {
                $(this).prop("checked", false);
            });
            checkList = [];
        }
    });

    $(".check-order").on('change', function () {
        getCheckList();
    });

    $(".batchDown").on("click", function () {
        if(checkList.length > 0 ) {
            $(this).attr("href", "/admin/orders/download?ids="+checkList.join(","));
        }else {
            $('.alert-danger').removeClass('hide').addClass('in');
            setTimeout(function () {
                $('.alert-danger').removeClass('in').addClass('hide');
            }, 2000)
        }

    });

    function getCheckList() {
        checkList = [];
        $.each($('.check-order:checked'), function () {
            checkList.push($(this).val());
        });
        $("#checkList").val(checkList);
    }

    $(".orderPay-content .conform-payment").on('click', function (e) {
        var orderid = $(this).data("id");
        $("#conformPayment #paymentId").val(orderid);
        $("#conformPaymentModal").modal("show");
    });

    $(".orderPay-content .blanking").on('click', function (e) {
        var self = $(this);
        var id = self.data("id");
        var form = new FormData();
        form.append("id", id);
        var action = '/admin/orders/rule/blanking';
        $.ajax({
            url: action,
            type: 'post',
            data: form,
            dataType: "json", //jsonp会把请求类型强制转换为get请求
            contentType: false,
            processData: false,
            async: true, //异步请求
            cache: false, //是否缓存
            success: function (res) {
                self.addClass('text-gray-default').removeClass('blanking');
                self.text("已下料")
                    $(".btn-order[data-id="+id+"]")
                    .attr("disabled",false)
            },
            error: function (res) {

            }
        });
    });

    $(".orderPay-content .send-order").on('click', function (e) {
        var orderId = $(this).data("id");
        $('#deliverGoods #orderId').val(orderId);
        var action = module ? '/admin/orders/'+module+'/deliver' : '/admin/quickOrders/deliver';
        $('#deliverGoods').attr('action', action);
        $('#deliverGoods #params').val(getParams(1));
        $('#deliverGoodsModal').modal("show");
    });
    $(".orderPay-content .confirm-completion").on('click', function (e) {
        var id = $(this).data("id");
        var action = module ? '/admin/orders/'+module+'/complete' : '/admin/quickOrders/complete';
        $('#conformComplete').attr('action', action);
        $("#conformComplete .params").val(getParams(1));
        $("#conformComplete .orderid").val(id);
        $('#conformCompleteModal').modal("show");
    });

    $(".orderPay-content .search-btn").on('click', function (e) {
        search(1)
    });

    $(".orderPay-content .page-conform").on('click', function (e) {
        search()
    });

    $(".orderPay-content .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var search = $(".orderPay-content input[name=search]").val();
        var page = defaultPage ? defaultPage : Number($(".orderPay-content .jump").val());
        var size = $(".orderPay-content .page-size-selector").val();
        var total = $(".orderPay-content .page-total").val();
        var startTime = $(".orderPay-content #startTime").val();
        var endTime = $(".orderPay-content #endTime").val();
        var orderStatus = $("#orderStatus").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return 'search=' + search + '&page=' + page + '&size=' + size + '&startTime=' + startTime + '&endTime=' + endTime + '&orderStatus=' + orderStatus;
    }

    function search() {
        var params = getParams();
        var action = (module ? '/admin/orders/'+module : '/admin/quickOrders') + '?' + params;
        window.location.href = action;
    }

    $(".orderQuick-H-content .dropdown-menu li").on('click', function (e) {
        var value = $(this).data("value");
        var params = getParams();
        var action = (module ? '/admin/orders/'+module : '/admin/quickOrders') + '?' + params + '&processed=' + value;
        window.location.href = action;
    })
}(jQuery);
