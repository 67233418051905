+function ($) {
    'use strict';

    var $_divice;

    // 打开修改和更新地址的弹窗
    $(".add-address-button").on('click', function () {
        $('#add-address-modal').fadeIn()
    })
    $(".modify-add-address-modal-icon,.orderAddressadd-cancel-button").on('click', function () {
        $('#add-address-modal').fadeOut()
        $('.modify-add-address-modal').fadeOut()
    })

    var comsumprice=0;
    var comsumweight=0;
    $.each($(".summarytotal"), function (i, item) {
        comsumprice=Math.round((Number(comsumprice) + Number($(item).text().substring(1))) * 100) / 100
    })
    $.each($(".sumweight"), function (i, item) {
        comsumweight=Math.round((Number(comsumweight) + Number($(item).text())) * 100) / 100
    })
    $(".comsumprice").text('￥'+comsumprice)
    $(".comsumweight").text(comsumweight+'kg')
    if (!isNaN(Number($(".fareprice").text().substring(1)))) {
        $(".mainsumprice").text(Math.round((Number(comsumprice) + Number($(".fareprice").text().substring(1))) * 100) / 100)
    } else {
        $(".mainsumprice").text("")
    }


    $.each($(".modify-button-id"), function (i, item) {
        $(item).click(modifybuttonid_fadeToggle);
    })
    function modifybuttonid_fadeToggle(){
        var addressid = $(this).data("id").replace("modify-button-","")
        var proviceId = $(".divisionid"+addressid).val()
        var cityinptId = $(".divisionchildid"+addressid).val()
        var districtinputId = $(".divisionchildchildid"+addressid).val()

        if (isEmpty(proviceId)) {
            return;
        }
        var city = [];
        for (var i = 0; i < $_divice.length; i++) {
            if ($_divice[i].id == proviceId) {
                city = $_divice[i].children;
            }
        }
        var $_addresscity = $(".address-city"+addressid)
        var cityId = $_addresscity.empty();
        for (var i = 0; i <city.length; i++) {
            cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
        }

        // var cityId = $(".address-city"+addressid).val()
        var cityId = cityinptId;
        if (isEmpty(cityId)) {
            return;
        }
        var district= [];
        for(var i=0;i<$_divice.length;i++){
            for(var j =0;j<$_divice[i].children.length;j++){
                if($_divice[i].children[j].id == cityId){
                    district = $_divice[i].children[j].children;
                    var districtId = $(".address-district"+addressid).empty();
                    for(var k = 0;k<district.length;k++){
                        districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                    }

                }

            }
        }

        $("#address-province"+addressid).val(proviceId)
        $(".address-city"+addressid).val(cityinptId)
        $(".address-district"+addressid).val(districtinputId)

        $('#modify-add-address'+$(this).data("id").replace("modify-button-","")).fadeIn()
    }

    $(".orderAddressadd-sure-button").on("click", function () {
        var currentName = $(".addaddressname").val()
        var currentDetail = $(".adddetailaddress").val()
        var currentPhone = $(".addaddressphone").val()
        var currentdefaultaddress = $('.addradiodiv input[name=defaultaddress]:checked').val();
        var currentdistrictId = $(".addaddressdivisionid").val()
        var isSend = true;
        if(isEmpty(currentName.replace(/\s*/g,""))){
            $("#quick-new-orders-name").show()
            $("#quick-new-orders-name").html("请填写联系人")
            isSend = false;
        } else if(currentName.length > 255){
            $("#quick-new-orders-name").show()
            $("#quick-new-orders-name").html("联系人最多255字")
            isSend = false;
        } else{
            $("#quick-new-orders-name").hide()
        }
        if(isEmpty(currentDetail.replace(/\s*/g,""))){
            $("#quick-new-orders-address").show()
            $("#quick-new-orders-address").html("请填写详细地址")
            isSend = false;
        } else if(currentDetail.length > 255){
            $("#quick-new-orders-address").show()
            $("#quick-new-orders-address").html("详细地址最多255字")
            isSend = false;
        }else {
            $("#quick-new-orders-address").hide()
        }
        if(isEmpty(currentPhone.replace(/\s*/g,""))){
            $("#quick-new-orders-phone").show()
            $("#quick-new-orders-phone").html("请填写联系电话")
            isSend = false;
        }else if(!(/^\d+(\-\d+)?$/.test(currentPhone))){
            $("#quick-new-orders-phone").show()
            $("#quick-new-orders-phone").html("联系电话有误，请重填")
            isSend = false;
        }else {
            $("#quick-new-orders-phone").hide()
        }
        var data = {
            "name": currentName,
            "division": {
                id: currentdistrictId
            },
            "address": currentDetail,
            "phone": currentPhone,
            "defaultAddress": currentdefaultaddress
        }
        if(isSend) {
            $.ajax({
                url: "/api/user-addresses",
                type: "post",
                contentType: "application/json",
                data: JSON.stringify(data),
                cache: false,
                success: function (message) {
                    location.reload()
                },
                error: function (res) {
                    if (res.status == 401) {
                        location.reload();
                    }
                }
            });
        }
    })



    $.each($(".address-item-content"), function (i, item) {
        $(item).on("click", function () {
            $(item).children("div.address-item").removeClass("address-item address-item-selected").addClass("address-item address-item-selected")
            $(item).siblings().children("div.address-item").removeClass("address-item address-item-selected").addClass("address-item")

            $(item).children("div.address-item").removeClass("address-item address-item-selected").addClass("address-item address-item-selected")
            $(item).siblings().children("div.address-item").removeClass("address-item address-item-selected").addClass("address-item")

            $(item).children("div.address-tag").removeClass("address-tag select-address-tag").addClass("address-tag select-address-tag")
            $(item).siblings().children("div.address-tag").removeClass("address-tag select-address-tag").addClass("address-tag")

            var cartCommodityIds = [];
            var addressitemchildchildid = $(item).children(".address-item").children(".address-item-content-info").children(".modify-button-id").data("id").replace("modify-button-","")
            $.each($(".summarytotal"),function (i,item) {
                cartCommodityIds.push($(item).data("id"))
            })
            var data = {
                "cartCommodityIds":cartCommodityIds,
                "userAddressId": addressitemchildchildid
            }
            $.ajax({
                url: "/api/orders/preview/freight",
                type: "post",
                data: JSON.stringify(data),
                contentType: "application/json",
                dataType: "json",
                cache: false,
                success: function (message) {
                    $(".fareprice").children(".commonPriceStyle").text("￥"+Math.round((Number(message)) * 100) / 100)
                    $(".mainsumprice").text("￥"+Math.round((Number(comsumprice) + Number($(".fareprice").text().substring(1))) * 100) / 100)
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    $(".mainsumprice").text("");
                    $(".fareprice").children(".commonPriceStyle").text("运费未配置，请联系商家！");
                }
            })
        })
    })

    $.each($(".orderAddress-sure-button"), function (i, item) {
        $(item).on("click", function () {
            var currentaddreassId = $(item).data("id").replace("orderAddress-sure-button","")
            var currentName = $(".address-username"+currentaddreassId).val()
            var currentDetail = $(".address-detail"+currentaddreassId).val()
            var currentPhone = $(".address-phone"+currentaddreassId).val()
            var currentdefaultaddress = $('#address-defaultsure'+currentaddreassId+' input[name=defaultaddress]:checked').val();
            var currentdistrictId = $(".address-district"+currentaddreassId).val()
            var data = {
                "id": currentaddreassId,
                "name": currentName,
                "division": {
                    id: currentdistrictId
                },
                "address": currentDetail,
                "phone": currentPhone,
                "defaultAddress": currentdefaultaddress
            }
            var isSend = true;
            if(isEmpty(currentName.replace(/\s*/g,""))){
                $("#quick-edit-orders-name").show()
                $("#quick-edit-orders-name").html("请填写联系人")
                isSend = false;
            } else if(currentName.length > 255){
                $("#quick-edit-orders-name").show()
                $("#quick-edit-orders-name").html("联系人最多255字")
                isSend = false;
            } else{
                $("#quick-edit-orders-name").hide()
            }
            if(isEmpty(currentDetail.replace(/\s*/g,""))){
                $("#quick-edit-orders-address").show()
                $("#quick-edit-orders-address").html("请填写详细地址")
                isSend = false;
            } else if(currentDetail.length > 255){
                $("#quick-edit-orders-address").show()
                $("#quick-edit-orders-address").html("详细地址最多255字")
                isSend = false;
            }else {
                $("#quick-edit-orders-address").hide()
            }
           if(isEmpty(currentPhone.replace(/\s*/g,""))){
                $("#quick-edit-orders-phone").show()
                $("#quick-edit-orders-phone").html("请填写联系电话")
               isSend = false;
            }else if(!(/^\d+(\-\d+)?$/.test(currentPhone))){
                $("#quick-edit-orders-phone").show()
                $("#quick-edit-orders-phone").html("联系电话有误，请重填")
                isSend = false;
            }else {
                $("#quick-edit-orders-phone").hide()
            }
            if(isSend) {
                $.ajax({
                    url: "/api/user-addresses",
                    type: "put",
                    contentType: "application/json",
                    data: JSON.stringify(data),
                    cache: false,
                    success: function (message) {
                        location.reload()
                    },
                    error: function (res) {
                        if (res.status == 401) {
                            location.reload()
                        }
                    }
                });
            }
        })
    });



    function setdivice(res) {
        $.each($(".address-province"), function (i, item) {
            var addressid = $(item).data("id").replace("address-province","")
            $(item).change(function () {
                var proviceId = $(item).val()
                if (isEmpty(proviceId)) {
                    return;
                }
                var city = [];
                for (var i = 0; i < res.length; i++) {
                    if (res[i].id == proviceId) {
                        city = res[i].children;
                    }
                }
                var $_addresscity = $(".address-city"+addressid)
                var cityId = $_addresscity.empty();
                for (var i = 0; i <city.length; i++) {
                    cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
                    if (i == 0) {
                        var district = city[0].children;
                        var districtId = $(".address-district"+addressid).empty();
                        for (var j = 0; j < district.length; j++) {
                            districtId.append("<option value='" + district[j].id + "'>" + district[j].fullName + "</option>")
                        }
                    }
                }
            })

            $.each($(".address-city"+addressid), function (i, item) {
                $(item).change(function () {
                    var cityId = $(item).val()
                    if (isEmpty(cityId)) {
                        return;
                    }
                    var district= [];
                    for(var i=0;i<res.length;i++){
                        for(var j =0;j<res[i].children.length;j++){
                            if(res[i].children[j].id == cityId){
                                district = res[i].children[j].children;
                                var districtId = $(".address-district"+addressid).empty();
                                for(var k = 0;k<district.length;k++){
                                    districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                                }
                            }
                        }
                    }
                })
            })
        })
    }






    $("#address-province").change(function () {
        var proviceId = $("#address-province").val()
        if (proviceId == "" || proviceId == null) {
            return;
        }
        var city = [];
        for (var i = 0; i < $_divice.length; i++) {
            if ($_divice[i].id == proviceId) {
                city = $_divice[i].children;
            }
        }
        var cityId = $("#address-city").empty();
        for (var i = 0; i <city.length; i++) {
            cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
            if (i == 0) {
                var district = city[0].children;
                var districtId = $("#address-district").empty();
                for (var j = 0; j < district.length; j++) {
                    districtId.append("<option value='" + district[j].id + "'>" + district[j].fullName + "</option>")
                }
            }
        }
    })
    $("#address-city").change(function () {
        var cityId = $("#address-city").val()
        if (cityId == "" || cityId == null) {
            return;
        }
        var district = [];
        for (var i = 0; i < $_divice.length; i++) {
            for (var j = 0; j < $_divice[i].children.length; j++) {
                if ($_divice[i].children[j].id == cityId) {
                    district = $_divice[i].children[j].children;
                    var districtId = $("#address-district").empty();
                    for (var k = 0; k < district.length; k++) {
                        districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                    }

                }

            }
        }
    })



    // 消息框
    var $message;
    var messageTimer;
    $.submitordermessage = function() {
        var message = {};
        if ($.isPlainObject(arguments[0])) {
            message = arguments[0];
        } else if (typeof arguments[0] === "string" && typeof arguments[1] === "string") {
            message.type = arguments[0];
            message.content = arguments[1];
        } else {
            return false;
        }

        if (message.type == null || message.content == null) {
            return false;
        }

        if ($message == null) {
            $message = $('<div class="xxMessage" ><i class="iconfont icon-warning" style="float:left;"></i><div class="messageContent message' + escapeHtml(message.type) + 'Icon"><\/div><\/div>');
            if (!window.XMLHttpRequest) {
                $message.append('<iframe class="messageIframe"><\/iframe>');
            }
            $message.appendTo("body");
        }

        $message.children("div").removeClass("messagewarnIcon messageerrorIcon messagesuccessIcon").addClass("message" + message.type + "Icon").html(message.content);
        $message.css({"margin-left": - parseInt($message.outerWidth() / 2), "z-index": 9999}).show();

        clearTimeout(messageTimer);
        messageTimer = setTimeout(function() {
            $message.hide();
        }, 3000);
        return $message;
    }


    $(".submit-orders-button-group-sure").unbind('click').click(function () {
        var cartCommodityIds = [];
        var userAddressId;
        var message = $(".buyer-message-textarea").val();
        var credit = getUrlParam("credit");
        var urgent = $("input[name='urgent']:checked").val() === 'true';
        $.each($(".summarytotal"),function (i,item) {
            cartCommodityIds.push($(item).data("id"))
        })

        $.each($(".address-item"), function (i, item) {
            if($(item).hasClass("address-item-selected")){
                userAddressId = $(item).children(".address-item-content-info").children(".modify-button-id").data("id").replace("modify-button-","")
            }
        })
        var data =
            {
                "cartCommodityIds": cartCommodityIds,
                "userAddressId": userAddressId,
                "message" : message,
                "credit": credit,
                "urgent": urgent
            };
        var urgentAvailable = false;
        if (urgent) {
            $.ajax({
                url: "/api/urgentAvailable",
                type: "get",
                async: false,
                contentType: "application/json",
                cache: false,
                success: function (result) {
                    urgentAvailable = result;
                }
            });
        }
        if(isEmpty(cartCommodityIds)||isEmpty(userAddressId)||isEmpty(credit)){
            $.submitordermessage("warn","请输入收货地址并保存!")
        } else if(urgent && !urgentAvailable){
            $("input[name='urgent']").attr("disabled","true");
            $("#urgent-off").attr("checked","true");
            $("#urgentNumber").text("0");
            $.submitordermessage("warn","加急次数已使用完，该订单无法加急!");
        } else {
            $.ajax({
                url: "/api/orders",
                type: "post",
                contentType: "application/json",
                data: JSON.stringify(data),
                cache: false,
                success: function (message) {
                    if(message.type=="CREDIT"){
                        location.href = "/mall/orders/"+message.id+'?payType=restAmountPay'
                    }else{
                        location.href = "/mall/payment/"+message.id
                    }

                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    var title = JSON.parse(XMLHttpRequest.responseText).title;
                    if (title === "Unauthorized") {
                        location.href = "/mall/login"
                    } else {
                        alert("错误信息: " + title); //获取的信息即是异常中的Message
                        location.href = "/mall/shoppingCart?credit=" + credit;
                    }
                }
            });
        }
    })

    function getUrlParam(name) {

        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象

        var r = window.location.search.substr(1).match(reg); //匹配目标参数

        if (r != null) return unescape(r[2]); return null; //返回参数值

    }





    $.each($(".address-item-content"), function (i, item) {
        var isSelected = $(item).children("div.address-tag").hasClass("select-address-tag");
        if (isSelected === false) {
            return;
        }
        var cartCommodityIds = [];
        var addressitemchildchildid = $(item).children(".address-item").children(".address-item-content-info").children(".modify-button-id").data("id").replace("modify-button-","")
        $.each($(".summarytotal"),function (i,item) {
            cartCommodityIds.push($(item).data("id"))
        })
        var data = {
            "cartCommodityIds":cartCommodityIds,
            "userAddressId": addressitemchildchildid
        }
        if(cartCommodityIds.length==0){
            $(".fareprice").children(".commonPriceStyle").text("￥"+Math.round((Number(0)) * 100) / 100)
            $(".mainsumprice").text(Math.round((Number(0))))
        }
        $.ajax({
            url: "/api/orders/preview/freight",
            type: "post",
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
            cache: false,
            success: function (message) {
                $(".fareprice").children(".commonPriceStyle").text("￥"+Math.round((Number(message)) * 100) / 100)
                $(".mainsumprice").text("￥"+Math.round((Number(comsumprice) + Number($(".fareprice").text().substring(1))) * 100) / 100)
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                $(".mainsumprice").text("");
                $(".fareprice").children(".commonPriceStyle").text("运费未配置，请联系商家！");
            }
        })
    })


    noaddressloaddivision();

    function isEmpty(value) {
        if (value == null || value == "" || value == "undefined" || value == undefined || value == "null") {
            return true;
        } else {
            /*value = value.toString().replace(/\s/g,"");
            if(value == ""){
                return true;
            }*/
            return false;
        }
    }

    function noaddressloaddivision() {

        $.ajax({
            cache: true,
            type: "get",
            url:"/api/divisions/provinces",
            success:function (res) {
                $_divice=res;
                setdivice(res);

                var proviceId = 11
                var cityinptId = 1101
                var districtinputId = 110101

                if (isEmpty(proviceId)) {
                    return;
                }
                var city = [];
                for (var i = 0; i < $_divice.length; i++) {
                    if ($_divice[i].id == proviceId) {
                        city = $_divice[i].children;
                    }
                }
                var $_addresscity = $("#address-city")
                var cityId = $_addresscity.empty();
                for (var i = 0; i <city.length; i++) {
                    cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
                }

                // var cityId = $(".address-city"+addressid).val()
                var cityId = cityinptId;
                if (isEmpty(cityId)) {
                    return;
                }
                var district= [];
                for(var i=0;i<$_divice.length;i++){
                    for(var j =0;j<$_divice[i].children.length;j++){
                        if($_divice[i].children[j].id == cityId){
                            district = $_divice[i].children[j].children;
                            var districtId = $("#address-district").empty();
                            for(var k = 0;k<district.length;k++){
                                districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                            }

                        }

                    }
                }

                $("#address-province").val(proviceId)
                $("#address-city").val(cityinptId)
                $("#address-district").val(districtinputId)
            }
        });
    }
}(jQuery);
