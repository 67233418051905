+function ($) {
    'use strict';

    $("#page-myPoints-selector").on('change', function (e) {
        search()
    });

    $("#page-myPoints-btn").on('click', function (e) {
        search()
    });

    function getParams(defaultPage) {
        var page = defaultPage?defaultPage:Number($(".points-body .jump").val());
        var size = $("#page-myPoints-selector").val();
        var total = $(".mall-myPoints-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = window.location.pathname+"?"+formatParam(params);
    }
    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }


    // 以下是新增
    // buyercenter --- eye显隐
    $('.my-info-head .eye').on('click',function(){
        $(this).hide()
        $(this).siblings().show()
        var openEye = $('.my-info-head .open-eye')
        console.log(openEye.is(":hidden"))
        var ifHide = openEye.is(":hidden")
        if(ifHide == true){
            $('.my-info-body .num').hide()
            $('.my-info-body .disNo').show()
        }else{
            $('.my-info-body .disNo').hide()
            $('.my-info-body .num').show()
        }

    })
}(jQuery);
