+function ($) {
    'use strict';


    $(".member-level-list .member-level-del").on("click", function (e) {
        var id = $(this).data("id");
        var userSize = $(this).data("user-size");
        var name = $(this).data("name");
        var label = $("#deleteMemberLevel .warning-label");
        if(userSize > 0) {
            label.text("“"+name+"”等级下存在会员用户，不能删除！");
            $("#deleteMemberLevel .conform").hide();
        } else {
           label.text("此操作将永久删除“"+name+"”，是否继续？");
            $("#deleteMemberLevel").attr("action", "/admin/memberLevels/"+ id);
            $("#deleteMemberLevel .conform").show();
        }
        $("#deleteMemberLevelModal").modal("show")
    })

    $(".member-level-list .member-level-edit").on("click", function (e) {
        var id = $(this).data("id");
        var level = $(this).data("level");
        var params = getParams();
        $("#params").val(formatParam(params));
        $("#memberId").val(id);
        $('#memberLevel.selectpicker').selectpicker('val',level);
        $("#editMemberLevel").modal("show");
    });

    $(".member-level-list .search-btn").on('click', function (e) {
        search(1)
    });

    $(".member-level-list .page-conform").on('click', function (e) {
        search()
    });

    $(".member-level-list .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var name = $(".member-level-list input[name=name]").val();
        var page = defaultPage?defaultPage:Number($(".member-level-list .jump").val());
        var size = $(".member-level-list .page-size-selector").val();
        var total = $(".member-level-list .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            name: name,
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/memberLevels?'+formatParam(params);
    }
    function formatParam(params) {
        return 'name=' + params.name + '&page=' + params.page + '&size=' + params.size;
    }


}(jQuery)
