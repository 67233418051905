+function ($) {
    'use strict';

    $(".registered-form .button").on('click', function () {
        var username = $(".registered-form #username").val()
        var phone = $(".registered-form #newPhone").val()
        var verificationcode = $(".registered-form #verification-code").val()
        var password = $(".registered-form #password").val()
        var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;

        if(isEmpty(username)){
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入用户名。")
            return false;
        }
        if(isEmpty(phone)||!myreg.test(phone)){
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入正确的手机号码。")
            return false;
        }
        if(isEmpty(verificationcode)){
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入验证码。")
            return false;
        }
        if(isEmpty(username)||(password.length<4||password.length>16)){
            $("#newPhone-label").show()
            $("#newPhone-label").html("密码格式错误。")
            return false;
        }

    })


    $(".personal_page .registered-send-phone-code").on('click', function () {
        var newPhone = $(".personal_page #newPhone").val()
        var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;

        if (newPhone != null && newPhone != "") {
            if (!myreg.test(newPhone)) {
                $("#newPhone-label").show()
                $("#newPhone-label").html("请输入正确的手机号")
                return false
            }
            $.ajax({
                url: '/api/phoneCode?type=CREATE&phoneNumber=' + newPhone,
                type: 'get',
                success: function (res) {
                    $("#modifyMobileModal").modal("show")
                    time($(".personal_page .registered-send-phone-code"));
                    $("#newPhone-label").hide()
                },
                error: function (res) {
                    $("#modifyMobileModal").modal("show")
                    $("#newPhone-label").show()
                    $("#newPhone-label").html(res.responseText)
                }
            })
        } else {
            $("#newPhone-label").show()
        }
    })

    var waitTime = 60;

    function time(sendCode) {
        if (waitTime == 0) {
            sendCode.removeAttr("disabled");
            sendCode.text("发送验证码");
            waitTime = 60;
        } else {
            sendCode.attr("disabled", true);
            sendCode.text("重新发送" + waitTime + "s");
            waitTime--;
            setTimeout(function () {
                    time(sendCode)
                },
                1000)
        }
    }
}(jQuery);
