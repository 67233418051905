+function ($) {
    'use strict';
     $(".first-menu").on('mouseover', function (e) {
         $(".active-line").css("top", $(e.currentTarget).offset().top);
         $(".active-line").css("transform", "scaleY(1)")
     });
    $(".first-menu").on('mouseout', function (e) {
        $(".active-line").css("transform", "scaleY(0)")
    });

    $('.number-check').bind('blur', function() {
        var val = $(this).val();
        if (val.toString().substr(0,1) == '.') {
            $(this).val('0' + val)
        }
        if (val.toString().substr(-1) == '.') {
            $(this).val(val.replace(/\./g,''));
        }
        if (val == 0) {
            $(this).val('')
        }
    });
    $('.number-check').bind('input propertychange', function() {
        var val = $(this).val();
        $(this).val(val.replace(/[^\d.]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.'));
    });
    $(".collapse-btn").on('click', function () {
        var side = $('#pageSidebar');
        var content = $('#pageWrapper');
        if(side.hasClass("side-collapsed")) {
            side.addClass("of-hidden");
            side.find("#sideContainer").addClass("width-22");
            side.find(".expand-item").show();
            side.find(".collapse-item").hide();
            side.removeClass("side-collapsed");
            content.removeClass("content-expanded");
            $(this).removeClass("expanded-btn");
            side.find(".collapse-item-tooltip").tooltip("destroy");
        } else {
            side.removeClass("of-hidden");
            side.find(".expand-item").hide();
            side.find(".collapse-item").show();
            side.addClass("side-collapsed");
            content.addClass("content-expanded");
            $(this).addClass("expanded-btn");
            side.find("#sideContainer").removeClass("width-22");
            side.find(".collapse-item-tooltip").tooltip();
        }

    });

//翻页查询框建议只能输入数字
    $(".jump").keyup(function(){
        $(this).val($(this).val().replace(/[^0-9-]+/,''));
    }).bind("paste",function(){  //CTR+V事件处理
        $(this).val($(this).val().replace(/[^0-9-]+/,''));
    }).css("ime-mode", "disabled");

    $(".entry-button").on("click",function (){
        window.location.href =  '/mall/commodities?transverse&searchFlg=DOMESTIC&&category.id=' + $(".micnav-tabs .active").attr("data-id");
    })

    $(".abroad-entry-button").on("click",function (){
        window.location.href =  '/mall/commodities?transverse&searchFlg=ABROAD&&category.id=' + $(".nmicnav-tabs .active").attr("data-id");
    })
}(jQuery);
