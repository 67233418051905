+function ($) {
    'use strict';

    $(".credit-sale-list .credit-sale-refuse").on('click', function (e) {
        var id = $(this).data("id");
        $("#creditSaleRefuseForm").attr("action", "/admin/creditSales/"+id+"/refuse");
        $("#creditSaleRefuseModal").modal("show");
    });

    $(".credit-sale-list .credit-sale-edit").on("click", function (e) {
        var id = $(this).data("id");
        var level = $(this).data("level");
        var exist = $(this).data("exist");
        $("#changeMemberLevel").attr("action", "/admin/creditSales/"+id+"/level");
        // if(exist) {
            $('#changeMemberLevel #memberLevel').selectpicker('val',exist?level:"");
        // }
        $("#editMemberLevel").modal("show");
    });

    $(".credit-sale-list .search-btn").on('click', function (e) {
        search(1)
    });

    $(".credit-sale-list .page-conform").on('click', function (e) {
        search()
    });

    $(".credit-sale-list .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var contact = $(".credit-sale-list input[name=contact]").val();
        var sort = $(".credit-sale-list #sort").val();
        var status = $(".credit-sale-list #status").val();
        var page = defaultPage?defaultPage:Number($(".member-level-list .jump").val());
        var size = $(".credit-sale-list .page-size-selector").val();
        var total = $(".credit-sale-list .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            contact: contact,
            page: page,
            size: size,
            sort: sort,
            status: status
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/creditSales?'+formatParam(params);
    }
    function formatParam(params) {
        return 'contact=' + params.contact + '&page=' + params.page + '&size=' + params.size + '&sort=' +params.sort + '&status=' +params.status;
    }
}(jQuery);
