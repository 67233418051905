+function ($) {
    'use strict';


    function GetQueryString(name)
    {
        var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if(r!=null)return  unescape(r[2]); return null;
    }

    if(GetQueryString('status')==null){
        $("#myOrders-type").text("全部状态")
    }
    if(GetQueryString('status')=='PENDING_PAYMENT'){
        $("#myOrders-type").text("待付款")
    }
    if(GetQueryString('status')=='WAITING_DELIVER'){
        $("#myOrders-type").text("待发货")
    }
    if(GetQueryString('status')=='WAITING_CONFIRM'){
        $("#myOrders-type").text("待收货")
    }
    if(GetQueryString('status')=='COMPLETE'){
        $("#myOrders-type").text("已完成")
    }


    $("#page-myOrders-btn").on('click', function (e) {
        search()
    });

    $("#page-myOrders-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var page = defaultPage?defaultPage:Number($(".order-pagination-wrapper .jump").val());
        var size = $("#page-myOrders-selector").val();
        var total = $(".mall-myOrder-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = window.location.pathname+"?"+formatParam(params);
    }
    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }

}(jQuery);
