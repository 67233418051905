+function ($) {
    'use strict';

    $(".commodity-list .list-table-row").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        window.location.href = "/admin/commodities/" + id + "?show";
    })

    $(".commodity-list .switch").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        var chk = $(this)[0].checked;
        $.ajax({
            url: '/admin/commodities/switch/' + id + '?homeShow=' + chk,
            type: 'get',
            success: function (res) {
                if (chk && !res.homeShow) {
                    $("#switchModal").modal("show")
                }
                var switchClass = '.commodity-list #switch' + res.id;
                $(switchClass).prop('checked', res.homeShow);
            },
            error: function (res) {

            }
        });
    });

    $(".commodity-list .del").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        var name = $(this).data("name");
        $("#delCommodityListModal .delete-name").text(name);
        $("#deleteCommodity").attr("action", "/admin/commodities/" + id);
        $("#delCommodityListModal").modal("show");
    })

    $(".commodity-list .edit").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        window.location.href = "/admin/commodities/edit?id="+ id ;

    })

    $(".commodity-list .onshelf").on('click', function (e) {
        e.stopPropagation();
        controlShelf($(this));
        $("#shelfModal").modal("show")

    })

    $(".commodity-list .offshelf").on('click', function (e) {
        e.stopPropagation();
        controlShelf($(this));
        $("#shelfModal").modal("show")
    })

    function controlShelf(self) {
        var label = self.data("label");
        var id = self.data("id");
        var name = self.data("name");
        $(".commodity-list .shelf-control").text(label);
        $(".commodity-list .commodity-name").text(name);
        $("#controlShelf").attr("action", "/admin/commodities/"+id+"/shelf");
        var params = getParams();
        $(".commodity-list .current-search").val(params.search);
        $(".commodity-list .current-page").val(params.page);
        $(".commodity-list .current-size").val(params.size);
    }

    $(".commodity-list .category-search-btn").on('click', function (e) {
        search(1)
    });

    $(".commodity-list .page-conform").on('click', function (e) {
        search()
    });

    $(".commodity-list .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var search = $(".commodity-list input[name=search]").val();
        var page = defaultPage?defaultPage:Number($(".commodity-list .jump").val());
        var size = $(".commodity-list .page-size-selector").val();
        var total = $(".commodity-list .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            search: search,
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/commodities?search=' + params.search + '&page=' + params.page + '&size=' + params.size;
    }

}(jQuery);
