+function ($) {
    'use strict';

    var $_divice = null;
    $("#btn-save-newAddress").on('click',function () {
        var addressId = $("#address-district").val();
        var name = $("#newName").val();
        var newAddress = $("#newAddress").val();
        var newPhone = $("#newPhone").val()
        var defaultAddress = true;
        var isSend = true;
        if(newAddress==null||newAddress.replace(/\s*/g,"")==""){
            $("#newAddress-label").show()
            $("#newAddress-label").html("请填写详细地址")
            isSend = false
        } else if(newAddress.length > 255){
            $("#newAddress-label").show()
            $("#newAddress-label").html("详细地址最多255字")
            isSend = false
        }else {
            $("#newAddress-label").hide()
        }
        if(name==null||name.replace(/\s*/g,"")==""){
            $("#newName-label").show()
            $("#newName-label").html("请填写联系人")
            isSend = false
        } else if(name.length > 255){
            $("#newName-label").show()
            $("#newName-label").html("联系人最多255字")
            isSend = false
        }else {
            $("#newName-label").hide()
        }
        if(newPhone==null||newPhone.replace(/\s*/g,"")==""){
            $("#newPhone-label").show()
            $("#newPhone-label").html("请填写联系电话")
            isSend = false
        }else if (!(/^\d+(\-\d+)?$/.test(newPhone))){
            $("#newPhone-label").show();
            $("#newPhone-label").html("联系号码有误，请重填");
            isSend = false;
        } else {
            $("#newPhone-label").hide()
        }
        if($("#checkDefault").prop("checked")==true){
            defaultAddress = true
        }else {
            defaultAddress = false
        }
        var data = {
            "name":name,
            "division":{
                id:addressId
            },
            "address":newAddress,
            "phone":newPhone,
            "defaultAddress":defaultAddress
        }
        console.log(isSend)
        if(isSend){
            $.ajax({
                url: "/api/user-addresses",
                type: "post",
                contentType: "application/json",
                cache: false,
                data: JSON.stringify(data),
                success: function (res) {
                    location.reload()
                },
                error: function (res) {
                    if(res.status == 401){
                        location.reload();
                    }
                }
            })
        }
    });

    var editId;
    $(".right-content-address .user-address-edit").on('click', function () {
        if ($_divice == null) {
            return;
        }
        editId = $(this).val();
        $('.modify-add-address-modal').fadeIn()
        addressEdit(editId);
    });

    function addressEdit(id){
        $("#edAddress").val($(".addressDetail"+id).text());
        $("#edPhone").val($(".phone"+id).text());
        $("#consignee").val($(".name"+id).text());

        if ($('.defaultAddress'+id).val() == "true") {
            $('#defaul-true').attr('checked','true')
        } else {
            $('#defaul-false').attr('checked','true')
        }
        var proCityDis = $(".address"+id).val().split("-");
        $("#address-province-item").val(proCityDis[0]);
        var proviceId = proCityDis[0];
        var city = [];
        for (var i = 0; i < $_divice.length; i++) {
            if ($_divice[i].id == proviceId) {
                city = $_divice[i].children;
            }
        }
        var cityId = $("#address-city-item").empty();
        for (var i = 0; i < city.length; i++) {
            cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
            if (city[i].id == proCityDis[1]) {
                var district = city[i].children;
                var districtId = $("#address-district-item").empty();
                for (var j = 0; j < district.length; j++) {
                    districtId.append("<option value='" + district[j].id + "'>" + district[j].fullName + "</option>")
                }
            }
        }
        $("#address-city-item").val(proCityDis[1]);
        $("#address-district-item").val(proCityDis[2]);
    }

    $("#quxiaoBtn").on('click', function () {
        $('.modify-add-address-modal').fadeOut()
    });
    $("#modify-add-address-modal-close-button").on('click', function () {
        $('.modify-add-address-modal').fadeOut()
    })
    $("#queren").on('click', function () {
        var id = editId;
        var name = $("#consignee").val();
        var divisionId = $("#address-district-item").val();
        var address = $("#edAddress").val();
        var phone = $("#edPhone").val();
        var defaultAddress = $("#redioDiv input[name=defaultaddress]:checked").val();
        var data = {
            "id": id,
            "name": name,
            "division": {
                id: divisionId
            },
            "address": address,
            "phone": phone,
            "defaultAddress": defaultAddress
        };
        var flg = true;
        if(address==null||address.replace(/\s*/g,"")==""){
            $("#popAddress-label").show();
            $("#popAddress-label").html("请填写详细地址");
            flg = false;
        } else if(address.length > 255){
            $("#popAddress-label").show();
            $("#popAddress-label").html("详细地址最多255个字");
            flg = false;
        }else {
            $("#popAddress-label").hide();
        }
        if(name==null||name.replace(/\s*/g,"")==""){
            $("#popName-label").show();
            $("#popName-label").html("请填写联系人");
            flg = false;
        } else if(name.length > 255){
            $("#popName-label").show();
            $("#popName-label").html("联系人最多255个字");
            flg = false;
        }else {
            $("#popName-label").hide();
        }
        if(phone==null||phone.replace(/\s*/g,"")==""){
            $("#popPhone-label").show()
            $("#popPhone-label").html("请填写联系电话")
            flg = false;
        }else if(!(/^\d+(\-\d+)?$/.test(phone))){
            $("#popPhone-label").show()
            $("#popPhone-label").html("联系号码有误，请重填")
            flg = false
        } else {
            $("#popPhone-label").hide();
        }
        if (flg) {
            $.ajax({
                url: "/api/user-addresses",
                type: "put",
                contentType: "application/json",
                cache: false,
                data: JSON.stringify(data),
                success: function (res) {
                    location.reload()
                },
                error: function (res) {
                    if (res.status == 401) {
                        location.reload();
                    }
                }
            })
        }else{

        }
    });


    $(".user-address-delete").on('click', function () {
        var id = $(this).val();
        $.ajax({
            url: '/api/user-addresses/' + id,
            type: 'delete',
            success: function (res) {
                window.location.href = "/mall/buyerCenter/address";
            },
            error: function (res) {
                if (res.status == 401) {
                    location.reload();
                }
            }
        })
    })

    $.ajax({
        url: "/api/divisions/provinces",
        type: 'get',
        success: function (res) {
            $_divice = res;
            $("#address-province").change(function () {
                var proviceId = $("#address-province").val()
                if (proviceId == "" || proviceId == null) {
                    return;
                }
                var city = [];
                for (var i = 0; i < $_divice.length; i++) {
                    if ($_divice[i].id == proviceId) {
                        city = $_divice[i].children;
                    }
                }
                var cityId = $("#address-city").empty();
                for (var i = 0; i < city.length; i++) {
                    cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
                    if (i == 0) {
                        var district = city[0].children;
                        var districtId = $("#address-district").empty();
                        for (var j = 0; j < district.length; j++) {
                            districtId.append("<option value='" + district[j].id + "'>" + district[j].fullName + "</option>")
                        }
                    }
                }
            })
            $("#address-city").change(function () {
                var cityId = $("#address-city").val()
                if (cityId == "" || cityId == null) {
                    return;
                }
                var district = [];
                for (var i = 0; i < $_divice.length; i++) {
                    for (var j = 0; j < $_divice[i].children.length; j++) {
                        if ($_divice[i].children[j].id == cityId) {
                            district = $_divice[i].children[j].children;
                            var districtId = $("#address-district").empty();
                            for (var k = 0; k < district.length; k++) {
                                districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                            }
                        }
                    }
                }
            })
        }
    });

    $("#address-province-item").change(function () {
        var proviceId = $("#address-province-item").val()
        if (proviceId == "" || proviceId == null) {
            return;
        }
        var city = [];
        for (var i = 0; i < $_divice.length; i++) {
            if ($_divice[i].id == proviceId) {
                city = $_divice[i].children;
            }
        }
        var cityId = $("#address-city-item").empty();
        for (var i = 0; i < city.length; i++) {
            cityId.append("<option value='" + city[i].id + "'>" + city[i].fullName + "</option>")
            if (i == 0) {
                var district = city[0].children;
                var districtId = $("#address-district-item").empty();
                for (var j = 0; j < district.length; j++) {
                    districtId.append("<option value='" + district[j].id + "'>" + district[j].fullName + "</option>")
                }
            }
        }
    })
    $("#address-city-item").change(function () {
        var cityId = $("#address-city-item").val()
        if (cityId == "" || cityId == null) {
            return;
        }
        var district = [];
        for (var i = 0; i < $_divice.length; i++) {
            for (var j = 0; j < $_divice[i].children.length; j++) {
                if ($_divice[i].children[j].id == cityId) {
                    district = $_divice[i].children[j].children;
                    var districtId = $("#address-district-item").empty();
                    for (var k = 0; k < district.length; k++) {
                        districtId.append("<option value='" + district[k].id + "'>" + district[k].fullName + "</option>")
                    }
                }
            }
        }
    })

}(jQuery);
