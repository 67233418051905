+function ($) {
    'use strict';


    var checkList = [];
    var checkSumList = [];

    $('.check-order').prop("checked", false);
    $('#checkAll').prop("checked", false);
    // 删除确认弹窗
    $("#delete-cart-button, #close-delete-button, .delete-modal-button-group button:last ,.cancelButton").on('click',function () {
        $('.delete-modal').fadeToggle()
        $('.cart-modify-model').fadeOut()
    })

    $.each($(".delete-cart-button"), function (i, item) {
        $(item).click(delCartButton);
    })

    function delCartButton(){
        var id = $(this).data("id");
        $("#requestIds").val(id)
        $("#deleteCommodity").attr("action", "/mall/ShoppingCart")
    }
    $.each($(".cart-submit-button , .cart-cancel-button"), function (i, item) {
        $(item).click(tableitem_fadeTogglefalse);
    })
    $.each($(".table-item-modify"), function (i, item) {
        $(item).click(tableitem_fadeToggle);
    })
    function tableitem_fadeTogglefalse(){
        $('#cart-modify-model'+$(this).attr('id').replace($(this).attr('class'),"")).fadeToggle()
    }
    function tableitem_fadeToggle() {
        var goodsid = $(this).data("id").split(",")[0];
        var processtype = ($(this).data("id").split(",")[1] == 'NOMINALSIZE' || $(this).data("id").split(",")[1] == 'NETSIZE') ? 'SEMI' : $(this).data("id").split(",")[1];
        var goodscarid = $(this).attr('id').replace($(this).attr('class'),"")
        if(!isEmpty(goodsid)){
            $.ajax({
                url: "/api/commodities/"+goodsid,
                type: "get",
                contentType: "application/json",
                dataType: "json",
                cache: false,
                success: function(message) {
                    // 现货
                    if (message.shape == "STANDARD") {
                        var arr = [];
                        var j = 0;
                        $.each(message.commodityPrices, function (k, val) {
                            var thicknessArrayValue = val.thickness.split(",");
                            $.each(thicknessArrayValue, function (i, item) {
                                arr[j ++] = val.edgeLength + " * " + val.edgeWidth + " * " + item;
                            })
                        })
                        $.each($(".contain"+goodscarid+" .standard_select"), function (i, item) {
                            console.log("i:  " + i)
                            $(item).empty()
                            $(item).show();
                            $.each(arr, function (k, n) {
                                var nArr = n.split("*");
                                var standArr = $(".contain"+goodscarid+" #standard").eq(i).val().split("*");
                                $(item).append("<option value='" + n + "'>" + n + "</option>");
                                if (Number(nArr[0]) == Number(standArr[0]) && Number(nArr[1]) == Number(standArr[1]) && Number(nArr[2]) == Number(standArr[2])) {
                                    $(item).val(n)
                                }
                            });
                        })
                    }
                    $.each(message.specifications,function(index,value){
                        // 圆
                        if (message.shape === "CYLINDER") {
                            $.each(value.sides, function (k, val) {
                                if (val.sideType === "DIAMETER") {
                                    var thicknessArrayValue = val.valueRange.split(",");
                                    $.each($(".contain"+goodscarid+" .diameter_select"), function (i, item) {
                                        $(item).empty()
                                        $(item).show();
                                        $.each(thicknessArrayValue, function (i, n) {
                                            $(item).append("<option value=" + n + ">" + n + "</option>");
                                        });
                                        $(item).val($(".contain"+goodscarid+" #diameter").eq(i).val().split(".")[0])
                                        $(".contain"+goodscarid+" #diameter").hide();
                                    })
                                }
                            })
                        }

                        // console.log("processtype: "  + processtype + "; value.processType: " + value.processType)
                        if(value.processType==processtype){
                            var goodsidtrue = $(this).attr('id').replace($(this).attr('class'),"");
                            var goodsid = $(this).data("id");
                            var attributeType = $("#cartAttributeType" + goodsid).val();
                            $.each(value.sides,function(index,value) {
                                if (value.sideType == "THICKNESS" && value.valueType == "SELECTION") {
                                    var thicknessArrayValue = value.valueRange.split(",");
                                    $.each($(".contain"+goodscarid+" .thickness_select"), function (i, item) {
                                        $(item).empty()
                                        $(item).show();
                                        $.each(thicknessArrayValue, function (i, n) {
                                            $(item).append("<option value=" + n + ">" + n + "</option>");
                                        });
                                        $(item).val($(".contain"+goodscarid+" #thickness_input").eq(i).val().split(".")[0])
                                        $(".contain"+goodscarid+" #thickness_input").hide();
                                    })
                                }
                                if(value.sideType == "THICKNESS" && value.valueType == "RANGE"){
                                    $.each($(".contain"+goodscarid+" .thickness_input"), function (i, item) {
                                        $(item).blur(function () {
                                            if (!/^(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/.test($(item).val())) {
                                                $(this).val('');
                                                $(this).attr('placeholder', '请输入数字');
                                            }
                                            if ("MEMBER" !== attributeType) {
                                                if ((Number($(item).val()) < Number(value.valueRange.split(",")[0]) || Number($(item).val()) > Number(value.valueRange.split(",")[1]))) {
                                                    $(this).val("");
                                                    $(this).attr('placeholder', value.valueRange.split(",")[0] + "-" + value.valueRange.split(",")[1]);
                                                }

                                            }

                                        });
                                    })
                                }
                                if(value.sideType == "WIDTH" && value.valueType == "RANGE"){
                                    $.each($(".contain"+goodscarid+" .proWidthShop"), function (i, item) {
                                        $(item).blur(function () {
                                            if (!/^(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/.test($(item).val())) {
                                                $(this).val('');
                                                $(this).attr('placeholder', '请输入数字');
                                            }
                                            if ("MEMBER" !== attributeType) {
                                                if ((Number($(item).val()) < Number(value.valueRange.split(",")[0]) || Number($(item).val()) > Number(value.valueRange.split(",")[1]))) {
                                                    $(this).val("");
                                                    $(this).attr('placeholder',  value.valueRange.split(",")[0] + "-" + value.valueRange.split(",")[1]);
                                                }
                                            }

                                        });
                                    })
                                }

                                if(value.sideType == "LENGTH" && value.valueType == "RANGE"){
                                    $.each($(".contain"+goodscarid+" .proLengthShop"), function (i, item) {
                                        $(item).blur(function () {
                                            if (!/^(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/.test($(item).val())) {
                                                $(this).val('');
                                                $(this).attr('placeholder', '请输入数字');
                                            }
                                            if ("MEMBER" !== attributeType) {
                                                if (Number(($(item).val()) < Number(value.valueRange.split(",")[0]) || Number($(item).val()) > Number(value.valueRange.split(",")[1]))) {
                                                    $(this).val("");
                                                    $(this).attr('placeholder',  value.valueRange.split(",")[0] + "-" + value.valueRange.split(",")[1]);
                                                }
                                            }

                                        });
                                    })
                                }
                            })
                        }
                    });
                }
            });
        }
        $('#cart-modify-model'+$(this).attr('id').replace($(this).attr('class'),"")).fadeToggle()
    }

    $(".commodity-list .del").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        var name = $(this).data("name");
        $("#delCommodityListModal .delete-name").text(name);
        checkList.push(id)
        $("#deleteCommodity").attr("action", "/mall/ShoppingCart?ids=" + checkList.join(","));
        $("#delCommodityListModal").modal("show");
    })

    $(".btn-item#checkAll").on('click', function (e) {
        $('.check-order').prop("checked", true);
        getCheckList()
        $(".quantity-text").text(checkList.length)
    });
    $("#checkAll").on('change', function (e) {
        var isAll = $(this).prop("checked");
        if (isAll) {
            $('.check-order').prop("checked", true);
            getCheckList()
        } else {
            $('.check-order').each(function () {
                $(this).prop("checked", false);
            });
            checkList = [];
            $(".value-text").text("0.00")
        }
        $(".quantity-text").text(checkList.length)
    });

    $(".check-order").on('change', function () {
        cartChange();
    });

    $("#checkAll").on('change',function () {
        cartChange();
    });

    function cartChange(){
        getCheckList();
        if($(".check-order").is(':checked')){
            $(".delete-button").css("color","#000000");
        }else{
            $(".delete-button").css("color","#d8d8d8");
        }
        $(".quantity-text").text(checkList.length)
    }

    $(".delete-button").on("click", function () {
        if(checkList.length > 0 ) {
            $.ajax({
                url: "/mall/ShoppingCart?ids="+checkList.join(","),
                type: "delete",
                cache: false,
                success: function(message) {

                }
            });
            // location.reload(true);
            setTimeout(function () {
                location.reload(true);
            }, 500)
        }else {
            $('.alert-danger').removeClass('hide').addClass('in');
            setTimeout(function () {
                $('.alert-danger').removeClass('in').addClass('hide');
            }, 2000)
        }
    });

    function getCheckList() {
        checkList = [];
        checkSumList = [];
        var v = 0;
        $.each($('.check-order:checked'), function () {
            checkList.push($(this).data("id"));
            var checkedid = $(this).data("id");
            $.each($('.summarytotal'), function () {
                if(checkedid==$(this).data("id")){
                    checkSumList.push($(this).text().replace("¥",""))
                }
            })
        });

        $.each(checkSumList,function(index,value){
            v = Math.round((Number(v) + Number(value)) * 100) / 100
        });
        $(".value-text").text(v)
        $("#checkList").val(checkList);
    }


    $(".clear-button").on("click", function () {
        $('.check-order').prop("checked", true);
        getCheckList()
        if(checkList.length > 0 ) {
            $.ajax({
                url: "/mall/ShoppingCart?ids="+checkList.join(","),
                type: "delete",
                cache: false,

                success: function(message) {

                }
            });
            setTimeout(function () {
                location.reload(true);
            }, 500)
        }else {
            $('.alert-danger').removeClass('hide').addClass('in');
            setTimeout(function () {
                $('.alert-danger').removeClass('in').addClass('hide');
            }, 2000)
        }
    });


    //获取url中的参数
    function getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
        var r = window.location.search.substr(1).match(reg);  //匹配目标参数
        if (r != null) return unescape(r[2]); return null; //返回参数值
    }

    var creditParam = getUrlParam('credit');
    if(creditParam=="true"){
        $(".tab-title-list li:first").removeClass("current")
        $(".tab-title-list li:last").addClass("current")
    }else{
        $(".tab-title-list li:first").addClass("current")
        $(".tab-title-list li:last").removeClass("current")
    }

    var jian = $(".btn-reduces");
    var add = $(".btn-adds");
    var numberInput = $(".quantity-input");
    var numberValue;


    $.each(numberInput, function (i, item) {
        $(item).on("input",function(){
            numberValue = parseInt($(item).val())
            if (numberValue<=1) {
                jian.eq(i).css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
                $(item).val(1)
                return;
            }else {
                jian.eq(i).css({"backgroundColor":"#fff","color":"#1E9FFF","cursor":"pointer"})
            }
        })
    })

    $.each(jian, function (i, item) {
        $(item).on("click", function () {
            numberValue = parseInt(numberInput.eq(i).val())
            numberValue--;
            if (numberValue <= 1) {
                $(item).css({"backgroundColor": "#eee", "color": "#ccc", "cursor": "not-allowed"})
                numberInput.eq(i).val(1)
                return;
            } else {
                $(item).css({"backgroundColor": "#fff", "color": "#1E9FFF", "cursor": "pointer"})
            }
            numberInput.eq(i).val(numberValue)
        })
    })
    $.each(add, function (i, item) {
        $(item).on("click", function () {
            numberValue = parseInt(numberInput.eq(i).val())
            numberValue++;
            if (numberValue <= 1) {
                jian.eq(i).css({"backgroundColor": "#eee", "color": "#ccc", "cursor": "not-allowed"})
                numberInput.eq(i).val(1)
                return;
            } else {
                jian.eq(i).css({"backgroundColor": "#fff", "color": "#1E9FFF", "cursor": "pointer"})
            }
            numberInput.eq(i).val(numberValue)
        })
    })


    // 消息框
    var $message;
    var messageTimer;
    $.messagethis = function() {
        var message = {};
        if ($.isPlainObject(arguments[0])) {
            message = arguments[0];
        } else if (typeof arguments[0] === "string" && typeof arguments[1] === "string") {
            message.type = arguments[0];
            message.content = arguments[1];
        } else {
            return false;
        }

        if (message.type == null || message.content == null) {
            return false;
        }

        if ($message == null) {
            $message = $('<div class="xxMessage" ><i class="iconfont icon-warning" style="float:left;"></i><div class="messageContent message' + escapeHtml(message.type) + 'Icon" STYLE="width : 200px;"><\/div><\/div>');
            if (!window.XMLHttpRequest) {
                $message.append('<iframe class="messageIframe"><\/iframe>');
            }
            $message.appendTo("body");
        }

        $message.children("div").removeClass("messagewarnIcon messageerrorIcon messagesuccessIcon").addClass("message" + message.type + "Icon").html(message.content);
        $message.css({"margin-left": - parseInt($message.outerWidth() / 2), "z-index": 9999}).show();

        clearTimeout(messageTimer);
        messageTimer = setTimeout(function() {
            $message.hide();
        }, 3000);
        return $message;
    }



    $(".cart-submit-button").click(function() {
        var goodsidtrue = $(this).attr('id').replace($(this).attr('class'),"");

        var goodsid = $(this).data("id");
        var goodsshape = $("#cart-modify-model"+goodsid).data("id");
        // var diameter=$(".contain"+goodsid+" .diameter");
        var diameter_select=$(".contain"+goodsid+" .diameter_select");
        var $quantity=$(".quantity-group"+goodsid+" #quantity");
        var processLevel=$("#processLevel").val();
        var proWidthShop=$(".contain"+goodsid+" .proWidthShop");
        var proLengthShop=$(".contain"+goodsid+" .proLengthShop");
        var thick= $(".contain"+goodsid+" .thickness_input")
        if($(".contain"+goodsid+" .thickness_input").is(":hidden")){
            thick=$(".contain"+goodsid+" .thickness_select")
        }
        var requestvalues = $(".modifiedvalues"+goodsid).val().split(",");
        var numberInput = $(".quantity-group"+goodsid+" .quantity-input");
        var goonthis = true;
        var specifications = [];
        if (goodsshape == "STANDARD") {
            var lengthArr = [];
            var widthArr = [];
            var thickArr = [];
            var quantity = [];
            var standardArr = document.getElementsByName("standard_select");
            var j = 0;
            $.each(standardArr, function (i, item) {
                console.log("item[" + i + "]1: " + item)
                if (item.value != null && item.value != "" && item.value != "undefined" && $quantity[i] != null && $quantity[i] != "") {
                    var tmpArr = item.value.split("*");
                    console.log("tmpArr[" + i + "]: " + tmpArr)
                    lengthArr[j] = tmpArr[0];
                    widthArr[j] = tmpArr[1];
                    thickArr[j] = tmpArr[2];
                    quantity[j] = $quantity[i].value
                        ++ j;
                }

            })
            $.each(lengthArr, function (i, item) {
                var sides = [];
                var lengthObject = {};
                var widthObject = {};
                var thickObject = {};
                lengthObject.sideType = "LENGTH";
                thickObject.sideType = "THICKNESS";
                widthObject.sideType = "WIDTH";
                lengthObject.value = lengthArr[i];
                thickObject.value = thickArr[i];
                widthObject.value = widthArr[i];
                console.log(lengthObject.value + "*" + widthObject.value + "*" + thickObject.value)

                if (isEmpty(lengthObject.value) || isEmpty(thickObject.value) || isEmpty(widthObject.value)) {
                    goonthis = false;
                    $.messagethis("warn", "商品数据不能为空！");
                }
                sides.push(lengthObject);
                sides.push(widthObject);
                sides.push(thickObject);

                specifications.push(
                    {
                        "quantity": quantity[i],
                        "sides": sides
                    }
                )
            });

        }
        if(goodsshape == "SQUARE") {

            $.each(numberInput, function (i, item) {
                var sides = [];
                var lengthObject = {};
                var widthObject = {};
                var thickObject = {};
                lengthObject.sideType = "LENGTH";
                thickObject.sideType = "THICKNESS";
                widthObject.sideType = "WIDTH";
                lengthObject.value = proLengthShop.eq(i).val();
                thickObject.value = thick.eq(i).val();
                widthObject.value = proWidthShop.eq(i).val();
                if (isEmpty(lengthObject.value) || isEmpty(thickObject.value) || isEmpty(widthObject.value)) {
                    goonthis = false;
                    $.messagethis("warn", "商品数据不能为空！");
                } else if (parseInt(lengthObject.value) < parseInt(widthObject.value) || parseInt(widthObject.value) < parseInt(thickObject.value)) {
                    goonthis = false;
                    $.messagethis("warn", "  注意： 长度 >= 宽度 >= 厚度");
                }
                sides.push(lengthObject);
                sides.push(widthObject);
                sides.push(thickObject);
                specifications.push(
                    {
                    "quantity": $quantity[i].value,
                    "sides": sides
                    }
                )
            });
        }
        if (goodsshape == "CYLINDER") {
            $.each(numberInput, function (i, item) {
                var sides = [];
                var lengthObject = {};
                var diameterObject = {};
                lengthObject.sideType = "LENGTH";
                lengthObject.value = proLengthShop.eq(i).val();
                diameterObject.sideType = "DIAMETER";
                diameterObject.value = diameter_select.eq(i).val();
                if (isEmpty(lengthObject.value) ||  isEmpty(diameterObject.value)) {
                    goonthis = false;
                    $.messagethis("warn", "商品数据不能为空！");
                }
                sides.push(lengthObject);
                sides.push(diameterObject);
                specifications.push(
                    {
                        "quantity": $quantity[i].value,
                        "sides": sides
                    }
                )
            })
        }
        var data = [{
            "id": goodsid,
            "commodityId": goodsidtrue,
            "processType": requestvalues[0],
            "chamfer": requestvalues[1],
            "modelShape": requestvalues[2],
            "toleranceType": requestvalues[3],
            "tolerance": requestvalues[4],
            "additionalProcessing": requestvalues[5],
            "credit": requestvalues[6],
            "specifications": specifications
        }];

        if(goonthis){
            $.ajax({
                url: "/api/cartCommodities",
                type: "put",
                contentType: "application/json",
                data: JSON.stringify(data),
                dataType: "json",
                cache: false,

                success: function (message) {
                    $.messagethis("warn", "订单修改成功！");
                    location.reload();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    var title = JSON.parse(XMLHttpRequest.responseText).title;
                    if (title === "Unauthorized") {
                        location.href = "/mall/login"
                    } else {
                        alert("错误信息: " + title); //获取的信息即是异常中的Message

                    }
                }
            });
            $('#cart-modify-model'+$(this).data('id')).fadeToggle()
        }
    })


    $(".settlement-button").on("click", function () {
        if(checkList.length > 0 ) {
            var creditParam = getUrlParam('credit');
            if(creditParam==null){    // 购物车弹窗进入
                creditParam = $("#isCredit").val();
            }
            location.href="/mall/orders?confirm&credit="+creditParam+"&cartids="+checkList.join(",");
        }else {
            $('.alert-danger').removeClass('hide').addClass('in');
            setTimeout(function () {
                $('.alert-danger').removeClass('in').addClass('hide');
            }, 2000)
        }
    });

}(jQuery);
