+function ($) {
    'use strict';

    $(".label-manage .edit-label").on("click", function (e) {
        var form = $("#editLabelForm");
        form.find("#labelId").val($(this).data("id"));
        form.find("#labelName").val($(this).data("name"));
        var params = getParams();
        form.find("#params").val(formatParam(params));
        $("#editLabel").modal("show");
    });

    $(".label-manage .delete-label").on("click", function (e) {
        var id = $(this).data("id");
        var name = $(this).data("name");
        $("#deleteLabelForm").attr("action", "/admin/commodityLabels/" + id);
        $("#deleteLabelForm .delete-name").text(name);
        $("#deleteLabel").modal("show");
    });

    $(".label-manage .page-conform").on('click', function (e) {
        search()
    });

    $(".label-manage .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var page = defaultPage?defaultPage:Number($(".label-manage .jump").val());
        var size = $(".label-manage .page-size-selector").val();
        var total = $(".label-manage .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/commodityLabels?'+formatParam(params);
    }
    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }

}(jQuery);
