+function ($) {
    'use strict';

    $(".category-tooltip").tooltip();

    $(".tree-container .add-btn").on('click', function (e) {
        e.stopPropagation();
        var level = $(this).data("level");
        var type = $(this).data("type");
        var id = $(this).data("id");
        var placeholder = (type === 'DOMESTIC' || level === 2) ?'例：热作模具钢': '例：日本（大同）';
        $("#secondModal input[name=level]").val(level);
        $("#secondModal input[name=parent]").val($(this).data("id"));
        $("#secondModal input[name=origin]").val(type);
        $("#secondModal #secondName").attr("placeholder", placeholder);
        $("#secondModal .level-label").text($(this).data("level-label"));
        $("#secondModal .type-label").text($(this).data("type-label"));
        $("#secondName").attr('data-remote','/admin/categories/validator?type='+type+'&level='+level+(id?'&parentId='+id:''));
        $("#addSecondLevelForm").attr('action','/admin/categories' + (id?'?parentId='+id:''));
        $("#secondModal").modal('show');
    });

    $(".tree-container .del-btn").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        $("#deleteCategoryModal input[name=id]").val(id);
        $("#deleteCategoryModal .category-name").text($(this).data("name"));
        $("#delLevelForm").attr('action', '/admin/categories/'+id);
        $("#deleteCategoryModal").modal('show');
    });

    $(".tree-container .edit-btn").on('click', function (e) {
        e.stopPropagation();
        var value = $(this).data('name');
        var level = $(this).data('level-label');
        var id = $(this).data('id');
        var modal = $("#editCategoryModal");
        modal.find(".control-label").text(level);
        modal.find(".form-control").val(value);
        $("#editCategoryModal input[name=id]").val(id);
        $("#editLevelForm").attr('action', '/admin/categories/'+id+'/edit');
        $("#editName").attr('data-remote','/admin/categories/validator/edit?id='+id);
        modal.modal('show');
    })

}(jQuery)
