+function ($) {
    'use strict';

    $.each($(".icon-div"), function (i, item) {
        $(item).mouseover(div_fadeIn);
        $(item).mouseout(div_fadeOut);
    });

    function div_fadeIn() {
        $('#show-cart-detail' + $(this).attr('id').replace("icon-div", "")).show();
    }

    function div_fadeOut() {
        $('#show-cart-detail' + $(this).attr('id').replace("icon-div", "")).hide();
    }

}(jQuery);
