+function ($) {
    'use strict';

    $(".personal-container #openPersonPasswordMadel").on("click", function (e) {
        var id = $(this).data("id");
        $("#changePasswordForm input[name=id]").val(id);
        $("#changePersonalPassword").modal("show");
    });

    $("#changePasswordForm .conform").on('click', function (e) {
        var form = $("#changePasswordForm");
        form.validator('validate');
        if(!form.validator().data('bs.validator').hasErrors()){
            $.ajax({
                url: '/admin/system/personal/changePassword',
                type: 'post',
                data: form.serialize(),
                success: function (res) {
                    form[0].reset();
                    $("#changePersonalPassword").modal("hide");
                },
                error: function (res) {
                    $("#changePersonalPassword").modal("hide");
                }
            });
        }

    })

}(jQuery);
