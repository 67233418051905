+function ($) {
    'use strict';

    if($("[name='commodityEditor']").length > 0) {
        var editor = CKEDITOR.replace('commodityEditor', {
            extraPlugins: 'uploadimage,image2',
            height: 300,
            image_previewText: ' ',
            filebrowserUploadUrl: '/admin/ckeditor/files',
            filebrowserImageUploadUrl: '/admin/ckeditor/files',
            stylesSet: [
                {name: 'Narrow image', type: 'widget', widget: 'image', attributes: {'class': 'image-narrow'}},
                {name: 'Wide image', type: 'widget', widget: 'image', attributes: {'class': 'image-wide'}}
            ],
            onUploaded: function (file, data, response) {
            }
        });
        var initValue = $("#editValue").val();
        if(initValue) {
            editor.setData(initValue);
        }
        editor.on( 'change', function( event ) {
            var data = this.getData();//内容
            $("#editValue").val(data)
        });
    }

    $(".round-tags").tagsinput({});

    if($("#shapeFlag").val() == "CYLINDER") {
        $("#squareOption").hide();
        $("#cylinderOption").show();
    } else {
        $("#squareOption").show();
        $("#cylinderOption").hide();
    }

    $(".commodity-add .shape-radio").on('change', function () {
        $("#squareOption").toggle();
        $("#cylinderOption").toggle();
    });

    $(".name-input").on('change blur keyup input', function (e) {
        var len = $(this).val().length;
        var count = $('#nameCount');
        count.text(len);
        if (len === 20) {
            count.addClass("text-warning")
        }
    })

    $(".description-input").on('change blur keyup input', function (e) {
        var len = $(this).val().length;
        var count = $('#descriptionCount');
        count.text(len);
        if (len === 20) {
            count.addClass("text-warning")
        }
    })

    $(".commodity-add-conform").on('click', function (e) {
        shapeOption();
        var price = $("input[name='semiSide.price']").val();
        var marketPrice = $("input[name='semiSide.marketPrice']").val();
        $("#defaultNowSelling").val(false);
        $("input[name='roughSide.price']").val(price);
        $("input[name='roughSide.marketPrice']").val(marketPrice);
        $("input[name='concentrateSide.price']").val(price);
        $("input[name='concentrateSide.marketPrice']").val(marketPrice);
        $('#commodityAddForm').submit()
    });

    $(".commodity-onshelf-conform").on('click', function (e) {
        shapeOption();
        $("#defaultNowSelling").val(true);
        $('#commodityAddForm').submit()
    });

    function shapeOption() {
        if($("#squareOption").css("display")=="none") {
            $("#squareOption").remove()
        } else {
            $("#cylinderOption").remove()
        }
    }

    $(".add-label").on('click', function (e) {
        $(".note-error").text('');
    });

    $("#addLabel .conform").on('click', function (e) {
        var form = $("#addLabel");
        form.validator('validate');
        if(!form.validator().data('bs.validator').hasErrors()){
            $.ajax({
                url: '/admin/commodityLabels/add',
                type: 'post',
                data: form.serialize(),
                success: function (res) {
                    if(res.name) {
                        var value = res.id.toString()+'`'+res.name;
                        $("#commodityLabelChosen").append($("<option key='"+res.id+"' value='"+value+"'>"+res.name+"</option>"));
                        $("#commodityLabelChosen").selectpicker("refresh");
                    }
                    $("#addLabelModal").modal("hide");
                    form[0].reset()
                },
                error: function (res) {
                    if (res.status == 401) {
                        $(".note-error").text('('+res.responseText+')');
                    } else {
                        $("#addLabelModal").modal("hide");
                    }
                }
            });
        }

    })
    $("#addSteelType .conform").on('click', function (e) {
        var form = $("#addSteelType");
        form.validator('validate');
        if(!form.validator().data('bs.validator').hasErrors()){
            $.ajax({
                url: '/admin/steelTypes/add',
                type: 'post',
                data: form.serialize(),
                success: function (res) {
                    if (res.name) {
                        $("#commodityTypeChosen").append($("<option key='" + res.id + "' value='" + res.id + "'>" + res.name + ' | 密度：' + res.density + ' kg/mm³' + "</option>"));
                        $("#commodityTypeChosen").selectpicker("refresh");
                    }
                    $("#addTypeModal").modal("hide");
                    form[0].reset()
                },
                error: function (res) {
                    $("#addTypeModal").modal("hide");
                }
            });
        }
    })
    // 点击 眼睛 显示或隐藏会员价
    $("#openEye").on('click',function(){
        $("#openEye").hide();
        $("#closeEye").show();
        $("#memberPriceHide").hide();
        $("#memberPriceShow").show();
    })
    $("#closeEye").on('click',function(){
        $("#openEye").show();
        $("#closeEye").hide();
        $("#memberPriceHide").show();
        $("#memberPriceShow").hide();
    })


    // 已充值页面 table 的点击添加三行
    $(".detail-table-add-line1").on('click',function(){
        // const dataId = $(this).attr('data-id');
        for(var i =0;i<3;i++){
            var newTr = $('<tr></tr>');
            newTr.append($(".spec-content").html());
            $("#addDetailTable1").append(newTr);
        }
    })
    $(".detail-table-add-line2").on('click',function(){
        for(var i =0;i<3;i++){
            var newTr = $('<tr></tr>');
            newTr.append($(".spec-content").html());
            $("#addDetailTable2").append(newTr);
        }
    })

    $(".detail-table-add-line-select").on('click',function(){
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var selectHtml = "\n " +
                "<td>\n" +
                "<input type=\"text\" placeholder=\"请先选择加工类型\" name=\"proLength\" oninput=\"value=value.replace(/[^\\d]/g,'')\" readonly=\"readonly\">\n" +
                "</td>\n" +
                "<td>\n" +
                "<input type=\"text\" placeholder=\"请先选择加工类型\" name=\"proWidth\" oninput=\"value=value.replace(/[^\\d]/g,'')\" readonly=\"readonly\">\n " +
                "</td>\n" +
                "<td class=\"progress-type-not-semi\">\n" +
                "<input type=\"text\" placeholder=\"请先选择加工类型\" name=\"proThickness\" oninput=\"value=value.replace(/[^\\d]/g,'')\" readonly=\"readonly\">\n " +
                "</td>\n" +
                "<td class=\"progress-type-semi\" style=\"display: none;\">\n" +
                "<select class=\"diameter-or-thickness-selection\" name=\"proThicknessSEMI\" style=\"width: 150px\">\n " +
                "<option value=\"\"></option>\n " +
                "</select>\n " +
                "</td>\n " +
                "<td class=\"td-color-normal\"><input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\"></td>\n " +
                "<td><button class=\"deleteButton\" style=\"background-color: transparent;\" onclick=\"removeRow(this)\">删除</button></td>\n                        "
            newTr.append(selectHtml);
            $("#addDetailTable3").append(newTr);
        }
    })



    $(".detail-table-add-line4").on('click',function(){
        for(var i =0;i<3;i++){
            var newTr = $('<tr></tr>');
            newTr.append($(".spec-content").html());
            $("#addDetailTable4").append(newTr);
        }
    })
    $(".detail-table-add-line5").on('click',function(){
        for(var i =0;i<3;i++){
            var newTr = $('<tr></tr>');
            newTr.append($(".spec-content").html());
            $("#addDetailTable5").append(newTr);
        }
    })

    // 余额充值遮罩+余额提现遮罩
    $("#investBalance").on('click',function(){
        $("#dialogInvest").show();
        $("#investAlert").show();
    })
    $("#dialogInvest").on('click',function(){
        $("#dialogInvest").hide();
        $("#investAlert").hide();
        $("#withdrawAlert").hide();
        $("#withdrawSuccessAlert").hide();
        $("#investSuccessAlert").hide();
        $("#withdrawalApplicationSuccessAlert").hide();
    })
    $("#investAlertCancelBtn").on("click",function(){
        $("#investAlert").hide();
        $("#dialogInvest").hide();
    })
    $("#WithdrawClick").on('click',function(){
        $("#dialogInvest").show();
        $("#withdrawAlert").show();
    })
    $("#withdrawAlertBtn").on("click",function(){
        $("#withdrawAlert").hide();
        $("#dialogInvest").hide();
    })
    $("#withdrawSuccessAlertBtn").on("click",function(){
        $("#withdrawSuccessAlert").hide();
        $("#dialogInvest").hide();
    })
    $("#withdrawalSuccessAlertBtn").on("click",function(){
        $("#withdrawalApplicationSuccessAlert").hide();
        $("#dialogInvest").hide();
    })

    $("#investSuccessAlertBtn").on("click",function(){
        $("#investSuccessAlert").hide();
        $("#dialogInvest").hide();
    })


    // 详情页购物车弹窗
    // $('.shop-cart-btn-right').on('click',function(){
    //     if($('.shopping-cart-dialog').css({"display":"none"})){
    //         $('.shopping-cart-dialog').show();
    //     }else if($('.shopping-cart-dialog').css({"display":"block"})){
    //         $('.shopping-cart-dialog').hide();
    //     }
    // })
    // $("#shoppingCartContentCloseBtn").on('click',function(){
    //     $('.shopping-cart-dialog').hide();
    // })
    //
    // // 详情页右边栏购物车弹框tab切换
    // $('.shopping-cart-tab ul li').on('click',function(event){
    //     $(this).addClass('current');
    //     $(this).siblings().removeClass('current');
    //     $(".shopping-cart-credit").eq($(this).index()).removeClass('display-none');
    //     $(".shopping-cart-credit").eq($(this).index()).siblings().addClass('display-none');
    // })
    //
    // $(function(){
    //     pushHistory();
    //     window.addEventListener("popstate", function(e) {
    //         console.log(11111)
    //         alert("我监听到了浏览器的返回按钮事件啦");//根据自己的需求实现自己的功能
    //     }, false);
    //     function pushHistory() {
    //         var state = {
    //             title: "title",
    //             url: "#"
    //         };
    //         window.history.pushState(state, "title", "#");
    //     }
    // });
    // window.onbeforeunload = function()
    // {
    //     if(is_form_changed())
    //     {
    //         return "您的修改内容还没有保存，您确定离开吗？";
    //     }
    // }
    //
    // function is_form_changed()
    // {
    //     //检测页面是否有保存按钮
    //     var t_save = jQuery(".detail-table");
    //     //检测到保存按钮,继续检测元素是否修改
    //     if(t_save.length>0)
    //     {
    //         var is_changed = false;
    //         jQuery("input, textarea, select").each(function()
    //         {
    //             var _v = jQuery(this).attr('_value');
    //             if(typeof(_v) == 'undefined')
    //                 _v = '';
    //
    //             if(_v != jQuery(this).val())
    //                 is_changed = true;
    //         });
    //
    //         return is_changed;
    //     }
    //     return false;
    // }
    //
    // jQuery(document).ready(function()
    // {
    //     jQuery("#A4 input, #A4 textarea, #A4 select").each(function()
    //     {
    //         jQuery(this).attr('_value', jQuery(this).val());
    //     });
    // });
}(jQuery);
