+function ($) {
    'use strict';
    var page_total_v = $(".mall-commodities-v .page-total").val();
    var page_Current_v = GetQueryString("page");
    $(".mall-commodities-v #current-page").html(page_Current_v*1+1);
    $(".mall-commodities-v #totle-page").html(page_total_v)

    $(".mall-commodities-v .mall-page").on('click', function (e) {
        search()
    });

    $(".mall-commodities-v .mall-page-size-selector").on('change', function () {
        search(1)
    });

    function GetQueryString(name)
    {
        var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if(r!=null)return  unescape(r[2]); return null;
    }

    $(".transverse-button").on('click',function () {
        // var pageCurrent = GetQueryString("page");
        var size = $(".mall-commodities-v .mall-page-size-selector").val();
        var requestId =  $(".request-list1-id").val();
        var btn_icon = $(".judge_val_t").val();
        if(page_Current_v==null){
            page_Current_v=0;
        }
        var sort = btn_icon*1 > 0 ? 'desc' : 'asc';
        var searchFlg = $(".searchFlg").val();
        var size_sort = '&page=' + page_Current_v + '&size=' + size+'&sort=price,' + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?transverseMember&attributeType=MEMBER' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=transverse&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    });

    function getParams(defaultPage) {
        var page = defaultPage ? defaultPage : Number($(".mall-commodities-v .jump").val());
        var size = $(".mall-commodities-v .mall-page-size-selector").val();
        var total = $(".mall-commodities-v .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }
    }


    function search(page) {
        var params = getParams();
        var requestId =  $(".request-list1-id").val();
        var btn_icon = $(".judge_val_v").val();
        var sort = btn_icon*1 > 0 ? 'desc' : 'asc';
        var searchFlg = $(".searchFlg").val();
        var size_sort = formatParam(params)+'&sort=price,' + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&vertical' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?verticalMember&attributeType=MEMBER&' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=vertical&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    }

    function formatParam(params) {
        return '&page=' + params.page + '&size=' + params.size;
    }

    $("#btn-icon-v").on('click',function () {
        var size = $(".mall-commodities-v .mall-page-size-selector").val();
        var requestId =  $(".request-list1-id").val();
        var btn_icon = $(".judge_val_v").val();
        if(page_Current_v==null){
            page_Current_v=0;
        }
        var sort = btn_icon*1 > 0 ? 'desc' : 'asc';
        var searchFlg = $(".searchFlg").val();
        var size_sort = '&page=' + page_Current_v + '&size=' + size+'&sort=price,' + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&vertical' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?verticalMember&attributeType=MEMBER' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=vertical&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    })

}(jQuery);
