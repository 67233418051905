+function ($) {
    'use strict';

    $(".roles-content .role-list-item").on('click', function (e) {
        e.stopPropagation();
        window.location.href = "/admin/roles?roleId=" + $(this).data("id")
    });

    $(".roles-content .edit-role").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        var name = $(this).data("name");
        $("#editRoleForm input[name=id]").val(id);
        $("#editRoleForm input[name=name]").val(name);
        $("#editRole").modal("show");
    });
    $(".roles-content .delete-role").on('click', function (e) {
        e.stopPropagation();
        var id = $(this).data("id");
        var name = $(this).data("name");
        $.ajax({
            url: "/admin/role/user/validator?id="+id,
            type: 'get',
            success: function (res) {
              if(res === 0) {
                  $("#deleteRoleForm").attr("action", "/admin/roles/" + id);
                  $("#deleteRoleForm .warning-label").text('此操作将永久删除"'+name+'"角色，是否继续？');
              } else {
                  $("#deleteRoleForm .warning-label").text('"'+name+'"角色下存在用户，不能删除！');
                  $("#deleteRoleForm .conform").hide();
              }
              $("#deleteRole").modal("show");
            },
            error: function (res) {

            }
        });
    });

    $("#deleteRoleForm .conform").on('click', function (e) {
        $("#deleteRoleForm").submit();
    });

    var allInput = $("#all_input");
    var oneInput = $(".oneLevel").find(":checkbox");
    var twoInput = $(".twoLevel").find(":checkbox");
    var oneLength = oneInput.length;
    var twoLength = twoInput.length;
    var inputLength = oneLength+twoLength;
    // 全选
    allInput.on("click",function(){
        var cked = $(this).prop("checked")
        oneInput.prop("checked",cked)
        twoInput.prop("checked",cked)
    });
    //一级框
    oneInput.on("click",function(){
        var cked = $(this).prop("checked")
        var childInput = $(this).parent().parent().parent().siblings().find(":checkbox")
        childInput.prop("checked",cked)
        //判断是否全选
        checkAll()
    })
    //二级框
    twoInput.on("click",function(){
        var brothers = $(this).parent().parent().parent().parent().find(":checkbox")
        var brothersCked = $(this).parent().parent().parent().parent().find(":checked")
        var parent = $(this).parent().parent().parent().parent().parent().siblings().find(":checkbox")
        if (brothers.length==brothersCked.length || brothersCked.length!=0 ){
            parent.prop("checked",true)
        }else {
            parent.prop("checked",false)
        }
        checkAll()
    })
    //判断全选
    function checkAll() {
        var oneInputCked = $(".oneLevel").find(":checked");
        var twoInputCked = $(".twoLevel").find(":checked");
        if ((oneInputCked.length+twoInputCked.length)==inputLength){
            allInput.prop("checked",true)
        } else {
            allInput.prop("checked",false)
        }
    }
}(jQuery);
