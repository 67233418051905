+function ($) {
    "use strict";


    // 商品详情弹窗
    $("#balancePay").on("click", function (e) {
        $("#balancePay").prop("checked", true)
        $(".balance-pay-content").css("display", "flex")
        $("#alipay").prop("checked", false)
        $(".alipay-content").css("display", "none")
        $("#weChatPay").prop("checked", false)
        $(".we-chat-pay-content").css("display", "none")
        $("#unionpay").prop("checked", false)
        $(".unionpay-content").css("display", "none")
        $(".payment-orders-price").text('￥' + $("#commodityMemberAmount").val())
    })

    $("#alipay").on("click", function (e) {
        $("#balancePay").prop("checked", false)
        $(".balance-pay-content").css("display", "none")
        $("#alipay").prop("checked", true)
        $(".alipay-content").css("display", "flex")
        $("#weChatPay").prop("checked", false)
        $(".we-chat-pay-content").css("display", "none")
        $("#unionpay").prop("checked", false)
        $(".unionpay-content").css("display", "none")
        $(".payment-orders-price").text('￥' + $("#commodityAmount").val())
    })

    var weChatPayFlag = true;
    var intervalId;
    $("#weChatPay").on("click", function (e) {
        $("#balancePay").prop("checked", false)
        $(".balance-pay-content").css("display", "none")
        $("#alipay").prop("checked", false)
        $(".alipay-content").css("display", "none")
        $("#weChatPay").prop("checked", true)
        $(".we-chat-pay-content").css("display", "flex")
        $("#unionpay").prop("checked", false)
        $(".unionpay-content").css("display", "none")
        $(".payment-orders-price").text('￥' + $("#commodityAmount").val())
        if(weChatPayFlag){
            $.ajax({
                url:'/mall/weChatPcPay/'+$(".order-number").data("id"),
                type:'post',
                success:function (res) {
                    $('#we-chat-qr-code').html('');
                    $('#we-chat-qr-code').qrcode({text: res, width: 211, height: 211});
                    weChatPayFlag = false;
                    setTimeout(function () {
                        weChatPayFlag = true;
                        clearInterval(intervalId);
                    }, 1800000);
                    intervalId = setInterval(function(){ orderQuery($(".order-number").data("id")) }, 3000);
                }
            })
        }
    });
    function orderQuery(orderId) {
        $.ajax({
            url:'/api/order-query?orderId='+orderId,
            type:'get',
            success:function (res) {
                if (res == 'SUCCESS') {
                    clearInterval(intervalId);
                    window.location.href = '/mall/orders/'+orderId;
                } else if (res == 'FAIL') {
                    clearInterval(intervalId);
                }
            }
        })
    }

    $("#unionpay").on("click", function (e) {
        $("#balancePay").prop("checked", false)
        $(".balance-pay-content").css("display", "none")
        $("#alipay").prop("checked", false)
        $(".alipay-content").css("display", "none")
        $("#weChatPay").prop("checked", false)
        $(".we-chat-pay-content").css("display", "none")
        $("#unionpay").prop("checked", true)
        $(".unionpay-content").css("display", "block")
        $(".payment-orders-price").text('￥' + $("#commodityAmount").val())
    })

    $(".alipay-button").on('click', function () {
        $.ajax({
            url:'/mall/aliPcPay/'+$(".order-number").data("id"),
            type:'post',
            success:function (res) {
                $(".alipay-content").append(res);
            }
        })
    })

}(jQuery);
