+function () {
    'use strict';

    var imageSerial = '';
    var cutFiles = {};
    var jcropApi = null;
    var fileinput = $("#addBanner");
    // var filelicenseinput = $("#addlicensepic");
    var allowedFileExtensions = ["jpg", "png","BMP","JPEG","GIF","PSD","TIFF","TGA","EPS"];
    if(fileinput==null||fileinput==''){
        allowedFileExtensions=["jpg", "png","BMP","JPEG"];
    }
    var urls = [];
    imageSerial = $("#imageSerial").val();
    if (imageSerial) {
        urls = '<img src="/files/fact2/' + imageSerial+'" class="full-width">';
    }

    fileinput.fileinput({
        initialPreview: urls,
        uploadUrl: "/api/files",
        maxFileCount: 1,
        browseOnZoneClick: true,
        language: "zh",
        showCancel: false,
        showRemove: false,
        showUpload: false,
        showBrowse: false,
        allowedFileExtensions: allowedFileExtensions,
        maxFileSize: fileinput.data("max"),
        dropZoneTitle: '',
        dropZoneClickTitle: '<div class="banner-icon"><i class="iconfont icon-shangchuan"></i></div>',
        showCaption: false,
        previewClass: fileinput.data("preview") ,
        frameClass: 'banner-frame'
    }).on('fileloaded', function (event, file, previewId, index, reader) {
        $(".cut-image-area").attr("src", reader.result);
        $('#cutImage').Jcrop({
            aspectRatio: fileinput.data("radio"),
            onDblClick: function (e) {
                cutFiles[previewId] = e;
                var option = cutFiles[previewId];
                option.type = file.type;
                option.name = file.name;
                fileinput.fileinput('_cutImage', $("#"+previewId).data("fileid"), option, file);
                $("#cutModal").modal("hide");
                jcropApi.destroy();
                setTimeout(function () {
                    fileinput.fileinput("upload")
                }, 100);
            }
        }, function () {
            jcropApi = this;
        });
        $("#cutModal").modal("show");
    }).on('fileuploaded', function (event, data, id, index) {
        imageSerial = data.response.fileRelativeAddress;
        $("#"+id +" .file-preview-image").attr("src", "/files/fact2/"+imageSerial);
        $("#zoom-"+id +" .file-preview-image").attr("src", "/files/fact2/"+imageSerial);
        $("#imageSerial").val(imageSerial);
    }).on('filesuccessremove', function (event, id, index) {  //上传后，移除文件
        $.ajax({
            url: '/files/fact2/' + imageSerial,
            type: 'delete',
            success: function (data) {
            }
        });
        imageSerial = "";
        $("#imageSerial").val(imageSerial);
    }).on('fileremoved', function (event, id, index) {  //未上传，移除文件
        imageSerial = "";
        $("#imageSerial").val(imageSerial);
    });


    $(".banner-del").on('click', function (e) {
        var id = $(this).data("id");
        $("#delBanner").attr("action", "/admin/banners/"+id);
        $("#delBannerModal").modal("show");
    })

        $(".upload-image-btn").on('click', function (e) {
            $(".file-drop-zone").click();
        })

    $(".credit-submit-btn").on('click', function (e) {
        var creditapplyname = $(".credit-apply-name").val()
        var creditapplyphone = $(".credit-apply-phone").val()
        var creditapplyreviewAddress = $(".credit-apply-reviewAddress").val()
        var creditapplyserial = $(".credit-apply-serial").val()
        var errorarea = $(".error-area")
        if(isEmpty(creditapplyname)){
            errorarea.css("display","block")
            errorarea.text("请输入您的姓名")
            return false
        }
        if(isEmpty(creditapplyphone)){
            errorarea.css("display","block")
            errorarea.text("请输入联系电话")
            return false
        }
        if(isEmpty(creditapplyreviewAddress)){
            errorarea.css("display","block")
            errorarea.text("请输入审核地址")
            return false
        }
        if(isEmpty(creditapplyserial)){
            errorarea.css("display","block")
            errorarea.text("请上传图片")
            return false
        }
        $(this).submit;
    })

}(jQuery);
