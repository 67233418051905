+function ($) {
    'use strict';
    var size = $(".mall-commodities-t .page-size-selector").val();
    var page_total_t = $(".mall-commodities-t .page-total").val();
    var page_Current_t = GetQueryString("page");
    $(".mall-commodities-t #current-page").html(page_Current_t*1+1);
    $(".mall-commodities-t #totle-page").html(page_total_t)

    $(".mall-commodities-t .mall-page").on('click', function (e) {
        search()
    });

    $(".mall-commodities-t .page-size-selector").on('change', function () {
        search(1)
    });

    function GetQueryString(name)
    {
        var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if(r!=null)return  unescape(r[2]); return null;
    }

    $(".vertical-button").on('click',function () {
        var size = $(".mall-commodities-t .page-size-selector").val();
        var requestId =  $(".request-list2-id").val();
        var btn_icon = $(".judge_val_v").val();
        if(page_Current_t==null){
            page_Current_t=0;
        }
        var sort = btn_icon*1 > 0 ? 'desc' : 'asc';
        var searchFlg = $(".searchFlg").val();
        var size_sort = '&page=' + page_Current_t + '&size=' + size+'&sort=price,' + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&vertical' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?verticalMember&attributeType=MEMBER' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=vertical&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    });

    function getParams(defaultPage) {
        var page = defaultPage ? defaultPage : Number($(".mall-commodities-t .jump").val());
        var size = $(".mall-commodities-t .page-size-selector").val();
        var total = $(".mall-commodities-t .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }

    }

    function search(page) {
        var params = getParams();
        var requestId =  $(".request-list2-id").val();
        var btn_icon = $(".judge_val_t").val();
        var sort = $("#btn-icon-t").hasClass("active") ? ('&sort=price,' + (btn_icon*1 > 0 ? 'desc' : 'asc')) : '';
        var searchFlg = $(".searchFlg").val();
        var size_sort = formatParam(params) + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?transverseMember&attributeType=MEMBER&' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=transverse&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    }

    function formatParam(params) {
        return '&page=' + params.page + '&size=' + params.size;
    }

    $("#btn-sort-d").on('click',function () {
        var requestId =  $(".request-list2-id").val();
        if(page_Current_t==null){
            page_Current_t=0;
        }
        var searchFlg = $(".searchFlg").val();
        var size_sort = '&page=' + page_Current_t + '&size=' + size;
        var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?transverseMember&attributeType=MEMBER' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=transverse&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    });

    $("#btn-icon-t").on('click',function () {
        var requestId =  $(".request-list2-id").val();
        var btn_icon = $(".judge_val_t").val();
        if(page_Current_t==null){
            page_Current_t=0;
        }
        var sort = btn_icon*1 > 0 ? 'desc' : 'asc';
        var searchFlg = $(".searchFlg").val();
        var size_sort = '&page=' + page_Current_t + '&size=' + size+'&sort=price,' + sort;
        var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        var isMember = $("#isMember").val();
        if(isMember!=null){
            window.location.href = '/mall/commodities?transverseMember&attributeType=MEMBER' + size_sort;
        }else {
            window.location.href =  '/mall/commodities?showFlg=transverse&searchFlg=' + searchFlg + size_sort + urlStr;
        }
    });

    $(".w-button-search").on("click", function () {
        var searchName = $('.j-searchInput').val();
        location.href = '/mall/commodities?search&showFlg=transverse&searchFlg=search&searchName=' + searchName;
    });

}(jQuery);
