+function ($) {

    $(document).ready(function(){
        viewPrice();
        processLevelChange();
        if ($(".detail-div").attr('id') == "STANDARD") {
            // standardSelector();
        } else if ($("#attributeType").val() !== "MEMBER" && $(".detail-div").attr('id') == "SQUARE" || $(".detail-div").attr('id') == "CYLINDER"){
            diameterOrThicknessSelector();
        }
    })

    function viewPrice() {
        var attributeType = $("#attributeType").val();
        var isRecharge = $("#isRecharge").val();
        var isAnonymousUser = $(".isAnonymousUser").val();
        if (isRecharge == true) {
            $("#onlyMember").hide();
            $("#hasRecharge").show();
            $(".notRecharge").hide();
            $("#recommendCommodity").hide();
        } else if (isRecharge == false && isAnonymousUser == false && attributeType != "MEMBER" && attributeType != "RECOMMEND") {
            $("#onlyMember").hide();
            $("#hasRecharge").hide();
            $(".notRecharge").show();
            $("#recommendCommodity").hide();
        }
        if (attributeType == "MEMBER") {
            $("#onlyMember").show();
            $("#hasRecharge").hide();
            $(".notRecharge").hide();
            $("#recommendCommodity").hide();
        }

        if (attributeType == "RECOMMEND") {
            $("#onlyMember").hide();
            $("#hasRecharge").hide();
            $(".notRecharge").hide();
            $("#isAnonymousUser").hide();
            $("#recommendCommodity").show();
        }
    }

    $.each($(".smallimg"), function (i, item) {
        // $(item).click(mictab_content);
        $(item).hover(img_change);
        // $(item).mouseout(imgblur_change)
    })
    function img_change() {
        $(".bigimg").attr("src",$(this).attr("src"));
        $(this).parent().css("padding","0px")
        $(this).parent().css("border","2px solid #ee0000")
        $(this).parent().siblings().css("padding","auto")
        $(this).parent().siblings().css("border","0px solid #ee0000")
    }
    function imgblur_change() {
        $(this).css("padding","auto")
        $(this).css("border","0px solid #ee0000")
    }

    var $addCart = $("#addCart,#buy");
    var $quantity = $("#quantity");

    // 加入购物车
    $addCart.click(function(e) {// 要判断输入框是否有值，有数值的装进数组
        var goodsid = $(".detail-content").attr('id');
        var brand = $("#brand").val();
        var goodsshape = $(".detail-div").attr('id');
        var creditvalue = $("#creditvalue").val()
        var processLevel = $("#processLevel").val();
        var chamfer =$("#chamfer option:selected").val();
        var posi_nega = $("#POSI_NEGA").val();
        var posi_negavalue = $("#POSI_NEGAVALUE").val();
        var processway = $("#processway").val();
        var specificationArr;
        var is_send = true;
        $('.shopping-cart-dialog').css('display','none');
        if(goodsshape=='SQUARE') {
            processLevel = processLevel== "SEMI" ? $("#selectCommon option:selected").val() : $("#processLevel").val();
            specificationArr = squareSpecificationArr();
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if(goodsshape=='CYLINDER'){
            specificationArr = cylinderSpecificationArr();
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if (goodsshape == 'STANDARD') {
            processway = 0;
            processLevel = "STANDARD";
            chamfer = false;
            posi_nega = "POSI_NEGA";
            posi_negavalue = 0;
            specificationArr = standardSpecification();
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }


            var data= {
                "commodityId": goodsid,
                "brand": brand,
                "processType": processLevel,
                "chamfer": chamfer,
                "modelShape": goodsshape,
                "toleranceType": posi_nega,
                "tolerance": posi_negavalue,
                "additionalProcessing": processway,
                "credit": creditvalue,
                "manner": $("#processway option:selected").text(),
                "specifications": specificationArr
            };
            if(is_send) {
                var exceptionFlag = validatePrice();
                if (exceptionFlag == false) {
                    console.log(".....");
                    $.ajax({
                        url: "/api/cartCommodities",
                        type: "post",
                        contentType: "application/json",
                        data: JSON.stringify(data),
                        dataType: "json",
                        cache: false,

                        success: function (message) {
                            if (e.currentTarget.id == "addCart") {
                                $.message("success", "加入购物车成功！");
                                $(".j-cart-num").html(parseInt($(".j-cart-num").html()) + 1);
                                if (message.newCommodity) {
                                    $("#cartCommoditiesSize").text(parseInt($("#cartCommoditiesSize").text())+1);
                                }
                            }
                            if (e.currentTarget.id == "buy") {
                                location.href = "/mall/orders?confirm&credit=" + creditvalue + "&cartids=" + message.id;
                            }
                        },
                        error: function (res) {
                            if (res.status == 401) {
                                location.href = "/mall/commodities/login-first/" + goodsid;
                            }
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            var title = JSON.parse(XMLHttpRequest.responseText).title;
                            if (title === "Unauthorized") {
                                location.href = "/mall/login"
                            } else {
                                alert("错误信息: " + title); //获取的信息即是异常中的Message

                            }
                        }
                    });
                } else {
                    alert("错误信息: 含未配置规格的商品");
                }
            }
    });


    function validatePrice() {
        var exceptionFlag = false;
        var goodsid = $(".detail-content").attr('id');
        var goodsshape = $(".detail-div").attr('id');
        var creditvalue = $("#creditvalue").val();
        var processLevel = $("#processLevel").val();
        var chamfer =$("#chamfer option:selected").val();
        var posi_nega = $("#POSI_NEGA").val();
        var posi_negavalue = $("#POSI_NEGAVALUE").val();
        var processway = $("#processway").val();
        var specificationArr;
        var is_send = true;
        if(goodsshape=='SQUARE') {
            specificationArr = squareSpecificationArr("computePrice");
            processLevel = processLevel== "SEMI" ? $("#selectCommon option:selected").val() : $("#processLevel").val();
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if(goodsshape=='CYLINDER'){
            specificationArr = cylinderSpecificationArr("computePrice");
            processLevel = "SEMI";
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if (goodsshape == 'STANDARD') {
            specificationArr = standardSpecification("computePrice");
            processway = 0;
            processLevel = "STANDARD";
            chamfer = false;
            posi_nega = "POSI_NEGA";
            posi_negavalue = 0;
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        var data= {
            "commodityId": goodsid,
            "processType": processLevel,
            "chamfer": chamfer,
            "modelShape": goodsshape,
            "toleranceType": posi_nega,
            "tolerance": posi_negavalue,
            "additionalProcessing": processway,
            "credit": creditvalue,
            "manner": $("#processway option:selected").text(),
            "specifications": specificationArr
        };
        if(is_send) {
            $.ajax({
                url: "/api/price",
                type: "post",
                async: false,
                contentType: "application/json",
                data: JSON.stringify(data),
                dataType: "json",
                cache: false,
                success: function (message) {
                    if (message.canBuy == false) {
                        exceptionFlag = true;
                        return exceptionFlag;
                    }
                    return false;
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    exceptionFlag = true;
                }
            });
        }
        return exceptionFlag;
    }

    fillselection();

    function processLevelChange(){
        var $this = $("#processLevel");
        $('.judge-chamfer').show();
        $(".progress-type-not-semi").show();
        $(".progress-type-semi").hide();
        $("#singlePrice").text("￥0.00");
        $("#unitWeight").text("0.00kg");
        $("#processPrice").text("0.00");
        if ($("#attributeType").val() === "MEMBER") {
            $("input[name='proWidth']").val("");
            $("input[name='proLength']").val("");
            $("input[name='proThickness']").val("");
            $("input[name='proWidth']").attr("readonly", $this.val() == "0" ? true : false);
            $("input[name='proLength']").attr("readonly", $this.val() == "0" ? true : false);
            $("input[name='proThickness']").attr("readonly", $this.val() == "0" ? true : false);
            $(".progress-type-not-semi").show();
            $(".progress-type-semi").hide();
            if ($this.val() == "0") {
                $("input[name='proLength']").attr("placeholder", "请先选择加工类型");
                $("input[name='proWidth']").attr("placeholder", "请先选择加工类型");
                $("input[name='proThickness']").attr("placeholder", "请先选择加工类型");
            } else {
                $("input[name='proLength']").attr("placeholder", "请输入");
                $("input[name='proWidth']").attr("placeholder", "请输入");
                $("input[name='proThickness']").attr("placeholder", "请输入");
            }
            if ($this.val() == "CONCENTRATE") {
                $("#POSI_NEGAVALUE").val("0.05");
            } else if ($this.val() == "ROUGH"){
                $("#POSI_NEGAVALUE").val("0.5");
            }
            $(".detail-table-add-line-select").hide();
            $(".detail-table-add-line-jl").hide();
            $(".detail-table-add-line-gb").hide();
            $(".detail-table-add-line-ml").hide();
            $(".detail-table-add-line-member").show();
            $(".detail-tolerance").show();
        } else if ($this.val() == "0") {
            $("#selectCommon").hide();
            $("input[name='proWidth']").val("");
            $("input[name='proLength']").val("");
            $("input[name='proThickness']").val("");
            $("input[name='proWidth']").attr("placeholder", "请先选择加工类型");
            $("input[name='proLength']").attr("placeholder", "请先选择加工类型");
            $("input[name='proThickness']").attr("placeholder", "请先选择加工类型");
            $("input[name='proWidth']").attr("readonly", true);
            $("input[name='proLength']").attr("readonly", true);
            $("input[name='proThickness']").attr("readonly", true);
            $("input[name='quantity']").attr("readonly", true);
            $("input[name='quantity']").attr("placeholder", "请先选择加工类型");
            $(".detail-table-add-line-select").show();
            $(".detail-table-add-line-jl").hide();
            $(".detail-table-add-line-gb").hide();
            $(".detail-table-add-line-ml").hide();
            $(".detail-table-add-line-member").hide();
            $(".detail-tolerance").show();
        } else if ($this.val() == "CONCENTRATE") {
            $("input[name='proWidth']").val("");
            $("input[name='proLength']").val("");
            $("input[name='proThickness']").val("");
            $("input[name='proWidth']").attr("readonly", false);
            $("input[name='proLength']").attr("readonly", false);
            $("input[name='proThickness']").attr("readonly", false);
            $("input[name='quantity']").val(1);
            $("input[name='quantity']").attr("readonly", false);
            $("#POSI_NEGAVALUE").val("0.05");
            $("#selectCommon").hide();
            $(".progress-type-not-semi").show();
            $(".progress-type-semi").hide();
            var lenArr = $("#lengthConcentrate").val().split(",");
            var widArr = $("#widthConcentrate").val().split(",");
            var thickArr = $("#thicknessConcentrate").val().split(",");
            $("input[name='proLength']").attr("placeholder", "范围" + lenArr[0] + "到" + lenArr[1]);
            $("input[name='proWidth']").attr("placeholder", "范围" + widArr[0] + "到" + widArr[1]);
            $("input[name='proThickness']").attr("placeholder", "范围" + thickArr[0] + "到" + thickArr[1]);
            $(".detail-table-add-line-select").hide();
            $(".detail-table-add-line-jl").show();
            $(".detail-table-add-line-gb").hide();
            $(".detail-table-add-line-ml").hide();
            $(".detail-table-add-line-member").hide();
            $(".detail-tolerance").show();
        } else if ($this.val() == "SEMI") {
            $("input[name='proWidth']").val("");
            $("input[name='proLength']").val("");
            $("input[name='proWidth']").attr("readonly", false);
            $("input[name='proLength']").attr("readonly", false);
            $("input[name='proThickness']").attr("readonly", false);
            $("input[name='quantity']").val(1);
            $("input[name='quantity']").attr("readonly", false);
            $("#selectCommon").show();
            $('.judge-chamfer').hide();
            $(".progress-type-not-semi").hide();
            $(".progress-type-semi").show();
            diameterOrThicknessSelector();
            $(".detail-table-add-line-select").hide();
            $(".detail-table-add-line-jl").hide();
            $(".detail-table-add-line-gb").hide();
            $(".detail-table-add-line-ml").show();
            $(".detail-table-add-line-member").hide();
            $(".detail-tolerance").hide();
        } else if ($this.val() == "ROUGH") {
            $("input[name='proWidth']").val("");
            $("input[name='proLength']").val("");
            $("input[name='proThickness']").val("");
            $("input[name='proWidth']").attr("readonly", false);
            $("input[name='proLength']").attr("readonly", false);
            $("input[name='proThickness']").attr("readonly", false);
            $("input[name='quantity']").val(1);
            $("input[name='quantity']").attr("readonly", false);
            $("#POSI_NEGAVALUE").val("0.5");
            $("#selectCommon").hide();
            $(".progress-type-not-semi").show();
            $(".progress-type-semi").hide();
            var lenArr = $("#lengthRough").val().split(",");
            var widArr = $("#widthRough").val().split(",");
            var thickArr = $("#thicknessRough").val().split(",");
            $("input[name='proLength']").attr("placeholder", "范围" + lenArr[0] + "到" + lenArr[1]);
            $("input[name='proWidth']").attr("placeholder", "范围" + widArr[0] + "到" + widArr[1]);
            $("input[name='proThickness']").attr("placeholder", "范围" + thickArr[0] + "到" + thickArr[1]);
            $(".detail-table-add-line-select").hide();
            $(".detail-table-add-line-jl").hide();
            $(".detail-table-add-line-gb").show();
            $(".detail-table-add-line-ml").hide();
            $(".detail-table-add-line-member").hide();
            $(".detail-tolerance").show();
        }
        // getNonStandardPrice();
    }

    $("#processLevel").change(function () {
        processLevelChange()
    });

    $(".deleteButton").on('click',function () {
        $(this).parents("tr").remove();
    });

    $(".detail-table").on('click', function() {
        computePrice();
        return;
    })


    $(".facAndPrice").on('click', function() {
        window.location.href = '/mall/commodities/show/' + this.id;
    })

    function computePrice() {
        var goodsid = $(".detail-content").attr('id');
        var goodsshape = $(".detail-div").attr('id');
        var creditvalue = $("#creditvalue").val();
        var processLevel = $("#processLevel").val();
        var chamfer =$("#chamfer option:selected").val();
        var posi_nega = $("#POSI_NEGA").val();
        var posi_negavalue = $("#POSI_NEGAVALUE").val();
        var processway = $("#processway").val();
        var specificationArr;
        var is_send = true;
        if(goodsshape=='SQUARE') {
            specificationArr = squareSpecificationArr("computePrice");
            processLevel = processLevel== "SEMI" ? $("#selectCommon option:selected").val() : $("#processLevel").val();
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if(goodsshape=='CYLINDER'){
            specificationArr = cylinderSpecificationArr("computePrice");
            processLevel = "SEMI";
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        if (goodsshape == 'STANDARD') {
            specificationArr = standardSpecification("computePrice");
            processway = 0;
            processLevel = "STANDARD";
            chamfer = false;
            posi_nega = "POSI_NEGA";
            posi_negavalue = 0;
            if (specificationArr == undefined || specificationArr.length == 0) {
                is_send = false;
            }
        }
        var data= {
            "commodityId": goodsid,
            "processType": processLevel,
            "chamfer": chamfer,
            "modelShape": goodsshape,
            "toleranceType": posi_nega,
            "tolerance": posi_negavalue,
            "additionalProcessing": processway,
            "credit": creditvalue,
            "manner": $("#processway option:selected").text(),
            "specifications": specificationArr
        };
        if(is_send) {
            $.ajax({
                url: "/api/price",
                type: "post",
                async: false,
                contentType: "application/json",
                data: JSON.stringify(data),
                dataType: "json",
                cache: false,
                success: function (message) {

                    var weight = 0;
                    var processPrice = 0;
                    var interest = 0;
                    var materialCharges = 0;

                    // 需要判断用户是否是会员，会员，则，该价格显示会员价格
                    // if ($("#isRecharge").val() == "true") {
                    if ($("#attributeType").val() == "MEMBER") {
                        $.each(message.specifications, function (i) {
                            weight += message.specifications[i].weight;
                            processPrice += message.specifications[i].processingCharges;
                            interest += message.specifications[i].interest;
                            materialCharges += message.specifications[i].memberPrice;
                        })
                    } else {
                        $.each(message.specifications, function (i) {
                            weight += message.specifications[i].weight;
                            processPrice += message.specifications[i].processingCharges;
                            interest += message.specifications[i].interest;
                            materialCharges += message.specifications[i].materialCharges;
                        })
                    }
                    $("#unitWeight").html((weight.toFixed(2)) + " kg");
                    $("#processPrice").html(processPrice.toFixed(2));
                    $("#singlePrice").html("￥" + interest.toFixed(2));
                    if (processLevel == "SEMI" || processLevel == "NOMINALSIZE" || processLevel == "NETSIZE") {
                        $("#spanDescribe").text("元锯切费");
                    } else {
                        $("#spanDescribe").text("元加工费");
                    }

                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    var title = JSON.parse(XMLHttpRequest.responseText).title;
                    if (title === "Unauthorized") {
                        location.href = "/mall/login"
                    } else {
                        alert("错误信息: " + title); //获取的信息即是异常中的Message

                    }
                }
            });
        }
    }


    function squareSpecificationArr(str) {

        var widthArrByName = document.getElementsByName("proWidth");
        var lengthArrByName = document.getElementsByName("proLength");
        var quantityArrByName = document.getElementsByName("quantity");
        var thicknessArr = new Array();
        var widthArr = new Array();
        var lengthArr = new Array();
        var quantityArr = new Array();
        var specificationArr = [{
            "quantity": 0,
            "sides": [{
                "sideType": "LENGTH",
                "value": 0
            },
            {
                "sideType": "THICKNESS",
                "value": 0
            },
            {
                "sideType": "WIDTH",
                "value": 0
            }]
        }]
        if ($(".detail-div").attr('id') == "SQUARE" && $("#processLevel").val() == "SEMI") {
            var lenArr = $("#lengthSemi").val().split(",");
            var widArr = $("#widthSemi").val().split(",");
            var thicknessArrByName = [];
            var thicknessArrOfSEMI = $(".diameter-or-thickness-selection option:selected");
            for (var i = 0, j = 0; i < thicknessArrOfSEMI.length; i ++) {
                if (thicknessArrOfSEMI[i].text == "" || thicknessArrOfSEMI[i].text == null || widthArrByName[i].value == "" || lengthArrByName[i].value == "" || quantityArrByName[i].value == "") {
                    continue;
                }
                thicknessArrByName[i] = thicknessArrOfSEMI[i].text.match(/\d+(.\d+)?/g);
                thicknessArr[j] = thicknessArrByName[i];
                widthArr[j] = widthArrByName[i].value;
                lengthArr[j] = lengthArrByName[i].value;
                quantityArr[j] = quantityArrByName[i].value;
                ++ j;
            }
            if (thicknessArr.length == 0) {
                if (str != "computePrice") {
                    $.message("warn", "请填写规格后再提交！");
                }
                return;
            }
            for (var i= 0; i < thicknessArr.length; i++) {
                var sideArr = [{
                    "sideType": "LENGTH",
                    "value": lengthArr[i]
                },
                    {
                        "sideType": "THICKNESS",
                        "value": thicknessArr[i][0]
                    },
                    {
                        "sideType": "WIDTH",
                        "value": widthArr[i]
                    }];
                specificationArr[i] = {"quantity": quantityArr[i], "sides": sideArr}
            }
            // 获取有效商品规格
            for (var i = 0, j = 0; i < thicknessArrByName.length; i++) {
                if (thicknessArrByName[i] == "" && widthArrByName[i].value == "" && lengthArrByName[i].value == "") {
                    continue;
                } else if ((thicknessArrByName[i] != "" && (widthArrByName[i].value == "" || lengthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || widthArrByName[i].value != "" && (thicknessArrByName[i] == "" || lengthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || lengthArrByName[i].value != "" && (thicknessArrByName[i] == "" || widthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || quantityArrByName[i].value != "" && (thicknessArrByName[i] == "" || widthArrByName[i].value == "" || lengthArrByName[i].value == ""))) {
                    if (str != "computePrice") {
                        $.message("warn", "第" + (i + 1) + "条规格数据请填写完整，或者删除！");
                    }
                    return;
                } else {
                    if (parseFloat(lengthArrByName[i].value) < lenArr[0] || parseFloat(lengthArrByName[i].value) > lenArr[1]) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据长度越界，范围：" + lenArr[0] + "~" + lenArr[1]);
                        }
                        return;
                    }
                    if (parseFloat(widthArrByName[i].value) < widArr[0] || parseFloat(widthArrByName[i].value) > widArr[1]) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据宽度越界，范围：" + widArr[0] + "~" + widArr[1]);
                        }
                        return;
                    }
                    if (parseFloat(thicknessArrByName[i]) > parseFloat(widthArrByName[i].value)) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据，厚度不能大于宽度！");
                        }
                        return;
                    }
                    if (parseFloat(widthArrByName[i].value) > parseFloat(lengthArrByName[i].value)) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据，宽度不能大于长度！");
                        }
                        return;
                    }
                }

            }

            return specificationArr;

        } else {
            var lenArr = $("#lengthRough").val().split(",");
            var widArr = $("#widthRough").val().split(",");
            var thickArr = $("#thicknessRough").val().split(",");
            var lenArrConcentrate = $("#lengthConcentrate").val().split(",");
            var widArrConcentrate = $("#widthConcentrate").val().split(",");
            var thickArrConcentrate = $("#thicknessConcentrate").val().split(",");
            var thicknessArrByName = document.getElementsByName("proThickness");

            // 获取有效商品规格
            for (var i = 0, j = 0; i < thicknessArrByName.length; i++) {
                if (thicknessArrByName[i].value == "" && widthArrByName[i].value == "" && lengthArrByName[i].value == "") {
                    continue;
                } else if ((thicknessArrByName[i].value != "" && (widthArrByName[i].value == "" || lengthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || widthArrByName[i].value != "" && (thicknessArrByName[i].value == "" || lengthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || lengthArrByName[i].value != "" && (thicknessArrByName[i].value == "" || widthArrByName[i].value == "" || quantityArrByName[i].value == "")
                    || quantityArrByName[i].value != "" && (thicknessArrByName[i].value == "" || widthArrByName[i].value == "" || lengthArrByName[i].value == ""))) {
                    if (str != "computePrice") {
                        $.message("warn", "第" + (i + 1) + "条规格数据请填写完整，或者删除！");
                    }
                    return;
                } else {
                    if ($("#attributeType").val() != "MEMBER") {
                        if ($("#processLevel").val() == "ROUGH") {
                            if (parseFloat(lengthArrByName[i].value) < lenArr[0] || parseFloat(lengthArrByName[i].value) > lenArr[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据长度越界，范围：" + lenArr[0] + "~" + lenArr[1]);
                                }
                                return;
                            }
                            if (parseFloat(widthArrByName[i].value) < widArr[0] || parseFloat(widthArrByName[i].value) > widArr[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据宽度越界，范围：" + widArr[0] + "~" + widArr[1]);
                                }
                                return;
                            }
                            if (parseFloat(thicknessArrByName[i].value) < thickArr[0] || parseFloat(thicknessArrByName[i].value) > thickArr[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据厚度越界，范围：" + thickArr[0] + "~" + thickArr[1]);
                                }
                                return;
                            }
                        }

                        if ($("#processLevel").val() == "CONCENTRATE") {
                            if (parseFloat(lengthArrByName[i].value) < lenArrConcentrate[0] || parseFloat(lengthArrByName[i].value) > lenArrConcentrate[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据长度越界，范围：" + lenArrConcentrate[0] + "~" + lenArrConcentrate[1]);
                                }
                                return;
                            }
                            if (parseFloat(widthArrByName[i].value) < widArrConcentrate[0] || parseFloat(widthArrByName[i].value) > widArrConcentrate[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据宽度越界，范围：" + widArrConcentrate[0] + "~" + widArrConcentrate[1]);
                                }
                                return;
                            }
                            if (parseFloat(thicknessArrByName[i].value) < thickArrConcentrate[0] || parseFloat(thicknessArrByName[i].value) > thickArrConcentrate[1]) {
                                if (str != "computePrice") {
                                    $.message("warn", "第" + (i + 1) +"条数据厚度越界，范围：" + thickArrConcentrate[0] + "~" + thickArrConcentrate[1]);
                                }
                                return;
                            }
                        }
                    }

                    if (parseFloat(thicknessArrByName[i].value) > parseFloat(widthArrByName[i].value)) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据，厚度不能大于宽度！");
                        }
                        return;
                    }
                    if (parseFloat(widthArrByName[i].value) > parseFloat(lengthArrByName[i].value)) {
                        if (str != "computePrice") {
                            $.message("warn", "第" + (i + 1) +"条数据，宽度不能大于长度！");
                        }
                        return;
                    }
                    if (thicknessArrByName[i].value != "" && widthArrByName[i].value != "" && lengthArrByName[i].value != "" && thicknessArrByName[i].value != null && widthArrByName[i].value != null && lengthArrByName[i].value != null) {
                        thicknessArr[j] = thicknessArrByName[i].value;
                        widthArr[j] = widthArrByName[i].value;
                        lengthArr[j] = lengthArrByName[i].value;
                        quantityArr[j] = quantityArrByName[i].value;
                        ++ j;
                    }
                }

            }
            if (thicknessArr.length == 0) {
                if (str != "computePrice") {
                    $.message("warn", "请填写规格后再提交！");
                }
                return;
            }
            for (var i= 0; i < thicknessArr.length; i++) {
                var sideArr = [{
                    "sideType": "LENGTH",
                    "value": lengthArr[i]
                },
                    {
                        "sideType": "THICKNESS",
                        "value": thicknessArr[i]
                    },
                    {
                        "sideType": "WIDTH",
                        "value": widthArr[i]
                    }];
                specificationArr[i] = {"quantity": quantityArr[i], "sides": sideArr}
            }
            return specificationArr;
        }


    }

    function cylinderSpecificationArr(str) {
        var lenArr = $("#lengthCylinder").val().split(",");
        var lengthArrByName = document.getElementsByName("proLength");
        var diameterArrByName = document.getElementsByName("proDiameter");
        var quantityArrByName = document.getElementsByName("quantity");
        var lengthArr = new Array();
        var diameterArr = new Array();
        var quantityArr = new Array();
        for (var i = 0, j = 0; i < lengthArrByName.length; i++) {

            if (lengthArrByName[i].value == "" || diameterArrByName[i].value == "") {
                continue;
            } else if (parseFloat(lengthArrByName[i].value) < lenArr[0] || parseFloat(lengthArrByName[i].value) > lenArr[1]) {
                $.message("warn", "第" + (i + 1) +"条数据长度越界，范围：" + lenArr[0] + "~" + lenArr[1]);
                return;
            }
            if (lengthArrByName[i].value != "" && lengthArrByName[i].value != null && diameterArrByName[i].value != "" && diameterArrByName[i].value != null) {
                lengthArr[j] = lengthArrByName[i].value;
                diameterArr[j] = diameterArrByName[i].value.match(/\d+(.\d+)?/g);
                quantityArr[j] = quantityArrByName[i].value;
                ++j;
            }
        }

        if (lengthArr.length == 0) {
            if (str != "computePrice") {
                $.message("warn", "请填写规格后再提交！");
            }
            return;
        }

        var specificationArr = [{
            "quantity": 0,
            "sides": [{
                "sideType": "LENGTH",
                "value": 0
            },
                {
                    "sideType": "DIAMETER",
                    "value": 0
                }]
        }]
        for (var i= 0; i < lengthArr.length; i++) {
            var sideArr = [{
                "sideType": "LENGTH",
                "value": lengthArr[i]
            },
                {
                    "sideType": "DIAMETER",
                    "value": diameterArr[i][0]
                }];
            specificationArr[i] = {"quantity": quantityArr[i], "sides": sideArr}
        }
        return specificationArr;
    }

    function standardSpecification(str) {
        var specificationArr = [{
            "quantity": 0,
            "sides": [{
                "sideType": "LENGTH",
                "value": 0
            },
                {
                    "sideType": "THICKNESS",
                    "value": 0
                },
                {
                    "sideType": "WIDTH",
                    "value": 0
                }]
        }]

        var thickArr = document.getElementsByName("proThickness");
        var widthArr = document.getElementsByName("proWidth");
        var lengthArr = document.getElementsByName("proLength");
        var quantityArrByName = document.getElementsByName("quantity");
        var index = [];
        for (var i = 0, j = 0; i < thickArr.length; i ++) {
            if (thickArr[i].value !== "" && thickArr[i].value != null && widthArr[i].value !== "" && widthArr[i].value != null
                && lengthArr[i].value !== "" && lengthArr[i].value != null && quantityArrByName[i].value !== "" && quantityArrByName[i].value != null) {
                index[j] = i;
                ++ j;
            }

        }
        for (var i = 0; i < index.length; i ++) {
            var j = index[i];
            var quantity = quantityArrByName[j].value;
            var sideArr = [{
                "sideType": "LENGTH",
                "value": lengthArr[j].value
            },
                {
                    "sideType": "THICKNESS",
                    "value": thickArr[j].value
                },
                {
                    "sideType": "WIDTH",
                    "value": widthArr[j].value
                }];
            specificationArr[i] = {"quantity": quantity, "sides": sideArr}
        }
        if (specificationArr.length === 1 && specificationArr[0].sides[0].value === 0
            && specificationArr[0].sides[1].value === 0 && specificationArr[0].sides[2].value === 0) {
            if (str != "computePrice") {
                $.message("warn", "请填写规格后再提交！");
            }
            return;
        }
        return specificationArr;
    }

    function diameterOrThicknessSelector() {
        var diameterArr = new Array();
        if ($(".detail-div").attr('id') == "SQUARE" && $("#processLevel").val() != "0") {
            var lenArr = $("#lengthSemi").val().split(",");
            var widArr = $("#widthSemi").val().split(",");
            $("input[name='proLength']").attr("placeholder", "范围" + lenArr[0] + "到" + lenArr[1]);
            $("input[name='proWidth']").attr("placeholder", "范围" + widArr[0] + "到" + widArr[1]);
            diameterArr = $("#thicknessSelection").val().split(",");
            $(".diameter-or-thickness-selection").empty();
            $(".diameter-or-thickness-selection").append("<option value=''>" + "</option>");
        } else if ($(".detail-div").attr('id') == "CYLINDER") {
            var lenArr = $("#lengthCylinder").val().split(",");
            $("input[name='proLength']").attr("placeholder", "范围" + lenArr[0] + "到" + lenArr[1]);
            $(".diameter-or-thickness-selection").empty();
            $(".diameter-or-thickness-selection").append("<option value=''>" + "</option>");
            diameterArr = $("#diameterSelection").val().split(",");
        }
        for (var i = 0; i < diameterArr.length; i ++) {
            $(".diameter-or-thickness-selection").append("<option value=" + diameterArr[i] + "mm" + ">" + diameterArr[i] + "mm" + "</option>");
        }
    }

    $("input[name='proWidth']").off('change').on('change', function() {
        computePrice();
    });
    $("input[name='proLength']").off('change').on('change', function() {
        computePrice();
    });
    $("input[name='proThickness']").off('change').on('change', function() {
        computePrice();
    });
    $("input[name='proDiameter']").off('change').on('change', function() {
        computePrice();
    });
    $("input[name='proThicknessSEMI']").off('change').on('change', function() {
        computePrice();
    });
    $(".diameter-or-thickness-selection").off('change').on('change', function() {
        computePrice();
    });
    $("#processway").off('change').on('change', function() {
        computePrice();
    });
    $("input[name='quantity']").off('change').on('change', function() {
        computePrice();
    });

    $(".detail-table-add-line-member").on('click',function(){
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var jlHtml = "\n " +
                "<td>\n" +
                "<input type=\"text\" placeholder=\"请输入\" name=\"proLength\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n " +
                "</td>\n" +
                "<td>\n" +
                "<input type=\"text\" placeholder=\"请输入\" name=\"proWidth\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n" +
                "</td>\n" +
                "<td class=\"progress-type-not-semi\">\n  " +
                "<input type=\"text\" placeholder=\"请输入\" name=\"proThickness\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n " +
                "</td>\n" +
                "<td class=\"progress-type-semi\" style=\"display: none;\">\n" +
                "<select class=\"diameter-or-thickness-selection\" name=\"proThicknessSEMI\" style=\"width: 150px\">\n" +
                "<option value=\"\"></option>\n" +
                "</select>\n" +
                "</td>\n" +
                "<td class=\"td-color-normal\"><input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\"></td>\n" +
                "<td><button class=\"deleteButton\" style=\"background-color: transparent;\" onclick=\"removeRow(this)\">删除</button></td>\n"
            newTr.append(jlHtml);
            $("#addDetailTable3").append(newTr);
        }
        if ($("#processLevel").val() == "0") {
            $("input[name='proLength']").attr("placeholder", "请先选择加工类型");
            $("input[name='proWidth']").attr("placeholder", "请先选择加工类型");
            $("input[name='proThickness']").attr("placeholder", "请先选择加工类型");
            $("input[name='proWidth']").attr("readonly", true);
            $("input[name='proLength']").attr("readonly", true);
            $("input[name='proThickness']").attr("readonly", true);
        } else {
            $("input[name='proLength']").attr("placeholder", "请输入");
            $("input[name='proWidth']").attr("placeholder", "请输入");
            $("input[name='proThickness']").attr("placeholder", "请输入");
            $("input[name='proWidth']").attr("readonly", false);
            $("input[name='proLength']").attr("readonly", false);
            $("input[name='proThickness']").attr("readonly", false);
        }
        $("input[name='proWidth']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })

    $(".detail-table-add-line-jl").on('click',function(){
        var lenLeft = $("#lengthConcentrate").val().split(",")[0];
        var lenRight = $("#lengthConcentrate").val().split(",")[1];
        var widthLeft = $("#widthConcentrate").val().split(",")[0];
        var widthRight = $("#widthConcentrate").val().split(",")[1];
        var thickLeft = $("#thicknessConcentrate").val().split(",")[0];
        var thickRight = $("#thicknessConcentrate").val().split(",")[1];
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var jlHtml = "\n                            " +
                "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+lenLeft+"到"+lenRight+"' name=\"proLength\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "</td>\n                            " +
                "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+widthLeft+"到"+widthRight+"' name=\"proWidth\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td class=\"progress-type-not-semi\">\n                                " +
                "<input type=\"text\" placeholder='范围"+thickLeft+"到"+thickRight+"' name=\"proThickness\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td class=\"progress-type-semi\" style=\"display: none;\">\n                                " +
                "<select class=\"diameter-or-thickness-selection\" name=\"proThicknessSEMI\" style=\"width: 150px\">\n                                    " +
                "<option value=\"\"></option>\n                                " +
                "</select>\n                            " +
                "<td class=\"td-color-normal\"><input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\"></td>\n                            " +
                "<td><button class=\"deleteButton\" style=\"background-color: transparent;\" onclick=\"removeRow(this)\">删除</button></td>\n                        "
            newTr.append(jlHtml);
            $("#addDetailTable3").append(newTr);
        }
        $("input[name='proWidth']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })
    $(".detail-table-add-line-gb").on('click',function(){
        var lenLeft = $("#lengthRough").val().split(",")[0];
        var lenRight = $("#lengthRough").val().split(",")[1];
        var widthLeft = $("#widthRough").val().split(",")[0];
        var widthRight = $("#widthRough").val().split(",")[1];
        var thickLeft = $("#thicknessRough").val().split(",")[0];
        var thickRight = $("#thicknessRough").val().split(",")[1];
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var gbHtml = "\n                            " +
                "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+lenLeft+"到"+lenRight+"' name=\"proLength\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+widthLeft+"到"+widthRight+"' name=\"proWidth\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td class=\"progress-type-not-semi\">\n                                " +
                "<input type=\"text\" placeholder='范围"+thickLeft+"到"+thickRight+"' name=\"proThickness\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td class=\"progress-type-semi\" style=\"display: none;\">\n                                " +
                "<select class=\"diameter-or-thickness-selection\" name=\"proThicknessSEMI\" style=\"width: 150px\">\n                                    " +
                "<option value=\"\"></option>\n                                " +
                "</select>\n                            " +
                "</td>\n                            " +
                "<td class=\"td-color-normal\"><input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\"></td>\n                            " +
                "<td><button class=\"deleteButton\" style=\"background-color: transparent;\" onclick=\"removeRow(this)\">删除</button></td>\n                        "
            newTr.append(gbHtml);
            $("#addDetailTable3").append(newTr);
        }
        $("input[name='proWidth']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $(".diameter-or-thickness-selection").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })
    $(".detail-table-add-line-ml").on('click',function(){
        var lenLeft = $("#lengthSemi").val().split(",")[0];
        var lenRight = $("#lengthSemi").val().split(",")[1];
        var widthLeft = $("#widthSemi").val().split(",")[0];
        var widthRight = $("#widthSemi").val().split(",")[1];
        var thickArr = $("#thicknessSelection").val().split(",");
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var optionHtml =
                "<td class=\"progress-type-not-semi\" style=\"display: none\">" +
                "<input type=\"text\" placeholder='' name=\"proThickness\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">" +
                "</td>" +
                "<td class=\"progress-type-semi\" style=\"\"><select class=\"diameter-or-thickness-selection\" name=\"proThicknessSEMI\" style=\"width: 150px\"><option value=\"\"></option>";
            for (var j = 0; j < thickArr.length; j ++) {
                var thick = thickArr[j];
                optionHtml = optionHtml + "<option value='"+thick+"mm'>"+thick+"mm</option>";
            }
            optionHtml += "</select></td> ";
            var mlHtml3 = "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+lenLeft+"到"+lenRight+"' name=\"proLength\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " +
                "<td>\n                                " +
                "<input type=\"text\" placeholder='范围"+widthLeft+"到"+widthRight+"' name=\"proWidth\" onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            ";
            var mlHtml4 ="<td class=\"td-color-normal\"><input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\"></td>\n                            " +
                "<td><button class=\"deleteButton\" style=\"background-color: transparent;\" onclick=\"removeRow(this)\">删除</button></td>\n                        "
            newTr.append(mlHtml3);
            newTr.append(optionHtml);
            newTr.append(mlHtml4);
            $("#addDetailTable3").append(newTr);
        }
        $("input[name='proWidth']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $(".diameter-or-thickness-selection").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })
    $(".detail-table-add-show-cylinder").on('click',function(){
        var lenLeft = $("#lengthCylinder").val().split(",")[0];
        var lenRight = $("#lengthCylinder").val().split(",")[1];
        var diameterSelectionArr = $("#diameterSelection").val().split(",");
        for(var i =0;i<3;i++){
            var newTr = $('<tr class="cylinder-content"></tr>');
            var cylinderHtml = "\n                            <td>\n                                " +
                "<input type=\"text\" name=\"proLength\" placeholder='范围"+lenLeft+"到"+lenRight+"' onkeyup=\"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')\">\n                            " +
                "</td>\n                            " ;
            var selectHtml = "<td>\n                                " +
                "<select class=\"diameter-or-thickness-selection\" name=\"proDiameter\" style=\"width: 150px\"><option value=''></option>";
            for (var j = 0; j < diameterSelectionArr.length; j ++) {
                selectHtml += "<option value='"+diameterSelectionArr[j]+"'>"+diameterSelectionArr[j]+"mm</option>"
            }
            selectHtml += "</select></td>" ;
            var cylinderHtml2 = "<td>\n                                " +
                "<input type=\"text\" name=\"quantity\" value=\"1\" maxlength=\"4\" oninput=\"value=value.replace( /[^0-9]/g,'')\" onpaste=\"return false;\">\n                            " +
                "</td>\n                            " +
                "<td><button class=\"deleteButton\" onclick=\"removeRow(this)\" style=\"width: 50px;background-color: transparent;\">删除</button></td>\n                        "
            newTr.append(cylinderHtml);
            newTr.append(selectHtml);
            newTr.append(cylinderHtml2);
            $("#cylinderTable").append(newTr);
        }
        $("input[name='proWidth']").off('change').on('change', function() {console
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $(".diameter-or-thickness-selection").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })
    $(".detail-table-add-line-spot-goods").on('click',function(){
        var standardLength = $("#standardLength").val().replace("[", "").replace("]", "").split(", ");
        var standardWidth = $("#standardWidth").val().replace("[", "").replace("]", "").split(", ");
        var thicknessArrs = $("#standardThickness").val().replace("[", "").replace("]", "").split(", ");

        for(var i =0;i<3;i++){
            var newTr = $('<tr class="spec-content"></tr>');
            var spotGoodsHtml2 = "<td><select class=\"standard-selection-thick\" name=\"proThickness\" style=\"width: 150px\" onchange=\"thickChange(this)\"><option value=\"\"></option>" ;
            for (var j = 0; j < thicknessArrs.length; j ++) {
                spotGoodsHtml2 += "<option value='"+thicknessArrs[j]+"'>"+thicknessArrs[j]+"</option>";
            }
            spotGoodsHtml2 += "</select></td>";
            var spotGoodsHtml3 = "<td><select class=\"standard-selection-width\" name=\"proWidth\" style=\"width: 150px\" onchange=\"widthChange(this)\"><option value=\"\"></option>" ;
            for (var j = 0; j < standardWidth.length; j ++) {
                spotGoodsHtml3 += "<option value='"+standardWidth[j]+"'>"+standardWidth[j]+"</option>";
            }
            spotGoodsHtml3 += "</select></td>";
            var spotGoodsHtml4 = "<td><select class=\"standard-selection-length\" name=\"proLength\" style=\"width: 150px\" onchange=\"lengthChange(this)\"><option value=\"\"></option>" ;
            for (var j = 0; j < standardLength.length; j ++) {
                spotGoodsHtml4 += "<option value='"+standardLength[j]+"'>"+standardLength[j]+"</option>";
            }
            spotGoodsHtml4 += "</select></td>";

            var spotGoodsHtml5 = "<td class=\"td-color-normal\">" +
                "<input type=\"text\" name=\"quantity\" value=\"1\" oninput=\"value=value.replace(/[^0-9]/g,'')\" style=\"width: 100px\">" +
                "</td>\n                            " +
                "<td><button class=\"deleteButton\" onclick=\"removeRow(this)\" style=\"width: 50px;background-color: transparent;\">删除</button></td>\n                        "
            newTr.append(spotGoodsHtml4);
            newTr.append(spotGoodsHtml3);
            newTr.append(spotGoodsHtml2);
            newTr.append(spotGoodsHtml5);
            $("#addDetailTable3").append(newTr);
        }
        $("input[name='proWidth']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proLength']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThickness']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proDiameter']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='proThicknessSEMI']").off('change').on('change', function() {
            computePrice();
        });
        $("input[name='quantity']").off('change').on('change', function() {
            computePrice();
        });
    })

    $("#brandSelector").change(function () {
        var commodityId = $("#brandSelector option:selected").val();
        location.href = "/mall/commodities/show/" + commodityId;
    });
}(jQuery)
function isEmpty(value) {
    if (value == null || value == "" || value == "undefined" || value == undefined || value == "null") {
        return true;
    } else {
        /*value = value.toString().replace(/\s/g,"");
        if(value == ""){
            return true;
        }*/
        return false;
    }
}

// 货币格式化
function currency(value, showSign, showUnit) {
    if (value != null) {
        var price = (Math.floor(value * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2);
        if (showSign) {
            price = '￥' + price;
        }
        if (showUnit) {
            price += '元';
        }
        return price;
    }
}

$(".div-cylinder").on('click', function (e) {
    // getNonStandardPrice();
})
//显示商品的计算价格
function getNonStandardPrice() {
    var width = $("#proWidth").val();
    var length = $("#proLength").val();
    var thick = $("#thickness_input").val();
    if (isEmpty(thick)) {
        thick = $("#thickness_select").val();
        if (!isEmpty(width) && parseInt(thick) > parseInt(width)) {
            $("#proWidth").before(errrorTemplate('厚度不能大于宽度'));
            setTimeout(function () {
                $('#error-prompt').fadeOut()
                $('#error-prompt').remove()
            }, 3000)
            return;
        }
    }

    var quantity = $("#quantity").val();
    var processLevel = $("#processLevel").val();
    var diameter = $("#diameter").val();
    var processway = $("#processway").val();

    var creditvalue = $("#creditvalue").val()
    if (!isEmpty(diameter)) {
        if (diameter <= 100) {
            $("#tolerance").val("(0/+3)mm");
        } else if (diameter > 100 && diameter <= 300) {
            $("#tolerance").val("(0/+5)mm");
        } else {
            $("#tolerance").val("(0/+10)mm");
        }
    }

    var goodsid = $(".detail-content").attr('id');
    var goodsshape = $(".detail-div").attr('id');

    var data = {
        "commodityId": goodsid,
        "credit": creditvalue,
        "modelShape": goodsshape,
        "processType": processLevel,
        "manner": $("#processway option:selected").text(),
        "additionalProcessing": processway,
        "specifications": [
            {
                "id": null,
                "quantity": quantity,
                "weight": null,
                "processingCharges": null,
                "materialCharges": null,
                "sides": [
                    {
                        "id": null,
                        "sideType": "LENGTH",
                        "value": length
                    },
                    {
                        "id": null,
                        "sideType": "THICKNESS",
                        "value": thick
                    },
                    {
                        "id": null,
                        "sideType": "WIDTH",
                        "value": width
                    }
                ],
                "commodity": null
            }
        ]
    };

    if (goodsshape == "CYLINDER") {
        var data = {
            "commodityId": goodsid,
            "credit": creditvalue,
            "modelShape": goodsshape,
            "processType": "SEMI",
            "manner": $("#processway option:selected").text(),
            "additionalProcessing": processway,
            "specifications": [
                {
                    "id": null,
                    "quantity": quantity,
                    "weight": null,
                    "processingCharges": null,
                    "materialCharges": null,
                    "sides": [
                        {
                            "id": null,
                            "sideType": "LENGTH",
                            "value": length
                        },
                        {
                            "id": null,
                            "sideType": "DIAMETER",
                            "value": diameter
                        }
                    ],
                    "commodity": null
                }
            ]
        };
    }
    if ((!isEmpty(processLevel) && !isEmpty(width) && !isEmpty(length) && !isEmpty(thick) && !isEmpty(quantity)) || (!isEmpty(length) && !isEmpty(diameter))) {
        $.ajax({
            url: "/api/price",
            type: "post",
            async: false,
            contentType: "application/json",
            data: JSON.stringify(data),
            dataTyoe: "json",
            cache: false,
            success: function (data) {
                if (!isEmpty(data)) {
                    if (data.processPrice == 0)
                        $("#processPrice").html(0 + " 元");
                    else
                        $("#processPrice").html(data.specifications[0].processingCharges + "	元");
                    $("#unitWeight").html(data.specifications[0].weight + " kg");
                    if(data.credit)
                        $("#singlePrice").html(currency((data.specifications[0].processingCharges + data.specifications[0].interest) * data.specifications[0].quantity, true) + " ");
                    else
                        $("#singlePrice").html(currency((data.specifications[0].processingCharges + data.specifications[0].materialCharges) * data.specifications[0].quantity, true) + " ");
                    is_temp = true;
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                var title = JSON.parse(XMLHttpRequest.responseText).title;
                if (title === "Unauthorized") {
                    location.href = "/mall/login"
                } else {
                    alert("错误信息: " + title); //获取的信息即是异常中的Message

                }
            }
        });
    }
    return true;
}

function fillselection() {
    if (!isEmpty($("#SEMI").children("#DIAMETER").val())) {
        var lengthValue = $("#SEMI").children("#LENGTH").val();
        $("#proLength").attr('placeholder', "范围" + lengthValue.split(",")[1] + "到" + lengthValue.split(",")[2]);
        var thicknessArrayValue = $("#SEMI").children("#DIAMETER").val().split(",").splice(1);
        $.each(thicknessArrayValue, function (i, n) {
            $("#diameter").append("<option value=" + n + ">" + n + "</option>");
        });
    }
}

// 消息框
var $message;
var messageTimer;
$.message = function() {
    var message = {};
    if ($.isPlainObject(arguments[0])) {
        message = arguments[0];
    } else if (typeof arguments[0] === "string" && typeof arguments[1] === "string") {
        message.type = arguments[0];
        message.content = arguments[1];
    } else {
        return false;
    }

    if (message.type == null || message.content == null) {
        return false;
    }

    if ($message == null) {
        var messageString = message.type != 'success' ? '<div class="xxMessage" ><i class="iconfont icon-warning" style="float:left;"></i><div class="messageContent message' + escapeHtml(message.type) + 'Icon" STYLE="width : 120px;"><\/div><\/div>' : '<div class="xxMessage" ><div class="messageContent message' + escapeHtml(message.type) + 'Icon" STYLE="width : 120px;"><\/div><\/div>'
        $message = $(messageString);
        if (!window.XMLHttpRequest) {
            $message.append('<iframe class="messageIframe"><\/iframe>');
        }
        $message.appendTo("body");
    }

    $message.children("div").removeClass("messagewarnIcon messageerrorIcon messagesuccessIcon").addClass("message" + message.type + "Icon").html(message.content);
    $message.css({"margin-left": - parseInt($message.outerWidth() / 2), "z-index": 9999}).show();

    clearTimeout(messageTimer);
    messageTimer = setTimeout(function() {
        $message.hide();
    }, 3000);
    return $message;
}


function escapeHtml(str) {
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

function thickChange(e) {
    var hrefStrId = (location.href.split("/").reverse())[0];
    var thick = $(e).val();
    var widthSet = new Set();
    var lengthSet = new Set();
    var thickSet = new Set();
    var parent = $(e).parent();
    var thickSelect = $(e);
    var widthSelect = parent.prev().children();
    var lengthSelect = parent.prev().prev().children();
    $.ajax({
        url: "/api/commodities/standard/" + hrefStrId,
        type: "get",
        contentType: "application/json",
        success: function (commodityPriceList) {
            // 长宽下拉框未选中值
            if (widthSelect.val() === "" && lengthSelect.val() === "") {
                if (thick === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        thickArr.push.apply(thickArr,item.thickness.split(","));
                        widthSet.add(item.edgeWidth);
                        lengthSet.add(item.edgeLength);
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }

                } else {
                    $.each(commodityPriceList, function (i, item) {
                        if(item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i ++) {
                        lengthSelect.append("<option th:value='"+lengthArr[i]+"'>"+lengthArr[i]+"</option>");
                    }
                }
            }

            if (widthSelect.val() === "" && lengthSelect.val() !== "") {
                var len = lengthSelect.val();
                if (thick === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        lengthSet.add(item.edgeLength);
                        if(item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    lengthSelect.val(len);
                } else {
                    var thickArr = [];
                    var length = lengthSelect.val();
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeLength === length) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.thickness.split(",").includes(thick)) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === length && item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    lengthSelect.val(length);
                }
            }

            if (widthSelect.val() !== "" && lengthSelect.val() === "") {
                var width = widthSelect.val();
                if (thick === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        widthSet.add(item.edgeWidth);
                        if(item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    widthSelect.val(width);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeWidth === widthSelect.val()) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.edgeWidth === widthSelect.val() && item.thickness.split(",").includes(thick)) {
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    widthSelect.val(width);
                }
            }

            if (widthSelect.val() !== "" && lengthSelect.val() !== "") {
                var width = widthSelect.val();
                var len = lengthSelect.val();
                if (thick === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.edgeWidth === width && item.edgeLength === len) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    });
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    widthSelect.val(width);
                    lengthSelect.val(len);
                    thickSelect.val(thick);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeWidth === width && item.edgeLength === len) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.edgeWidth === width && item.thickness.split(",").includes(thick)) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.thickness.split(",").includes(thick) && item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                        }
                    });
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    widthSelect.val(width);
                    lengthSelect.val(len);
                    thickSelect.val(thick);
                }

            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            var title = JSON.parse(XMLHttpRequest.responseText).title;
            if (title === "Unauthorized") {
                location.href = "/mall/login"
            } else {
                alert("错误信息: " + title); //获取的信息即是异常中的Message

            }
        }
    })
}

function widthChange(e) {
    var hrefStrId = (location.href.split("/").reverse())[0];
    var width = $(e).val();
    var thickSet = new Set();
    var widthSet = new Set();
    var lengthSet = new Set();
    var parent = $(e).parent();
    var thickSelect = parent.next().children();
    var lengthSelect = parent.prev().children();
    var widthSelect = $(e);
    $.ajax({
        url: "/api/commodities/standard/" + hrefStrId,
        type: "get",
        contentType: "application/json",
        success: function (commodityPriceList) {
            // 长宽下拉框未选中值
            if (thickSelect.val() === "" && lengthSelect.val() === "") {
                if (width === "") {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        thickArr.push.apply(thickArr, item.thickness.split(","));
                        widthSet.add(item.edgeWidth);
                        lengthSet.add(item.edgeLength);
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                }
            }

            if (thickSelect.val() === "" && lengthSelect.val() !== "") {
                var len = lengthSelect.val();
                if (width === "") {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        lengthSet.add(item.edgeLength);
                        if (item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    lengthSelect.val(len);
                } else {
                    var thickArr = [];
                    var length = lengthSelect.val();
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeLength === length) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === length && item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    widthSelect.val(width);
                    lengthSelect.val(length);
                }
            }

            if (thickSelect.val() !== "" && lengthSelect.val() === "") {
                var thick = thickSelect.val();
                if (width === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        thickArr.push.apply(thickArr, item.thickness.split(","));
                        if (item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                            lengthSet.add(item.edgeLength);
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.thickness.split(",").includes(thickSelect.val())) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.thickness.split(",").includes(thickSelect.val()) && item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    widthSelect.val(width);
                }
            }
            if (thickSelect.val() !== "" && lengthSelect.val() !== "") {
                var thick = thickSelect.val();
                var len = lengthSelect.val();
                if (width === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.thickness.split(",").includes(thick)) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === len) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.thickness.split(",").includes(thick) && item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    lengthSelect.val(len);
                    widthSelect.val(width);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.thickness.split(",").includes(thick) && item.edgeLength === len) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.thickness.split(",").includes(thick) && item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeWidth === width && item.edgeLength === len) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    lengthSelect.val(len);
                    widthSelect.val(width);
                }

            }

        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            var title = JSON.parse(XMLHttpRequest.responseText).title;
            if (title === "Unauthorized") {
                location.href = "/mall/login"
            } else {
                alert("错误信息: " + title); //获取的信息即是异常中的Message

            }
        }
    })
}

function lengthChange(e) {
    var hrefStrId = (location.href.split("/").reverse())[0];
    var length = $(e).val();
    var thickSet = new Set();
    var widthSet = new Set();
    var lengthSet = new Set();
    var parent = $(e).parent();
    var thickSelect = parent.next().next().children();
    var widthSelect = parent.next().children();
    var lengthSelect = $(e);
    $.ajax({
        url: "/api/commodities/standard/" + hrefStrId,
        type: "get",
        contentType: "application/json",
        success: function (commodityPriceList) {
            // 宽厚下拉框未选中值
            if (thickSelect.val() === "" && widthSelect.val() === "") {
                if (length === "") {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        thickArr.push.apply(thickArr,item.thickness.split(","));
                        widthSet.add(item.edgeWidth);
                        lengthSet.add(item.edgeLength);
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i ++) {
                        thickSelect.append("<option th:value='"+thickArr[i]+"'>"+thickArr[i]+"</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i ++) {
                        lengthSelect.append("<option th:value='"+lengthArr[i]+"'>"+lengthArr[i]+"</option>");
                    }
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if(item.edgeLength === length) {
                            thickArr.push.apply(thickArr,item.thickness.split(","));
                            widthSet.add(item.edgeWidth);
                        }
                    });
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i ++) {
                        thickSelect.append("<option th:value='"+thickArr[i]+"'>"+thickArr[i]+"</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                }
            }

            if (thickSelect.val() === "" && widthSelect.val() !== "") {
                var width = widthSelect.val();
                if (length === "") {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        widthSet.add(item.edgeWidth);
                        if(item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                            lengthSet.add(item.edgeLength);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    widthSelect.val(width);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === length) {
                            widthSet.add(item.edgeWidth);
                        }
                        if (item.edgeLength === length && item.edgeWidth === width) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    lengthSelect.val(length);
                    widthSelect.val(width);
                }
            }

            if (thickSelect.val() !== "" && widthSelect.val() === "") {
                var thick = thickSelect.val();
                if (length === "") {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        thickArr.push.apply(thickArr,item.thickness.split(","));
                        if(item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                            lengthSet.add(item.edgeLength);
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i ++) {
                        thickSelect.append("<option th:value='"+thickArr[i]+"'>"+thickArr[i]+"</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i ++) {
                        lengthSelect.append("<option th:value='"+lengthArr[i]+"'>"+lengthArr[i]+"</option>");
                    }
                    thickSelect.val(thick);
                } else {
                    var thickArr = [];
                    $.each(commodityPriceList, function (i, item) {
                        if (item.thickness.split(",").includes(thickSelect.val())) {
                            lengthSet.add(item.edgeLength);
                        }
                        if (item.edgeLength === length) {
                            thickArr.push.apply(thickArr, item.thickness.split(","));
                        }
                        if (item.thickness.split(",").includes(thickSelect.val()) && item.edgeLength === length) {
                            widthSet.add(item.edgeWidth);
                        }
                    });
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    widthSet = new Set(widthSet);
                    var widthArr = Array.from(widthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i++) {
                        widthSelect.append("<option th:value='" + widthArr[i] + "'>" + widthArr[i] + "</option>");
                    }
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    lengthSet = new Set(lengthSet);
                    var lengthArr = Array.from(lengthSet).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i++) {
                        lengthSelect.append("<option th:value='" + lengthArr[i] + "'>" + lengthArr[i] + "</option>");
                    }
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i++) {
                        thickSelect.append("<option th:value='" + thickArr[i] + "'>" + thickArr[i] + "</option>");
                    }
                    thickSelect.val(thick);
                    lengthSelect.val(length);
                }
            }

            if (thickSelect.val() !== "" && widthSelect.val() !== "") {
                var thick = thickSelect.val();
                var width = widthSelect.val();

                if (length === "") {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        if(item.thickness.split(",").includes(thick)) {
                            widthSet.add(item.edgeWidth);
                        }
                        if(item.edgeWidth === width) {
                            thickArr.push.apply(thickArr,item.thickness.split(","));
                        }
                        if(item.thickness.split(",").includes(thick) && item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i ++) {
                        thickSelect.append("<option th:value='"+thickArr[i]+"'>"+thickArr[i]+"</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i ++) {
                        lengthSelect.append("<option th:value='"+lengthArr[i]+"'>"+lengthArr[i]+"</option>");
                    }
                    thickSelect.val(thick);
                    widthSelect.val(width);
                    lengthSelect.val(length);
                } else {
                    var thickArr = []
                    $.each(commodityPriceList, function (i, item) {
                        if(item.thickness.split(",").includes(thick) && item.edgeWidth === width) {
                            lengthSet.add(item.edgeLength);
                        }
                        if(item.thickness.split(",").includes(thick) && item.edgeLength === length) {
                            widthSet.add(item.edgeWidth);
                        }
                        if(item.edgeLength === length && item.edgeWidth === width) {
                            thickArr.push.apply(thickArr,item.thickness.split(","));
                        }
                    })
                    thickSelect.children().remove();
                    thickSelect.append("<option value=''></option>");
                    widthSelect.children().remove();
                    widthSelect.append("<option value=''></option>");
                    lengthSelect.children().remove();
                    lengthSelect.append("<option value=''></option>");
                    thickArr = Array.from(new Set(thickArr)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < thickArr.length; i ++) {
                        thickSelect.append("<option th:value='"+thickArr[i]+"'>"+thickArr[i]+"</option>");
                    }
                    var widthArr = Array.from(new Set(widthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < widthArr.length; i ++) {
                        widthSelect.append("<option th:value='"+widthArr[i]+"'>"+widthArr[i]+"</option>");
                    }
                    var lengthArr = Array.from(new Set(lengthSet)).map(Number).sort(function(a, b) {return a - b});
                    for (var i = 0; i < lengthArr.length; i ++) {
                        lengthSelect.append("<option th:value='"+lengthArr[i]+"'>"+lengthArr[i]+"</option>");
                    }
                    thickSelect.val(thick);
                    widthSelect.val(width);
                    lengthSelect.val(length);
                }

            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            var title = JSON.parse(XMLHttpRequest.responseText).title;
            if (title === "Unauthorized") {
                location.href = "/mall/login"
            } else {
                alert("错误信息: " + title); //获取的信息即是异常中的Message

            }
        }
    })
}


