+function () {
    'use strict';

    $(".announcement-del").on('click', function (e) {
        var id = $(this).data("id");
        $("#delAnnouncement").attr("action", "/admin/announcements/"+id);
        $("#delAnnouncementModal").modal("show");
    })

    $("#notice-page").on('click', function (e) {
        search()
    });

    $("#notice-page-size-selector").on('change', function (e) {
        search()
    });

    function search(page) {
        var params = getParams();
        var size_sort = formatParam(params)+'&sort=createdTime,desc';
        // var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        window.location.href =  '/mall/helpCenter/announcement?' + size_sort;
    }

    function getParams(defaultPage) {
        var page = defaultPage ? defaultPage : Number($("#notice-jump").val());
        var size = $("#notice-page-size-selector").val();
        var total = $(".notice-page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }

    }

    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }

    $(".notice-info-btn").on('click',function (e) {
        window.location.href =  '/mall/helpCenter/announcement?id=' + this.id;
    })

    $(document).ready(function(){
        var i = $("#notice-page-size-selector").val();
        for(i = i - 1;i>=0;i--) {
            var date = $("#" + "new-show" + i).attr("value");
            var m1 = moment().format("YYYY-MM-DD");
            var m2 = moment(date);
            var day = m2.diff(m1, 'day');
            if(day > -3){
                $("#new-show" + i).show();
            }

        }
        //公告详情页渲染
        var date = $(".announcement-date-show").text();
        var m1 = moment().format("YYYY-MM-DD");
        var m2 = moment(date);
        var day = m2.diff(m1, 'day');
        if(day > -3){
            $(".announcement-new-show").show();
        }
    })

}(jQuery);
