+function ($) {
    'use strict';
    $.ajax({
        url: '/api/invoice-statistics',
        type: 'get',
        success: function (res) {
            if (!res) {
                $(".invoice-total-invoiced").text(0)
                $("#sub-btn-invoiceApply").attr("disabled", true);
            } else {
                $(".invoice-total-invoiced").text((res.total * 1 - res.invoiced * 1).toFixed(2))
                if (res.total * 1 - res.invoiced * 1 < 1000) {
                    $("#sub-btn-invoiceApply").attr("disabled", true);
                }
            }

        }
    });

    $.ajax({
        url: '/api/invoice-records/last-time',
        type: 'get',
        success: function (res) {
            if (res != null) {
                if (res.companyName != null) {
                    $("#invoiced-companyName").val(res.companyName)
                    $("#invoiced-taxNumber").val(res.taxNumber)
                    $("#invoiced-address").val(res.address)
                    $("#invoiced-phone").val(res.phone)
                    $("#invoiced-bankName").val(res.bankName)
                    $("#invoiced-bankAccount").val(res.bankAccount)
                    $("#invoiced-name").val(res.name)
                    $("#invoiced-contactNumber").val(res.contactNumber)
                    $("#invoiced-receiptAddress").val(res.receiptAddress)
                    $("#invoiced-receiptEmail").val(res.receiptEmail)
                }
            }
        }
    });

    $("#invoice-money").bind('input propertychange', function () {
        if ($("#invoice-money").val() * 1 >= 1000) {
            $("#sub-btn-invoiceApply").removeAttr("disabled");
            $('#sub-btn-invoiceApply').css('cursor', 'pointer')
        } else {
            $("#sub-btn-invoiceApply").attr("disabled", true);
            $('#sub-btn-invoiceApply').css('cursor', 'not-allowed')
        }
    })

    // 使用我的收货地址
    $(".use-address-button").on('click', function () {
        $('#delivery-address-modal').fadeIn()
    })

    $(".address-detail").on('click', function () {
        var name = $(this).children('.name').text();
        var division = $(this).children('.division').text();
        var addressDetail = $(this).children('.addressDetail').text();
        var phone = $(this).children('.phone').text();
        $("#invoiced-name").val(name)
        $("#invoiced-contactNumber").val(phone)
        $("#invoiced-receiptAddress").val(division + addressDetail)
        $('.use-delivery-address-modal').fadeOut()
    })

    $("#delivery-address-modal-close-button").on('click', function () {
        $('.use-delivery-address-modal').fadeOut()
    })

    $("#sub-btn-invoiceApply").on('click', function () {
        var companyName = $("#invoiced-companyName").val();
        var taxNumber = $("#invoiced-taxNumber").val();
        var address = $("#invoiced-address").val();
        var phone = $("#invoiced-phone").val();
        var bankName = $("#invoiced-bankName").val();
        var bankAccount = $("#invoiced-bankAccount").val();
        var name = $("#invoiced-name").val();
        var contactNumber = $("#invoiced-contactNumber").val();
        var receiptAddress = $("#invoiced-receiptAddress").val();
        var receiptEmail = $("#invoiced-receiptEmail").val();
        var money = $("#invoice-money").val() * 1;
        var maxMoney = $("#maxMoney").html();
        var isSend = true;

        // if(address==null||address.replace(/\s*/g,"")==""){
        //     $("#invoiced-address-message").show();
        //     $("#invoiced-address-message").html("请填写地址");
        //     isSend = false;
        // } else if(address.length > 100){
        //     $("#invoiced-address-message").show();
        //     $("#invoiced-address-message").html("地址最多填写100个字");
        //     isSend = false;
        // }else {
        //     $("#invoiced-address-message").hide();
        // }

        // if(name==null||name.replace(/\s*/g,"")==""){
        //     $("#invoiced-name-message").show();
        //     $("#invoiced-name-message").html("请填写公司名称");
        //     isSend = false;
        // } else if(name.length > 50){
        //     $("#invoiced-name-message").show();
        //     $("#invoiced-name-message").html("公司名称最多50个字");
        //     isSend = false;
        // }else {
        //     $("#invoiced-name-message").hide();
        // }

        // if(phone==null||phone.replace(/\s*/g,"")==""){
        //     $("#invoiced-phone-message").show()
        //     $("#invoiced-phone-message").html("请填写联系电话")
        //     isSend = false;
        // }else if (!(/^\d+(\-\d+)?$/.test(phone))) {
        //     $("#invoiced-phone-message").show();
        //     $("#invoiced-phone-message").html("号码有误，请重填");
        //     isSend = false;
        // } else {
        //     $("#invoiced-phone-message").hide();
        // }
        //
        // if(taxNumber==null||taxNumber.replace(/\s*/g,"")==""){
        //     $("#invoiced-taxNumber-message").show();
        //     $("#invoiced-taxNumber-message").html("请填写税号");
        //     isSend = false;
        // } else if(taxNumber.length > 50){
        //     $("#invoiced-taxNumber-message").show();
        //     $("#invoiced-taxNumber-message").html("税号最多50个数字");
        //     isSend = false;
        // } else if(!(/^[0-9]+$/.test(taxNumber))){
        //     $("#invoiced-taxNumber-message").show();
        //     $("#invoiced-taxNumber-message").html("税号只能为数字");
        //     isSend = false;
        // }else {
        //     $("#invoiced-taxNumber-message").hide();
        // }

        // if(bankName==null||bankName.replace(/\s*/g,"")==""){
        //     $("#invoiced-bankName-message").show();
        //     $("#invoiced-bankName-message").html("请填写开户银行");
        //     isSend = false;
        // } else if(bankName.length > 50){
        //     $("#invoiced-bankName-message").show();
        //     $("#invoiced-bankName-message").html("开户银行最多50个字");
        //     isSend = false;
        // }else {
        //     $("#invoiced-bankName-message").hide();
        // }

        // if(bankAccount==null||bankAccount.replace(/\s*/g,"")==""){
        //     $("#invoiced-bankAccount-message").show();
        //     $("#invoiced-bankAccount-message").html("请填写开户行账号");
        //     isSend = false;
        // } else if(bankAccount.length > 20){
        //     $("#invoiced-bankAccount-message").show();
        //     $("#invoiced-bankAccount-message").html("开户行账号最多20个数字");
        //     isSend = false;
        // } else if(!(/^[0-9]+$/.test(bankAccount))){
        //     $("#invoiced-bankAccount-message").show();
        //     $("#invoiced-bankAccount-message").html("开户行账号只能为数字");
        //     isSend = false;
        // }else {
        //     $("#invoiced-bankAccount-message").hide();
        // }
        //
        if (money == null || money == "" || !Number(money)) {
            $("#invoiced-money-message").show();
            $("#invoiced-money-message").html("请填写开票金额");
            isSend = false;
        } else if (money < 1000) {
            $("#invoiced-money-message").show();
            $("#invoiced-money-message").html("注：开票面额不得低于1000元");
            isSend = false;
        } else if (money > maxMoney) {
            $("#invoiced-money-message").show();
            $("#invoiced-money-message").html("开票面额不得高于当前累计可开金额");
            isSend = false;
        } else {
            $("#invoiced-money-message").hide();
            isSend = true;
        }

        var data = {
            "applicationAmount": money,
            "companyName": companyName,
            "taxNumber": taxNumber,
            "address": address,
            "phone": phone,
            "bankName": bankName,
            "bankAccount": bankAccount,
            "name": name,
            "contactNumber": contactNumber,
            "receiptAddress": receiptAddress,
            "receiptEmail": receiptEmail
        }
        if (isSend) {
            $.ajax({
                url: '/api/invoice-records',
                type: 'post',
                contentType: "application/json",
                cache: false,
                data: JSON.stringify(data),
                success: function (res) {
                    window.location.href = window.location
                }
            })
        } else {
            $('#sub-btn-invoiceApply').css('cursor', 'not-allowed')
        }
    })
}(jQuery);
