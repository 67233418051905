+function ($) {
    'use strict';
    var forSquare = $(".order-new .for-square");
    var forCircle = $(".order-new .for-circle");
    forSquare.show();
    forCircle.hide();
    var ticFlag = true
    var lenFlag = true
    var breFlag = true
    var dimFlag = true
    var toleranceFlag = true
    $('#commoditySelector').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        var label = e.currentTarget.value;
        var shape = "SQUARE";
        if(label) {
           var arr = label.split('`');
           var id = arr[0];
            shape = arr[1];
           $(".order-new #commodityId").val(id);
           $(".order-new #modelShape").val(shape);
        }
        if(shape=="SQUARE") {
            forSquare.show();
            forCircle.hide();
        }else {
            forSquare.hide();
            forCircle.show();
            $(".order-new #tolerance").val(0);
            $(".order-new #process").val(0);
            $('#process.selectpicker').selectpicker('val',"SEMI");
        }
    });


    $("#cartCommodityForm .add-btn").on('click', function (e) {
       if(ticFlag&&lenFlag&&breFlag&&toleranceFlag){$("#cartCommodityForm").submit()}
    });

    $(".order-new .order-conform").on('click', function (e) {
        $("#quickOrderForm").submit()
    });

    $(".order-new .cart-del").on('click', function (e) {
        $("#delCartCommodity .delete-name").text($(this).data("name"));
        $("#delCartCommodity").attr("action", "/admin/quickOrders/"+$(this).data("order-id")+"/cartCommodity/"+$(this).data("id"));

        $("#delCartCommodityModal").modal("show");
    });


    var jian = $("#btn-reduces");
    var add = $("#btn-adds");
    var numberInput = $("#numberInputs");
    var numberValue;

    numberInput.on("input",function(){
        numberValue = parseInt(numberInput.val())
        if (numberValue<=1) {
            jian.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            numberInput.val(1)
            return;
        }else {
            jian.css({"backgroundColor":"#fff","color":"#1E9FFF","cursor":"pointer"})
        }
    })
    jian.on("click",function () {
        numberValue =  parseInt(numberInput.val())
        numberValue--;
        if (numberValue<=1) {
            jian.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            numberInput.val(1)
            return;
        }else {
            jian.css({"backgroundColor":"#fff","color":"#1E9FFF","cursor":"pointer"})
        }
        numberInput.val(numberValue)
    })
    add.on("click",function () {
        numberValue =  parseInt(numberInput.val())
        numberValue++;
        if (numberValue<=1) {
            jian.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            numberInput.val(1)
            return;
        }else {
            jian.css({"backgroundColor":"#fff","color":"#1E9FFF","cursor":"pointer"})
        }
        numberInput.val(numberValue)
    })
        // function isDisable(){
        //     if (numberValue<=1) {
        //         jian.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
        //         numberInput.val(1)
        //         return;
        //     }else {
        //         jian.css({"backgroundColor":"#fff","color":"#1E9FFF","cursor":"pointer"})
        //     }
        // }
    var resn =null;
    var ress=[];

    $("#commoditySelector,#process").on('change',function () {
        var idAndType = $("#commoditySelector").val();
        var process = $("#process").val();
        var it = idAndType.split("`");
        if (it[1] == "SQUARE") {
            $(".chamfer").removeClass('hidden');
        } else {
            $(".chamfer").addClass('hidden');
        }
        if(idAndType!=""&&(process!=""||it[1]=="CYLINDER")){
        $.ajax({
                url: "/admin/quickOrders/quickOrder?id=" + it[0] + "&process=" + process,
                type: "get",
                async: true,
                success: function (res) {
                    // 光板
                    if (it[1] == "SQUARE") {
                        $("#toleranceType").val("POSI_NEGA");
                        if (process == "ROUGH") {
                            $("#tolerance").val(0.5);
                            $("#tolerance-div").removeClass('hidden');
                            $(".chamfer").removeClass('hidden');
                        } else if (process == "CONCENTRATE") {
                            $("#tolerance").val(0.1);
                            $("#tolerance-div").removeClass('hidden');
                            $(".chamfer").removeClass('hidden');
                        } else if (process = "SEMI") {
                            $("#tolerance").val(0);
                            $("#tolerance-div").addClass('hidden');
                            $(".chamfer").addClass('hidden');
                        }
                    }
                    resn = res
                    ress = []
                    var tempLen = null
                    var tempWid = null
                    var tempTic = null
                    var tempTicS = null
                    var temmpdime = null
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].sideType == "DIAMETER" && res[i].valueType == "SELECTION") {
                            temmpdime = res[i].valueRange.split(",");
                            if (temmpdime.length > 0) {
                                for (var j = 0; j < temmpdime.length; j++) {
                                    temmpdime[j] = temmpdime[j] * 1;
                                }
                            }
                            $("#diameter").attr('placeholder', "可选值:" + temmpdime)
                            break;
                        }
                    }
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].sideType == "LENGTH" && res[i].valueType == "RANGE") {
                            tempLen = res[i].valueRange.split(",");
                            tempLen[0] = tempLen[0] * 1;
                            tempLen[1] = tempLen[1] * 1;
                            $("#length").attr('placeholder', tempLen[0] + "--" + tempLen[1])
                            break;
                        }
                    }
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].sideType == "WIDTH" && res[i].valueType == "RANGE") {
                            tempWid = res[i].valueRange.split(",");
                            tempWid[0] = tempWid[0] * 1;
                            tempWid[1] = tempWid[1] * 1;
                            $("#breadth").attr('placeholder', tempWid[0] + "--" + tempWid[1])
                            break;
                        }
                    }
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].sideType == "THICKNESS" && res[i].valueType == "RANGE") {
                            tempTic = res[i].valueRange.split(",");
                            tempTic[0] = tempTic[0] * 1;
                            tempTic[1] = tempTic[1] * 1;
                            $("#thickness").attr('placeholder', tempTic[0] + "--" + tempTic[1])
                            break;
                        } else if (res[i].sideType == "THICKNESS" && res[i].valueType == "SELECTION") {
                            tempTicS = res[i].valueRange.split(",");
                            if (tempTicS.length > 0) {
                                for (var j = 0; j < tempTicS.length; j++) {
                                    tempTicS[j] = tempTicS[j] * 1;
                                }
                            }
                            $("#thickness").attr('placeholder', "可选值:" + tempTicS)
                            break;
                        }
                    }


                    ress.push(tempLen)
                    ress.push(tempWid)
                    ress.push(tempTic)
                    ress.push(tempTicS)
                    ress.push(temmpdime)
                }
            }

        )

        $("#length").on('change', function () {
            var length = $(this).val();
            var breath = $("#breadth").val();
            if (length < ress[0][0] || length > ress[0][1]) {
                $("#len-label").show()
                $("#len-label").html("请输入" + ress[0][0] + "--" + ress[0][1] + "之间的值")
                lenFlag = false
            } else if (parseInt(breath) > parseInt(length)) {
                $("#len-label").show()
                $("#len-label").html("长度不能小于宽度")
                lenFlag = false
            } else {
                $("#len-label").hide()
                lenFlag = true
            }
        })


        $("#breadth").on('change', function () {
            var breath = $(this).val();
            var length = $("#length").val();
            var thickness = $("#thickness").val();
            if (breath < ress[1][0] || breath > ress[1][1]) {
                $("#wid-label").show()
                $("#wid-label").html("请输入" + ress[1][0] + "mm--" + ress[1][1] + "之间的值")
                breFlag = false
            } else if (parseInt(breath) > parseInt(length)) {
                $("#wid-label").show()
                $("#wid-label").html("宽度不能大于长度")
                lenFlag = false
            } else if (parseInt(thickness) > parseInt(breath)) {
                $("#wid-label").show()
                $("#wid-label").html("宽度不能小于厚度")
                lenFlag = false
            } else {
                $("#wid-label").hide()
                breFlag = true
            }
        })


        $("#thickness").on('change', function () {
            var thickness = $(this).val();
            var breath = $("#breadth").val();
            for (var i = 0; i < resn.length; i++) {
                if (resn[i].sideType == "THICKNESS" && resn[i].valueType == "RANGE") {
                    if (thickness < ress[2][0] || thickness > ress[2][1]) {
                        $("#tic-label").show()
                        $("#tic-label").html("请输入" + ress[2][0] + "--" + ress[2][1] + "之间的值")
                        ticFlag = false
                    } else if (parseInt(thickness) > parseInt(breath)) {
                        $("#tic-label").show()
                        $("#tic-label").html("厚度不能大于宽度")
                        lenFlag = false
                    } else {
                        $("#tic-label").hide()
                        ticFlag = true
                    }
                    break;
                } else if (resn[i].sideType == "THICKNESS" && resn[i].valueType == "SELECTION") {
                    if (ress[3].indexOf(thickness * 1) < 0) {
                        $("#tic-label").show()
                        $("#tic-label").html("请输入" + ress[3] + "中的值")
                        ticFlag = false
                    } else if (parseInt(thickness) > parseInt(breath)) {
                        $("#tic-label").show()
                        $("#tic-label").html("厚度不能大于宽度")
                        lenFlag = false
                    } else {
                        $("#tic-label").hide()
                        ticFlag = true
                    }
                    break;
                }
            }


        })



            $("#diameter").on('change', function () {
                var diameter = $(this)
                for (var i = 0; i < resn.length; i++) {
                    if (resn[i].sideType == "DIAMETER" && resn[i].valueType == "SELECTION") {
                        if (ress[4].indexOf(diameter.val() * 1) < 0) {
                            $("#diameter-label").show()
                            $("#diameter-label").html("请输入" + ress[4] + "中的值")
                            toleranceFlag = false
                        } else {
                            $("#diameter-label").hide()
                            toleranceFlag = true
                        }
                        break;
                    }
                }


            })


        }
        
    })




    $("#tolerance").on('change', function () {
        var tolerance = $(this)
                if (tolerance.val()< 0) {
                    $("#tolerance-label").show()
                    $("#tolerance-label").html("公差不能为负数")
                    dimFlag = false
                } else {
                    $("#tolerance-label").hide()
                    dimFlag = true
                }
    })

    $("#quickOrder-page").on("click",function () {
        search()
    })

    $("#quickOrder-page-size-selector").on('change', function (e) {
        search()
    });

    function search(page) {
        var params = getParams();
        var size_sort = formatParam(params)+'&sort=createdTime,desc';
        // var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        window.location.href =  '/mall/buyerCenter/quick-orders?' + size_sort;
    }

    function getParams(defaultPage) {
        var page = defaultPage ? defaultPage : Number($("#notice-jump").val());
        var size = $("#quickOrder-page-size-selector").val();
        var total = $(".quickOrder-page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }

    }

    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }


}(jQuery);
