+function ($) {
    'use strict';

    $(".message-list #deleteAll").on('click', function (e) {
        if($("#deleteMessageForm #checkList").val()) {
            $("#deleteMessageForm .warning-label").text("此操作将永久删除选中记录，是否继续？");
            $("#deleteMessageForm .conform").show();
        } else {
            $("#deleteMessageForm .warning-label").text("请至少选中一条记录！");
            $("#deleteMessageForm .conform").hide();
        }
        $("#deleteMessageForm #params").val(getParams());
        $("#delMessageModal").modal("show");
    });
    $(".message-list .message-delete").on('click', function (e) {
        $("#deleteMessageForm .warning-label").text("此操作将永久删除此记录，是否继续？");
        $("#deleteMessageForm #params").val(getParams());
        $("#deleteMessageForm #checkList").val($(this).data("id"));
        $("#delMessageModal").modal("show");
    });
    $("#deleteMessageForm .conform").on('click', function (e) {
        $("#deleteMessageForm").submit();
    });


    $(".message-list .range-input").daterangepicker(
        {
            autoApply: true,
            autoUpdateInput: false,
            alwaysShowCalendars: true,
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '近7天': [moment().subtract(7, 'days'), moment()],
                '这个月': [moment().startOf('month'), moment().endOf('month')],
                '上个月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                format: "YYYY/MM/DD HH:MM:SS",
                separator: " 至 ",
                applyLabel: "确认",
                cancelLabel: "清空",
                fromLabel: "开始时间",
                toLabel: "结束时间",
                customRangeLabel: "自定义",
                daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
            }
        }
    ).on('apply.daterangepicker', function (ev, picker) {
        $("#startTime").val(picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
        $("#endTime").val(picker.endDate.format('YYYY-MM-DD HH:mm:ss'));
        search(1);
    });
    $(".message-list .timerange-group .input-icon").on('click', function (e) {
        $("#startTime").val("");
        $("#endTime").val("");
        search(1);
    });
    $(".message-list .type-group .item").on('click', function (e) {
        $(".message-list #orderType").val($(this).data("value"));
        search(1);
    });

    $(".message-list #processed").on('change', function (e) {
        search(1)
    });

    $(".message-list .page-conform").on('click', function (e) {
        search()
    });

    $(".message-list .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var orderType = $(".message-list input[name=orderType]").val();
        var page = defaultPage ? defaultPage : Number($(".message-list .jump").val());
        var size = $(".message-list .page-size-selector").val();
        var total = $(".message-list .page-total").val();
        var startTime = $(".message-list #startTime").val();
        var endTime = $(".message-list #endTime").val();
        var processed = $(".message-list #processed").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return 'orderType=' + orderType + '&page=' + page + '&size=' + size + '&startTime=' + startTime + '&endTime=' + endTime + '&processed=' + processed;
    }

    function search() {
        var params = getParams();
        var action = '/admin/orderMessages?' + params;
        window.location.href = action;
    }


    var checkList = [];
    var checkAllfalse = false;
    $("#noticeCheckAll").on('click', function (e) {
        checkAllfalse = !checkAllfalse;
        if (checkAllfalse) {
            $('.check-order').prop("checked", true);
            getCheckList()
        } else {
            $('.check-order').each(function () {
                $(this).prop("checked", false);
            });
            checkList = [];
            getCheckList()
        }
    });

    function getCheckList() {
        checkList = [];
        $.each($('.check-order:checked'), function () {
            checkList.push($(this).val());
        });
        $("#checkList").val(checkList);
    }
}(jQuery);
