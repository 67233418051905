+function ($) {
    'use strict';

    $(".image-preview").on('click', function (e) {
        var modal = $("#imagePreview");
        modal.find(".current-image").attr("src", "/files/"+$(this).data("url"));
        modal.modal("show");
    })


}(jQuery);
