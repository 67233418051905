
// $(function () {
//     $('#sendBtn1').click(function() {
//         $('#sendTest1').css('display','none');
//     });
// })


$(document).ready(function(e) {
    $("#sendTest1").click(function(e) {
        $("#sendToggle1").toggle()
    });
    $('#sendBtn1').click(function() {
        $('#sendToggle1').hide();
    });
    $("#sendTest2").click(function(e) {
        $("#sendToggle2").toggle()
    });
    $('#sendBtn2').click(function() {
        $('#sendToggle2').hide();
    });
});
