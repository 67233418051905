+function ($) {
    'use strict';

    var categoryItemId = ''
    // 首页分类的 hover js 开始
    // 使用变量，使以下六个hover方法，变为一个
    $('.category-item').on({
        mouseenter: function () {
            $('.category-modal').html("");
            $.ajax({
                "url": "/api/commodities/category?categoryId=" + $(this).attr("data-id"),
                "async": false,
                "success": function (result) {
                    $.each(result, function (i, v) {
                        var commodity = "";
                        var moreCommodity = v.commodities.length > 35 ? '<a class="more-text" href="/mall/commodities?transverse&category.id=' + v.id + '">更多>></a>' : '';
                        for (var j = 0; j < 35 && j < v.commodities.length; j++) {
                            commodity += '<a href="/mall/commodities/show/' + v.commodities[j].id + '">' + v.commodities[j].name + '</a>';
                        }
                        var groupHTML = '<div>' +
                            '<h4 class="sub-category-title sub-category-item"><a href="/mall/commodities?transverse&category.id=' + v.id + '">'+v.categoryName+'</a></h4>' +
                            '<div class="sub-category-content">' +
                            '<div class="category-commodities">' + commodity + moreCommodity +
                            '</div>' +
                            '</div>' +
                            '</div>';
                        $(".category-modal").append(groupHTML);
                    })
                }
            });
            categoryItemId = '#' + $(this).attr("id");
            $('.category-modal').css("display", "block");
            $(this).css("z-index", 11);
            $(this).css("box-shadow", "-14px 0 16px 0 rgba(197, 197, 197, 0.5)");
        },
        mouseleave: function () {
            $('.category-modal').css("display", "none")
            $(this).css("z-index", 1)
            $(this).css("box-shadow", "unset")
        }
    });
    $('.category-modal').hover(function () {
        $('.category-modal').css("display", "block")
        $(categoryItemId).css("z-index", 11)
        $(categoryItemId).css("box-shadow", "-14px 0 16px 0 rgba(197, 197, 197, 0.5)")
    }, function () {
        $('.category-modal').css("display", "none")
        $(categoryItemId).css("z-index", 1)
        $(categoryItemId).css("box-shadow", "unset")
    })
    // 首页分类的 hover js 结束
    // 首页tab栏悬浮时二级分类触发的 js 方法 开始
    var credit = $("#credit").val() === 'true';
    var interestRate = credit ? $("#interestRate").val() : 1;

    first_show();
    sellWell_show();
    member_show();
    //初次加載商品數據
    function first_show() {
        load_commodity($(".subcategory1").attr("data-id"));
    }

    function sellWell_show() {
        $.getJSON("/api/commodities?size=4&sellWell=true", function (result) {
            $("#hot-good-list").html("").append(convertCommodityLabel(result));
        });
    }

    function member_show() {
        $.getJSON("/api/commodities?size=4&attributeType=MEMBER", function (result) {
            $("#member-good-list").html("").append(convertCommodityLabel(result));
        });
    }

    $(".subcategory-item").click(function() {
        var id = $(this).attr('data-id');
        var parentId = $(this).attr('parent-id');
        load_commodity(id, parentId);
        $(this).parent().find(".subcategory-item").css({"color": "#13283F", "background": "#FFFFFF"});
        $(this).css({"color": "#FFFFFF", "background": "#064CAF"})
    });

    function convertFacAndPrice(commodities) {
        var facAndPrice = '';
        $.each(commodities, function (i, v) {
            if (i >= 3) {
                return facAndPrice;
            }
            var micprice = '￥' + parseFloat(v.minPrice * interestRate, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            facAndPrice = facAndPrice +
                '<div class="fac">' +
                    '<div class="name" title="' + v.brand + '">' + v.brand + '</div>' +
                    '<div class="price-fac" title="' + micprice + '">' + micprice + '</div>' +
                '</div>';
        });
        return facAndPrice;
    }

    function load_commodity(id) {
        if (id) {
            $.getJSON("/api/commodities?size=999&category.id=" + id, function (result) {
                $("#category-good-list").html("").append(convertCommodityLabel(result));
            });
        }
    }

    function convertCommodityLabel(result) {
        if (result.length == 0) {
            return '<div style="margin: auto"><div style="background: url(/content/images/noCommodity.png) no-repeat 50% 50%;margin-top: 40px;height: 200px;width: 200px;background-size:100% 100%;"></div><div style="font-size: 16px;text-align: center">很抱歉，暂无商品</div></div>'
        } else {
            var groupHTML = '';
            $.each(result, function (i, v) {
                var image = (v.annexes.length == 0 ? "/content/images/side-logo-icon.png" : "/files/fact2/" + v.annexes[0].serial)
                var facAndPrice = convertFacAndPrice(v.otherCommodities);
                groupHTML = groupHTML +
                    '<div class="item" onclick="location.href=\'/mall/commodities/show/' + v.id + '\'">' +
                    '<img src=' + image + ' class="img">' +
                    '<div class="flex-1 info">' +
                    '<div class="name" title="' + v.name + '">' + v.name + '</div>' +
                    facAndPrice +
                    '</div>' +
                    '</div>';
            })
            // if (result.length >= 36) {
            //     var groupHTML = '<div class="commodity-item" onclick="location.href=\'/mall/commodities?transverse&category.id='+id+'\'">' +
            //         '<img src="/content/images/findMore.png" class="commodity-image" style="width: 144px;height:210px;">' +
            //         '</div>';
            //     $(".commodity-list-" + parentId).append(groupHTML);
            // }
            return groupHTML;
        }
    }

    // 首页点击二级分类触发的 js 方法 结束


    /* 首页二维码hover动效 */
    var indexQrCode  = $("#index-qr-code")
    var qrCodeHoverBox  = $("#qr-code-hover")
    indexQrCode.on('mouseenter',function(){
        qrCodeHoverBox.show()
    })
    indexQrCode.on('mouseleave',function(){
        qrCodeHoverBox.hide()
    })
    qrCodeHoverBox.on('mouseenter',function(){
        qrCodeHoverBox.show()
    })
    qrCodeHoverBox.on('mouseleave',function(){
        qrCodeHoverBox.hide()
    })
}(jQuery);
