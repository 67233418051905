+function () {
    'use strict';


    var urls = [];
    var fileInfo = {};
    $('#nameCount').text($(".name-input").val() ? $(".name-input").val().length : 0);
    $('#descriptionCount').text($(".description-input").val() ? $(".description-input").val().length : 0);
    if ($("#fileName").val()) {
        var fileName = $("#fileName").val().split("/");
        var fileRelativeAddress = $("#fileRelativeAddress").val().split(",");
        fileName.forEach(function (item, index) {
            if (item) {
                var url = fileRelativeAddress[index];
                urls.push('/files/' + url);
                fileInfo["preview-init_" + index] = {
                    fileName: item,
                    serial: url
                };
            }
        });
    }
    var cutFiles = {};
    var jcropApi = null;
    var commodityFile = $("#commodityEditPicture");
    commodityFile.fileinput({
        totalCount: 3,
        initialPreview: urls,
        initialPreviewAsData: true,
        uploadUrl: "/admin/files",
        maxFileCount: 3,
        showCancel: false,
        showRemove: false,
        showUpload: false,
        overwriteInitial: false,
        allowedFileExtensions: ["jpg", "png","JPEG"],
        maxFileSize: 2048,
        browseLabel: "选择文件"
    }).on('fileselect', function (event, numFiles, label) {
    }).on('filebatchselected', function (event, files) {
    }).on('fileloaded', function (event, file, previewId, index, reader) {


        $(".cut-image-area").attr("src", reader.result)
        $('#cutImage').Jcrop({
            aspectRatio: 1,
            onDblClick: function (e) {
                cutFiles[previewId] = e;

                var option = cutFiles[previewId];
                option.type = file.type;
                option.name = file.name;
                commodityFile.fileinput('_cutImage', $("#"+previewId).data("fileid"), option, file);
                $("#cutModal").modal("hide");
                jcropApi.destroy();
                setTimeout(function () {
                    commodityFile.fileinput("upload")
                }, 100);
            }
        }, function () {
            jcropApi = this;
        });

        $("#cutModal").modal("show");
    }).on('filepreupload', function (event, data, previewId, index) {
    }).on('fileuploaded', function (event, data, id, index) {
        var i = Object.keys(fileInfo).length;
        var customId = 'preview' + (i + 1);
        var imageUrl = data.response.fileRelativeAddress;
        fileInfo[customId] = {
            fileName: data.response.originName,
            serial: imageUrl
        };
        $("#"+id +" .file-preview-image").attr("src", "/files/"+imageUrl);
        $("#zoom-"+id +" .file-preview-image").attr("src", "/files/"+imageUrl);
        fillInfo(fileInfo);
        $($(".file-preview-thumbnails .file-preview-frame.kv-preview-thumb").get(i)).attr("id", customId);
        $($(".file-preview-thumbnails .file-preview-frame.kv-zoom-thumb").get(i)).attr("id", "zoom-" + customId);
    }).on('filesuccessremove', function (event, id, index) {  //上传后，移除文件
        var info = fileInfo[id];
        $.ajax({
            url: '/files/' + info.serial,
            type: 'delete',
            success: function (data) {
            }
        });
        delete fileInfo[id];
        fillInfo(fileInfo);
    }).on('fileremoved', function (event, id, index) {  //未上传，移除文件
        delete fileInfo[id];
        fillInfo(fileInfo);
    });


    function fillInfo(map) {
        var names = '';
        var address = '';
        Object.keys(map).forEach(function (f) {
            var item = map[f];
            names = names + (names ? '/' : '') + item.fileName;
            address = address + (address ? ',' : '') + item.serial;
        });
        $("#fileName").val(names);
        $("#fileRelativeAddress").val(address);
    }

}(jQuery)
