+function ($) {
    'use strict';

    $(".member-list .member-edit").on("click", function (e) {
        var id = $(this).data("id");
        var level = $(this).data("level");
        var params = getParams();
        $("#params").val(formatParam(params));
        $("#memberId").val(id);
        $('#memberLevel.selectpicker').selectpicker('val',level);
        $("#editMemberLevel").modal("show");
    });

    $(".member-list .member-del").on("click", function (e) {
        var id = $(this).data("id");
        var params = getParams();
        var form = "#form"+id;
        $(form + " #formparam").val(formatParam(params));
        $(form).submit();
    });

    $(".member-list #level").on('change', function (e) {
        search(1)
    });

    $(".member-list .search-btn").on('click', function (e) {
        search(1)
    });

    $(".member-list .page-conform").on('click', function (e) {
        search()
    });

    $(".member-list .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var search = $(".member-list input[name=search]").val();
        var level = $(".member-list #level").val();
        var page = defaultPage?defaultPage:Number($(".member-list .jump").val());
        var size = $(".member-list .page-size-selector").val();
        var total = $(".member-list .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            search: search,
            level: level,
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/members?'+formatParam(params);
    }
    function formatParam(params) {
       return 'search=' + params.search + '&level=' + params.level + '&page=' + params.page + '&size=' + params.size;
    }

}(jQuery);
