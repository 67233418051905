+function ($) {
    'use strict';

    var url = window.location.search;

    if(url.indexOf("error") != -1){
        $(".login-title-message").html("用户名或密码错误");
    }
    if(url.indexOf("logout") != -1){
        $(".login-title-message").html("您已登出");
    }

    // $("#validPhone").on("change input", function () {
    //     var val = $(this).val();
    //     var getValid = $("#getValidateCode");
    //    if(val) {
    //        getValid.removeAttr("disabled");
    //    }else {
    //        getValid.attr("disabled", "disabled");
    //    }
    // });
    //
    // $("#validCode").on("change blur", function () {
    //     var val = $(this).val();
    //     if(val && $(".forget-form-card").css("display") == "block") {
    //         $.ajax({
    //             url: "/admin/validCode/validator?phone="+$("#validPhone").val()+"&validCode="+$("#validCode").val()+"&type=FORGET_PASSWORD",
    //             type: "get",
    //             success: function (res) {
    //                 $("#adminSavePasswordBtn").removeAttr("disabled");
    //                 $("#activationKey").val(res);
    //                 $("#validError").text("");
    //             },
    //             error: function (res) {
    //                 $("#validError").text("验证码错误");
    //                 $("#adminSavePasswordBtn").attr("disabled", "disabled");
    //                 $("#activationKey").val("***");
    //             }
    //         });
    //     }
    // });
    //
    // $("#getValidateCode").on("click", function () {
    //     var self = $(this);
    //     var phone = $("#validPhone").val();
    //     $.ajax({
    //         url: "/api/phoneCode?phoneNumber="+phone+"&type=FORGET_PASSWORD",
    //         type: 'get',
    //         success: function (res) {
    //             $("#phoneError").text("");
    //             var $btn = self.button('loading');
    //             self.attr("disabled", "disabled");
    //             var count = self.data("count") - 1;
    //             var timer = setInterval(function () {
    //                 self.text(count-- + 's');
    //                 if(count<0) {
    //                     clearInterval(timer);
    //                     self.attr("disabled", "");
    //                     self.text("获取验证码");
    //                     $btn.button('reset')
    //                 }
    //             }, 1000);
    //         },
    //         error: function (res) {
    //             if(res.status == 400) {
    //                 $("#phoneError").text(res.responseText);
    //             }
    //         }
    //     });
    // });
    //
    // $(".forget-password").on('click', function () {
    //     $(".login-form-card").toggle();
    //     $(".forget-form-card").toggle();
    //     $("#adminForgetForm")[0].reset()
    // });
    //
    // $(".back-login").on('click', function () {
    //     $(".login-form-card").toggle();
    //     $(".forget-form-card").toggle();
    //     $("#adminForgetForm")[0].reset()
    // });

    $("#adminLoginBtn").on('click', function (e) {
        $(this).button('loading');
        $(this).attr("disabled", "disabled");
        $("#adminLoginForm").submit();
    })


}(jQuery);
