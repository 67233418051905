+function ($) {
    'use strict';

    $(".personal_page .forget-send-phone-code").on('click', function () {
        var newPhone = $(".personal_page #mobile").val()
        var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;

        if (newPhone != null && newPhone != "") {
            if (!myreg.test(newPhone)) {
                $("#newPhone-label").show()
                $("#newPhone-label").html("请输入正确的手机号")
                return false
            }
            $.ajax({
                url: '/api/phoneCode?type=FORGET_PASSWORD&phoneNumber=' + newPhone,
                type: 'get',
                success: function (res) {
                    $("#modifyMobileModal").modal("show")
                    time($(".personal_page .registered-send-phone-code"));
                    $("#newPhone-label").hide()
                },
                error: function (res) {
                    $("#modifyMobileModal").modal("show")
                    $("#newPhone-label").show()
                    $("#newPhone-label").html(res.responseText)
                }
            })
        } else {
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入手机号码。")
        }
    })

    var waitTime = 60;

    function time(sendCode) {
        if (waitTime == 0) {
            sendCode.removeAttr("disabled");
            sendCode.text("发送验证码");
            waitTime = 60;
        } else {
            sendCode.attr("disabled", true);
            sendCode.text("重新发送" + waitTime + "s");
            waitTime--;
            setTimeout(function () {
                    time(sendCode)
                },
                1000)
        }
    }



}(jQuery);
