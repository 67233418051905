+function ($) {
    'use strict';

    $(".invoice-record .invoice-btn").on('click', function (e) {
        var id = $(this).data("id");
        var ableAmount = $(this).data("ableamount");
        var amount = $(this).data("amount");
        $("#invoiceForm input[name=id]").val(id);
        $("#invoiceForm .amount").text(ableAmount);
        $("#amount").val(amount);
        $("#hiddenAmount").val(amount);
        $("#invoiceForm #amount").attr("data-remote", "/admin/invoiceRecord/validator?amount="+amount+"&");
        $("#invoiceModal").modal('show');
    });

    $(".invoice-record .delete-btn").on('click', function (e) {
        var id = $(this).data("id");
        $("#invoiceDeleteForm").attr('action', '/admin/invoiceRecords/'+id);
        $("#invoiceDeleteForm input[name=params]").val(formatParams(getParams()));
        $("#invoiceDeleteModal").modal('show');
    });

    $(".invoice-record .search-btn").on('click', function (e) {
        search(1)
    });

    $(".invoice-record .page-conform").on('click', function (e) {
        search()
    });

    $(".invoice-record .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var search = $(".invoice-record #search").val();
        var invoiced = $(".invoice-record #invoiced").val();
        var page = defaultPage?defaultPage:Number($(".invoice-record .jump").val());
        var size = $(".invoice-record .page-size-selector").val();
        var total = $(".invoice-record .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            search: search,
            page: page,
            size: size,
            invoiced: invoiced
        }
    }

    function search() {
        window.location.href = '/admin/invoiceRecords?'+formatParams(getParams());
    }
    function formatParams(params) {
        return 'invoiced='+params.invoiced+'&companyName=' + params.search + '&page=' + params.page + '&size=' + params.size
    }
}(jQuery);
