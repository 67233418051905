+function ($) {
    'use strict';

    $(".steel-type-manage .edit-type").on("click", function (e) {
        var form = $("#editTypeForm");
        form.find("#typeId").val($(this).data("id"));
        form.find("#editTypeName").val($(this).data("name"));
        form.find("#editTypeDensity").val($(this).data("density"));
        var params = getParams();
        form.find("#params").val(formatParam(params));
        $("#editType").modal("show");
    });

    $(".steel-type-manage .delete-type").on("click", function (e) {
        var id = $(this).data("id");
        var name = $(this).data("name");
        $.ajax({
            url: "/admin/steelTypes/validator?id="+id,
            type: 'get',
            success: function (res) {
                if(res === 0) {
                    $("#deleteTypeForm").attr("action", "/admin/steelTypes/" + id);
                    $("#deleteTypeForm .warning-label").text('此操作将永久删除类型"'+name+'"，是否继续？');
                } else {
                    $("#deleteTypeForm .warning-label").text('类型"'+name+'"下存在商品，不能删除！');
                    $("#deleteTypeForm .conform").hide();
                }
                $("#deleteType").modal("show");
            },
            error: function (res) {

            }
        });
    });

    $("#deleteTypeForm .conform").on('click', function (e) {
        $("#deleteTypeForm").submit();
    });

    $(".steel-type-manage .page-conform").on('click', function (e) {
        search()
    });

    $(".steel-type-manage .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var page = defaultPage?defaultPage:Number($(".steel-type-manage .jump").val());
        var size = $(".steel-type-manage .page-size-selector").val();
        var total = $(".steel-type-manage .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/steelTypes?'+formatParam(params);
    }
    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }

}(jQuery);
