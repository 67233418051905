+function ($) {
    'use strict';

    $(".user-manage .user-active").on('click', function (e) {
        var id = $(this).data("id");
        var params = getParams();
        var form = "#form"+id;
        $(form + " #formparam").val(formatParam(params));
        $(form).submit();
    });

    $(".user-manage .change-password").on('click', function (e) {
        var id = $(this).data("id");
        $("#passwordChangeForm #userId").val(id);
        $("#passwordChangeForm #params").val(formatParam(getParams()));

        $("#changePassword").modal("show");
    });

    $(".user-manage .search-btn").on('click', function (e) {
        search(1)
    });

    $(".user-manage .page-conform").on('click', function (e) {
        search()
    });

    $(".user-manage .page-size-selector").on('change', function () {
        search(1)
    });

    function getParams(defaultPage) {
        var search = $(".user-manage input[name=search]").val();
        var page = defaultPage?defaultPage:Number($(".user-manage .jump").val());
        var size = $(".user-manage .page-size-selector").val();
        var total = $(".user-manage .page-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            search: search,
            page: page,
            size: size
        }
    }

    function search() {
        var params = getParams();
        window.location.href = '/admin/users?'+formatParam(params);
    }
    function formatParam(params) {
        return 'search=' + params.search + '&page=' + params.page + '&size=' + params.size;
    }
}(jQuery);
