+function ($) {
    'use strict';

    $('#quick-order-file').change(function () {
        var currentFile = $('#quick-order-file').val(); // 上传文件地址
        var filePathArray = currentFile.split("\\"); // 文件地址数组化
        var fileName = filePathArray[filePathArray.length - 1]; // 获取文件名
        var fileNameArray = fileName.split('.'); // 文件名数组化
        var fileNameExtension = fileNameArray[fileNameArray.length - 1]; // 获取文件扩展名
        var acceptFileTypes = ".doc,.docx,.pdf,.jpg,.jpeg,.png,.xls,.xlsx"; // 可上传的文件扩展名

        if (acceptFileTypes.includes(fileNameExtension)) {
            // 文件类型可上传
            $('.files-content').css('display', 'flex');
            // 获取文件图标
            switch (fileNameExtension) {
                case 'pdf':
                    $('.file-img').attr('src', '/content/images/pdf.png');
                    break;
                case 'doc':
                case 'docx':
                    $('.file-img').attr('src', '/content/images/word.png');
                    break;
                case 'xlsx':
                case 'xls':
                    $('.file-img').attr('src', '/content/images/excel.png');
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                    $('.file-img').attr('src', '/content/images/jpg.png');
                    break;
                default: break
            }
            // 获取文件名
            $('.file-name').html(fileName)
        } else {
            $.submitQuickOrdermessage("warn","文件类型不对!");
        }
    });

    $(".create-quick-orders-button").click(function () {
        var userAddressId;
        var message = $(".buyer-message-textarea").val();
        var urgent = $("input[name='urgent']:checked").val() === 'true';
        $.each($(".address-item"), function (i, item) {
            if($(item).hasClass("address-item-selected")){
                userAddressId = $(item).children(".address-item-content-info").children(".modify-button-id").data("id").replace("modify-button-","")
            }
        })

        $("#quickordermessage").val(message)
        $("#quickorderaddress").val(userAddressId)
        $("#quickorderurgent").val(urgent)
        if (!isEmpty(message)) {
            if(message.length > 255) {
                $.submitQuickOrdermessage("warn", "买家留言最多255个字!");
                return false;
            }
        }
        if (isEmpty(userAddressId)) {
            $.submitQuickOrdermessage("warn","请输入收货地址并保存!");
            return false;
        }
        if(isEmpty($("#quick-order-file").val())){
            $.submitQuickOrdermessage("warn","请上传文件后提交!");
            return false;
        }
        var urgentAvailable = false;
        if (urgent) {
            $.ajax({
                url: "/api/urgentAvailable",
                type: "get",
                async: false,
                contentType: "application/json",
                cache: false,
                success: function (result) {
                    urgentAvailable = result;
                }
            });
        }
        if(urgent && !urgentAvailable){
            $("input[name='urgent']").attr("disabled","true");
            $("#urgent-off").attr("checked","true");
            $("#urgentNumber").text("0");
            $.submitQuickOrdermessage("warn","加急次数已使用完，该订单无法加急!");
        }
    })

    // 消息框
    var $message;
    var messageTimer;
    $.submitQuickOrdermessage = function() {
        var message = {};
        if ($.isPlainObject(arguments[0])) {
            message = arguments[0];
        } else if (typeof arguments[0] === "string" && typeof arguments[1] === "string") {
            message.type = arguments[0];
            message.content = arguments[1];
        } else {
            return false;
        }

        if (message.type == null || message.content == null) {
            return false;
        }

        if ($message == null) {
            $message = $('<div class="xxMessage" ><i class="iconfont icon-warning" style="float:left;"></i><div class="messageContent message' + escapeHtml(message.type) + 'Icon"><\/div><\/div>');
            if (!window.XMLHttpRequest) {
                $message.append('<iframe class="messageIframe"><\/iframe>');
            }
            $message.appendTo("body");
        }

        $message.children("div").removeClass("messagewarnIcon messageerrorIcon messagesuccessIcon").addClass("message" + message.type + "Icon").html(message.content);
        $message.css({"margin-left": - parseInt($message.outerWidth() / 2), "z-index": 9999}).show();

        clearTimeout(messageTimer);
        messageTimer = setTimeout(function() {
            $message.hide();
        }, 3000);
        return $message;
    }

    $("#quickOrder-new").on("click",function () {
        search()
    })

    $("#quickOrder-new-size-selector").on('change', function (e) {
        search()
    });

    function search(page) {
        var params = getParams();
        var size_sort = formatParam(params)+'&sort=createdTime,desc';
        // var urlStr = requestId ? '&category.id='+requestId + '&transverse' : searchFlg == 'sellWell' ? '&sellWell=true&search' : '&search&searchName=' + $('.j-searchInput').val();
        window.location.href =  '/mall/quick-orders/add?' + size_sort;
    }

    function getParams(defaultPage) {
        var page = defaultPage ? defaultPage : Number($("#notice-jump").val());
        var size = $("#quickOrder-new-size-selector").val();
        var total = $(".quickOrder-new-total").val();
        page = (page < 1 ? 1 : page > total ? total : page) - 1;
        return {
            page: page,
            size: size
        }

    }

    function formatParam(params) {
        return 'page=' + params.page + '&size=' + params.size;
    }
    // 购买数量 + -
    var reduceBtn = $(".purchase-btn-reduces");
    var addBtn = $(".purchase-btn-adds");
    var countInput = $(".countInputs");
    var countValue;

    countInput.on("input",function(){
        countValue = parseInt($(this).val())
        if (countValue<=1) {
            var reBtn = $(this).siblings('button')[0]
            reBtn.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            $(this).val(1)
            return;
        }else {
            reBtn.css({"backgroundColor":"#fff","color":"#333","cursor":"pointer"})
        }
    })
    reduceBtn.on("click",function () {
        var conInpOn = $(this).siblings('input')
        countValue =  parseInt(conInpOn.val())
        countValue--;
        if (countValue<=1) {
            $(this).css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            conInpOn.val(1)
            return;
        }else {
            $(this).css({"backgroundColor":"#fff","color":"#333","cursor":"pointer"})
        }
        conInpOn.val(countValue)
        var parent = $(this).parent().parent().parent();
        getCommoditiesDetail(parent);
    })
    addBtn.on("click",function () {
        var conInpCur = $(this).siblings('input')
        countValue =  parseInt(conInpCur.val())
        countValue++;
        if (countValue<=1) {
            reduceBtn.css({"backgroundColor":"#eee","color":"#ccc","cursor":"not-allowed"})
            conInpCur.val(1)
            return;
        }else {
            reduceBtn.css({"backgroundColor":"#fff","color":"#333","cursor":"pointer"})
        }
        conInpCur.val(countValue)
        var parent = $(this).parent().parent().parent();
        getCommoditiesDetail(parent);
    })

    // 待确认订单删除按钮
    var deleteListBoxBtn = $(".del-contetn-list-box")
    deleteListBoxBtn.on('click',function(){
        var parentThreeOrigin = $(this).parent().parent().parent().parent().parent();
        parentThreeOrigin.remove()
    })

    // 变更规格式重新计算加工费以及单价
    $('#unConfirmedOrder .thickness').change(function () {
        var parent = $(this).parent().parent();
        getCommoditiesDetail(parent);
    })
    $('#unConfirmedOrder .width').change(function () {
        var parent = $(this).parent().parent();
        getCommoditiesDetail(parent);
    })
    $('#unConfirmedOrder .length').change(function () {
        var parent = $(this).parent().parent();
        getCommoditiesDetail(parent);
    })
    $('#unConfirmedOrder .count').change(function () {
        var parent = $(this).parent().parent().parent();
        getCommoditiesDetail(parent);
    })

    bindOnChangeEvent()

    function getCommoditiesDetail(parent) {

        var processModeId = parent.find(".method-select").val();
        var thickness = parent.find(".thickness").val();
        var width = parent.find(".width").val();
        var length = parent.find(".length").val()
        var index = parent.attr("data-index");
        var shape = parent.attr("data-shape");
        var steelType = parent.attr("data-name");
        var processType = parent.attr("data-type");
        var count = parent.find(".count").val();

        if (!!thickness && (!!width || shape==='CYLINDER') && !!length) {
            $.ajax({
                "url": "/mall/quick-orders/commodities?processModeId=" + (!processModeId?'':processModeId)
                    + '&thickness=' + thickness + '&count=' + count
                    + '&processType=' + (!processType?'':processType)
                    + '&width=' +  (!width?'':width)
                    + '&length=' + length + '&index=' + index + '&shape=' + shape + '&steelType=' + steelType,
                "async": false,
                "success": function (data) {
                    if (data.toString().includes('密码登录')) {
                        window.location.href="/mall/login";
                    }
                    $('#commodityDetails-'+index).html(data);
                }
            });
            var commodityId = $("#commodityId-"+index).val();
            var imgUrl = $("#images-"+index).val();
            reloadPicAndProcessMode(index, commodityId, imgUrl, processModeId)
        }
    }

    function reloadPicAndProcessMode(index, commodityId, imgUrl, processModeId) {
        if (!!commodityId) {
            $.ajax({
                "url": "/mall/quick-orders/process-mode?processModeId=" + (!processModeId?'':processModeId) + "&commodityId=" + commodityId + '&index=' + index,
                "async": false,
                "success": function (data) {
                    if (data.toString().includes('密码登录')) {
                        window.location.href="/mall/login";
                    }
                    $('#processMode-'+index).html(data);
                }
            });
        }
        if (!!imgUrl) {
            $.ajax({
                "url": "/mall/quick-orders/image?imageUrl=" + imgUrl,
                "async": false,
                "success": function (data) {
                    if (data.toString().includes('密码登录')) {
                        window.location.href="/mall/login";
                    }
                    $('#commodityPic-'+index).html(data);
                }
            });
        }
        bindOnChangeEvent()
    }

    function bindOnChangeEvent() {
        $('#unConfirmedOrder .method-select').off('change').on('change', function (item) {
            var parent = $(this).parent().parent().parent().parent();
            var selectIndex = item.target.selectedIndex;
            parent.find(".manner").val($(item.target.options[selectIndex]).text());
            getCommoditiesDetail(parent);
        })
        $('#unConfirmedOrder .radio-commodity').off('change').on("change", function () {
            var commodityId = $(this).val();
            var imageUrl = $(this).attr("data-image");
            var index = $(this).attr("data-index");
            var shape = $(this).attr("data-shape");
            var processModeId = $("#processModeId-"+index).val();
            $("#unConfirmedCommodityShape-"+index).val(shape);
            reloadPicAndProcessMode(index, commodityId, imageUrl, processModeId);
        })
        var iconTishiPro =$(".icon-tishi1")
        iconTishiPro.off('mouseenter').on('mouseenter',function(){
            var sizeInfo = $(this).siblings(".size-info")
            sizeInfo.show()
        })
        iconTishiPro.off('mouseleave').on('mouseleave',function(){
            var sizeInfo = $(this).siblings(".size-info")
            sizeInfo.hide()
        })
        $(".size-info").off('mouseenter').on('mouseenter',function(){
            $(this).show()
        }).off('mouseleave').on('mouseleave',function(){
            $(this).hide()
        })
    }

    $("#confirm-quick-orders").click(function (event) {
        var statements = document.getElementsByName("unConfirmedCommodityStatement");
        var stop = false;
        $.each(statements, function (i, item) {
            if (item.innerText !== null && item.innerText !== "") {
                $.submitQuickOrdermessage("warn", "订单含有不合要求的数据，请修改后再提交!");
                stop = true;
                return false;
            }
        })
        if (stop) {
            event.preventDefault();
        }

        var list = [];
        $(".box-top").attr("class","box-top");
        var valid = true;
        $.each($(".radio-commodity"), function (i, item) {
            if(!list.includes($(item).attr("name"))) {
                list.push($(item).attr("name"))
            }
        })
        $.each(list, function (i, item) {
            if(!$('input:radio[name="'+item+'"]:checked').val()) {
                var index = $('input:radio[name="'+item+'"]').attr("data-index");
                $("#unConfirmedCommodity-"+index).attr("class","box-top error");
                $.submitQuickOrdermessage("warn", "请设置正确的规格!");
                valid = false;
            }
        })
        $.each($(".commodityId-hidden"), function (i, item) {
            if(!$(item).val()) {
                $(item).parent().parent().parent().find(".box-top").attr("class","box-top error");
                $.submitQuickOrdermessage("warn", "请将不存在的商品删除!");
                valid = false;
            }
        })
        return valid;
    })
}(jQuery);
