+function ($) {
    'use strict';


    function phoneToSecrecy() {

        var user_phone = $(".personal_page .hidden_phone").val();
        if(!isEmpty(user_phone)){
        var phone = user_phone.split("")
        for (var j = 3; j < 7; j++) {
            phone[j] = "*";
        }
        var str_phone = "";
        for (var i = 0; i < phone.length; i++) {
            str_phone += phone[i];
        }
        $(".personal_page .person_phone").text(str_phone)
        }
    }

    phoneToSecrecy();


    $(".personal_page .btn-change-pass").on('click', function (e) {
        var form = $("#change-pass-form");
        form.validator('validate');
        if (!form.validator().data('bs.validator').hasErrors()) {
            $.ajax({
                url: '/mall/personalData/change-password',
                type: 'put',
                data: form.serialize(),
                success: function (res) {
                    form[0].reset();
                    $("#change-pass-form").modal("hide");
                },
                error: function (res) {
                    $("#change-pass-form").modal("hide");
                }
            });
        }

    })

    var waitTime = 60;

    function time(sendCode) {
        if (waitTime == 0) {
            sendCode.removeAttr("disabled");
            sendCode.text("发送验证码");
            waitTime = 60;
        } else {
            sendCode.attr("disabled", true);
            sendCode.text("重新发送" + waitTime + "s");
            waitTime--;
            setTimeout(function () {
                    time(sendCode)
                },
                1000)
        }
    }

    // $(".personal_page .send-phone-code").on('click', function () {
    //     $("#modifyMobileModal").modal("show")
    //     time($(".personal_page .send-phone-code"));
    // })
    $(".personal_page .send-phone-code").on('click', function () {
        var newPhone = $(".personal_page #newPhone").val()
        var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;

        if (newPhone != null && newPhone != "") {
            if (!myreg.test(newPhone)) {
                $("#newPhone-label").show()
                $("#newPhone-label").html("请输入正确的手机号")
                return false
            }
            $.ajax({
                url: '/api/phoneCode?type=CHANGE_PHONE&phoneNumber=' + newPhone,
                type: 'get',
                success: function (res) {
                    $("#modifyMobileModal").modal("show")
                    time($(".personal_page .send-phone-code"));
                    $("#newPhone-label").hide()
                },
                error: function (res) {
                    $("#modifyMobileModal").modal("show")
                    $("#newPhone-label").show()
                    $("#newPhone-label").html(res.responseText)
                }
            })
        } else {
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入手机号码。")
        }
    })

    $(".personal_page .btn-change-phone").on('click', function () {
        var newPhone = $(".personal_page #newPhone").val()
        var phoneCode = $(".personal_page #phoneCode").val()
        var login = $("#personal_page_user_login").val()
        if (newPhone != null && phoneCode != null && newPhone != "" && phoneCode != "") {
            $.ajax({
                url: '/mall/personalData/change-phone',
                type: 'put',
                dataType: "json",
                data: JSON.stringify({
                    "login":login,
                    "phone":newPhone,
                    "type":"CHANGE_PHONE",
                    "phoneCode":phoneCode
                }),
                contentType: "application/json",
                processData: false,
                async: true,
                success: function (res) {
                    $("#modifyMobileModal").modal("hide");
                },
                error: function (res) {
                    $("#modifyMobileModal").modal("show");
                    $("#newPhone-label").show();
                    $("#newPhone-label").html("验证码有误，请重新输入。");
                }
            })
        } else {
            $("#modifyMobileModal").modal("show")
            $("#newPhone-label").show()
            $("#newPhone-label").html("请输入手机号并填入验证码。")
        }
    })


}(jQuery);
